import React, { useMemo, useState } from "react";
import orderBy from "lodash/orderBy";

import InfiniteScroll from "react-infinite-scroll-component";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import ListIcon from "@mui/icons-material/List";
import FilterListIcon from "@mui/icons-material/FilterList";
import NorthIcon from "@mui/icons-material/North";
import Slide from "@mui/material/Slide";
import Fade from "@mui/material/Fade";

import { post } from "utils/restClient";
import { useSearchesStore } from "store/Store";

import Filters from "./Filters/Default";
import SearchMapScreen from "./SearchMapScreen";
import { SET_FILTER_STATE } from "../../reducers/searchesReducer";
import ListingCardNew from "components/Listings/ListingCardNew";

const sideBarWidth = "400px";

const ToggleSidebarButton = ({ onClick }) => {
  return (
    <ButtonBase
      id="toggle-listing-sidebar"
      onClick={onClick}
      sx={{
        color: (theme) => theme.palette.primary.main,
        p: "4px",
        width: "auto",
        display: "inline-flex",
        borderRadius: "4px",
      }}
    >
      <ListIcon color="action" />
    </ButtonBase>
  );
};

const SortListingButton = ({ onClick, asc }) => {
  return (
    <Tooltip title="Sort" arrow>
      <ButtonBase
        id="toggle-listing-sidebar"
        onClick={onClick}
        sx={{
          color: (theme) => theme.palette.primary.main,
          p: "4px",
          width: "auto",
          display: "inline-flex",
          borderRadius: "4px",
        }}
      >
        <FilterListIcon color="action" fontSize="small" />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              color: "text.primary",
              fontSize: "9px",
              fontWeight: 600,
              lineHeight: "10px",
            }}
          >
            <span>A</span>
            <span>Z</span>
          </Box>

          <Box
            sx={{
              transform: `rotate(${asc ? "180deg" : "360deg"})`,
              transition: `transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
              display: "flex",
              alignItems: "center",
            }}
          >
            <NorthIcon color="action" fontSize="small" />
          </Box>
        </Box>
      </ButtonBase>
    </Tooltip>
  );
};

const SearchScreen = () => {
  const [searchesState, searchesDispatch] = useSearchesStore();
  const [listings, setListings] = React.useState([]);
  const [meta, setMeta] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(true);
  const [ascByName, setAscByName] = React.useState(true);
  const [selectedListingID, setSelectedListingID] = useState(null);

  const { filterState } = searchesState;

  const { currentRegion } = filterState;

  // const mapBoundaries = useMemo(() => {
  //   return {
  //     minLongitude: currentRegion.longitude - currentRegion.longitudeDelta / 2,
  //     minLatitude: currentRegion.latitude - currentRegion.latitudeDelta / 2,
  //     maxLongitude: currentRegion.longitude + currentRegion.longitudeDelta / 2,
  //     maxLatitude: currentRegion.latitude + currentRegion.latitudeDelta / 2,
  //     centerLatitude: currentRegion.latitude,
  //     centerLongitude: currentRegion.longitude,
  //   };
  // }, [currentRegion]);

  const [mapBoundaries, setmapBoundaries] = useState({});

  React.useLayoutEffect(() => {
    reset();

    return () => {
      reset();
    };
  }, [filterState]);

  React.useEffect(() => {
    if (!meta?.total_pages) {
      console.log("changed in useEffect");
      fetchListings();
    }
  }, [filterState, meta, loading]);

  const reset = () => {
    setMeta({});
    setListings([]);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const toggleOrderListings = () => {
    // searchesDispatch({
    //     type: SET_FILTER_STATE,
    //     filterState: {
    //         ...filterState,
    //         sortDirection: filterState?.sortDirection === 'asc' ? 'desc' : 'asc',
    //     },
    // });

    setAscByName(!ascByName);
  };

  const handleMapViewChange = (map) => {
    // const longitudeDelta = Math.abs(
    //   (map.bounds?.nw?.lng - map.bounds?.ne?.lng) / 2
    // );
    // const latitudeDelta = Math.abs(
    //   (map.bounds?.nw?.lat - map.bounds?.sw?.lat) / 2
    // );

    // });
    // const newRegion = {
    //   latitude: map.center?.lat,
    //   longitude: map.center?.lng,
    //   latitudeDelta: latitudeDelta,
    //   longitudeDelta: longitudeDelta,
    // };
    searchesDispatch({
      type: SET_FILTER_STATE,
      filterState: {
        ...filterState,
        minLongitude: map.bounds?.nw?.lng,
        minLatitude: map.bounds?.sw?.lat,
        maxLongitude: map.bounds?.ne?.lng,
        maxLatitude: map.bounds?.nw?.lat,
        centerLatitude: map.center?.lat,
        centerLongitude: map.center?.lng,
      },
    });
  };

  async function fetchListings() {
    if (!loading) {
      try {
        setLoading(true);

        const res = await post(
          `/api/v1/listings_by_coordinates?page=${
            meta?.total_pages > meta?.current_page
              ? (meta?.current_page || 0) + 1
              : meta?.current_page || 1
          }&items=10`,
          {
            ...filterState,
            // ...boundaries,
          }
        );

        if (res.status === 200) {
          setListings([...listings, ...res?.data?.data]);
          setMeta(res?.data?.meta);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const scrollToRef = (ref) => {
    ref?.current?.scrollIntoView();
  };

  return (
    <Box
      sx={{
        pl: showSidebar ? sideBarWidth : 0,
        transition: `padding 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`,
      }}
    >
      <Slide in={showSidebar} direction="right">
        <Box
          sx={{
            height: "auto",
            width: sideBarWidth,
            position: "fixed",
            top: "64px",
            bottom: 0,
            left: 0,

            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              p: "16px 0px 12px 24px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SortListingButton
                onClick={toggleOrderListings}
                asc={ascByName}
              />

              <ToggleSidebarButton onClick={toggleSidebar} />
            </Box>

            {filterState?.selectedLocations[0]?.name && (
              <Box
                sx={{
                  flexShrink: 0,
                  mt: "8px",
                }}
              >
                <Typography variant="h5" component="div">
                  <b>
                    Properties in{" "}
                    <Typography
                      color="primary"
                      variant="inherit"
                      component="span"
                    >
                      {filterState?.selectedLocations[0]?.name}
                    </Typography>
                  </b>
                </Typography>
              </Box>
            )}

            <Box mt="9px">
              <Filters />
            </Box>
          </Box>

          <Box
            sx={{
              flexGrow: "1",
              overflow: "auto",
              p: "0 24px 16px",
              background: `linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .2), rgba(0, 0, 0, 0)) 0 100%`,
              backgroundRepeat: "no-repeat",
              backgroundColor: "#fff",
              backgroundSize: `100% 40px, 100% 40px, 100% 14px, 100% 14px`,
              backgroundAttachment: `local, local, scroll, scroll`,
            }}
            id="scrollableDiv"
          >
            <InfiniteScroll
              dataLength={listings.length}
              empty
              next={fetchListings}
              scrollThreshold="200px"
              hasMore={
                meta?.total_pages
                  ? meta?.total_pages > meta?.current_page
                  : true
              }
              loader={
                <Box
                  sx={{
                    width: "1",
                    textAlign: "center",
                    mb: "16px",
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              }
              scrollableTarget="scrollableDiv"
            >
              {orderBy(
                listings,
                "address_1",
                ascByName ? ["asc"] : ["desc"]
              ).map((item, index) => (
                <ListingCardNew
                  item={item}
                  key={item.id}
                  index={index}
                  isSelected={selectedListingID === item.id}
                  scrollToRef={scrollToRef}
                />
              ))}
            </InfiniteScroll>
          </Box>
        </Box>
      </Slide>

      <Box
        sx={{
          m: { xs: -2, md: -4, lg: -6 },
          p: 2,
        }}
      >
        <Fade in={!showSidebar} mountOnEnter unmountOnExit>
          <Box mb="12px">
            <ToggleSidebarButton onClick={toggleSidebar} />
          </Box>
        </Fade>

        <Box
          sx={{
            minHeight: "200px",
            borderRadius: "16px",
            backgroundColor: (theme) => theme.palette.grey[100],
          }}
        >
          <SearchMapScreen
            handleMapViewChange={handleMapViewChange}
            mapBoundaries={mapBoundaries}
            listings={listings}
            setSelectedListingID={setSelectedListingID}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SearchScreen;
