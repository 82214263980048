import React from "react";

import NumberFormat from "react-number-format";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

import Card from "components/shared/Card";

const CommonWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",

        "& > *": {
          flex: "1 auto",
        },
      }}
    >
      {children}
    </Box>
  );
};

const CommonTitle = ({ label }) => {
  return (
    <Typography color="textSecondary" fontWeight={300}>
      {label}&nbsp;
    </Typography>
  );
};

const CommonSubtitle = ({ label }) => {
  return (
    <Typography align="right">
      <b>{label}</b>
    </Typography>
  );
};

export default function ZipCodeData({ zipCodeData }) {
  return (
    <Card>
      <Typography
        variant="h6"
        color="primary"
        paragraph
        sx={{ fontWeight: 800 }}
      >
        <strong>Data for {zipCodeData?.code}</strong>
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "40px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            flex: "1 auto",
          }}
        >
          <CommonWrapper>
            <CommonTitle label="Avg Days on Market" />

            <CommonSubtitle
              label={`${zipCodeData?.average_days_on_market} days`}
            />
          </CommonWrapper>

          <CommonWrapper>
            <CommonTitle label="Avg Price/Sq Ft" />

            <CommonSubtitle
              label={`$${zipCodeData?.average_list_price_per_sq_ft}`}
            />
          </CommonWrapper>

          <CommonWrapper>
            <CommonTitle label="Avg List Price" />

            <NumberFormat
              value={zipCodeData?.average_list_price}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={0}
              renderText={(value) => <CommonSubtitle label={value} />}
            />
          </CommonWrapper>

          <CommonWrapper>
            <CommonTitle label="AVG LISTINGS/MONTH" />

            <CommonSubtitle label="41" />
          </CommonWrapper>
        </Box>

        <Box
          sx={{
            width: "120px",
            flex: "1 auto",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              pb: "20px",
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              sx={{
                fontSize: "60px",
                fontWeight: 900,
                color: "primary.main",
                textAlign: "center",
              }}
            >
              B+
            </Typography>

            <Typography align="center">Desirability Grade</Typography>
          </Box>

          <Box pb={2}>
            <Box textAlign="center">
              <TrendingUpIcon
                color="primary"
                sx={{
                  height: "60px",
                  width: "60px",
                }}
              />
            </Box>

            <Typography align="center">Trending</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
