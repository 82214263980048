import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import ZipCodeData from "./Insights/ZipCodeData";
import NearbyPlaces from "./Insights/NearbyPlaces";
import TravelTimes from "./Insights/TravelTimes";
import ExpertAnalysis from "./Insights/ExpertAnalysis";

import NotLoggedIn from "./NotLoggedIn";

export default function InsightsTab({
  listing,
  userLoggedIn,
  detailsLoading,
  insightsData,
  nearbyPlaces,
  handleAddTravelTime,
  removeTravelTime,
}) {
  if (detailsLoading) {
    return null;
  }

  if (!userLoggedIn) {
    return <NotLoggedIn listingID={listing.id} />;
  }

  return (
    <Box mt="20px">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ZipCodeData zipCodeData={insightsData?.zip_code_data} />
        </Grid>
        <Grid item xs={12} md={6}>
          <NearbyPlaces nearbyPlaces={nearbyPlaces} />
        </Grid>

        <Grid item xs={12} md={6}>
          <TravelTimes
            travelTimes={insightsData?.custom_filters}
            removeTravelTime={removeTravelTime}
            handleAddTravelTime={handleAddTravelTime}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ExpertAnalysis />
        </Grid>
      </Grid>
    </Box>
  );
}
