import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import ListingShowScreen from "./screens/Listings/ListingShowScreenNew";
import ListingShareShowScreen from "./screens/Listings/ListingShareShowScreen";
import LoginScreen from "./screens/Auth/LoginScreen";
import HowzerAppBar from "./components/shared/AppBar";
import LandingPage from "./screens/Marketing/LandingPage";
import OnBoarding from "components/Onboarding";
import {
  CurrentUserStoreProvider,
  useCurrentUserStore,
  SearchesStoreProvider,
} from "./store/Store";
import {
  SET_LOGGED_IN,
  initialCurrentUserState,
  currentUserReducer,
} from "./reducers/currentUserReducer";
import {
  initialSearchState,
  searchesReducer,
} from "./reducers/searchesReducer";
import SearchScreen from "./screens/Search/SearchScreen";
import ScrollToTop from "./components/shared/ScrollToTop";
import SetUserInfo from "./components/shared/SetUserInfo";
import SavedSearchesScreen from "./screens/SavedSearches";
import SavedSearchesDetailScreen from "./screens/SavedSearches/Detail";

import theme from "./theme";
import "./App.css";
import MapTestScreen from "screens/MapTestScreen";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Howzer.app</title>
        <link rel="canonical" href="%PUBLIC_URL%/favicon.ico" />
      </Helmet>
      <CurrentUserStoreProvider
        initialState={initialCurrentUserState}
        reducer={currentUserReducer}
      >
        <SearchesStoreProvider
          initialState={initialSearchState}
          reducer={searchesReducer}
        >
          <Router>
            <ScrollToTop />
            <SetUserInfo />
            <AppWithUser />
          </Router>
        </SearchesStoreProvider>
      </CurrentUserStoreProvider>
    </>
  );
}

function AppWithUser() {
  const [userState] = useCurrentUserStore();

  if (!userState.userInfoFetched) return null;

  return (
    <>
      <ThemeProvider theme={theme}>
        <HowzerAppBar />

        {/*
          A <Routes> looks through its children <Route>s and
          renders the first one that matches the current URL.
        */}
        <Box p={{ xs: 0, md: 4, lg: 6 }} component="section">
          <Routes>
            <Route exact path="/login" element={<LoginScreen />} />
            <Route exact path="/onboarding" element={<OnBoarding />} />
            <Route exact path="/search" element={<SearchScreen />} />
            <Route
              exact
              path="/saved_searches"
              element={<SavedSearchesScreen />}
            />
            <Route exact path="/map_test" element={<MapTestScreen />} />
            <Route
              exact
              path="/saved_searches/:id"
              element={<SavedSearchesDetailScreen />}
            />
            <Route exact path="/:listingID" element={<ListingShowScreen />} />
            <Route
              exact
              path="/portal/share/:listingID"
              element={<ListingShareShowScreen />}
            />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
