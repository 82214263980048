import React from "react";
import isEmpty from "lodash/isEmpty";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";

import MapDrawScreen from "screens/MapDrawScreen";
import { useSearchesStore } from "store/Store";
import { fetchLocationOptions } from "reducers/searchesReducer";
import { ReactComponent as IconClose } from "assets/svgs/close.svg";
import { colors } from "utils/constants";
import MiniButton from "components/shared/Button/MiniButton";

function MapModal({ handleClose, handleSubmit }) {
  return (
    <>
      <Dialog position="relative" fullScreen open onClose={handleClose}>
        <MapDrawScreen handleSubmit={handleSubmit} handleClose={handleClose} />
      </Dialog>
    </>
  );
}

const AutocompleteChip = ({ name, handleClose }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        width: 141,
        height: 60,
        backgroundColor: "primary.main",
        color: colors.white,
        borderRadius: 2,
      }}
    >
      <Typography fontSize={18}>{name}</Typography>

      <IconButton color="inherit" size="large" onClick={handleClose}>
        <IconClose />
      </IconButton>
    </Box>
  );
};

export default function StepTwo({ values, updateValues, handleStep }) {
  const [showMap, setShowMap] = React.useState(false);
  const [hasValue, setHasValue] = React.useState(false);
  const [searchesState, searchesDispatch] = useSearchesStore();

  const { fetchedLocationOptions, locationOptions } = searchesState;

  React.useEffect(() => {
    setHasValue(!!values.locations.length);
  }, [values]);

  React.useEffect(() => {
    if (locationOptions?.length == 0 && !fetchedLocationOptions) {
      fetchLocationOptions(searchesDispatch);
    }
  }, []);

  const handleShowMap = () => {
    setShowMap(!showMap);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography align="center" variant="h5" color="text.primary" gutterBottom>
        <b>Where are you looking?</b>
      </Typography>

      <Box sx={{ display: "flex", gap: "10px" }}>
        <Autocomplete
          multiple
          sx={{
            "& .MuiInputBase-root": {
              padding: "15px",
              background: colors.white,
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
            },
          }}
          renderTags={() => null}
          fullWidth
          options={locationOptions || []}
          getOptionLabel={(option) => option?.name || ""}
          autoHighlight
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="City or Zip"
              variant="outlined"
            />
          )}
          value={values.locations}
          forcePopupIcon={false}
          onChange={(_event, newValue) => {
            updateValues({ locations: newValue });
          }}
        />

        {showMap ? (
          <MapModal
            handleSubmit={(newValue) => {
              const data = newValue.filter((v) => {
                const index = values.locations.findIndex((l) => l.key === v.key);
                return index === -1;
              });

              updateValues({ locations: [...values.locations, ...data] })
            }}
            handleClose={() => setShowMap(false)}
          />
        ) : (
          ""
        )}

        <MiniButton
          name="Map"
          icon={<FmdGoodOutlinedIcon fontSize="large" />}
          onClick={handleShowMap}
        />
      </Box>

      <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {!isEmpty(values.locations) &&
          values.locations.map((item) => (
            <AutocompleteChip
              key={item?.key}
              name={item?.name}
              handleClose={() => {
                const newValues = values.locations.filter(
                  (data) => data?.name !== item?.name
                );
                updateValues({ locations: newValues });
              }}
            />
          ))}
      </Box>

      <Button
        variant="contained"
        disabled={!hasValue}
        fullWidth
        onClick={handleStep}
        sx={{
          padding: "15px",
          boxShadow: "none",
          borderRadius: "6px",
          fontSize: (theme) => theme.typography.h6,

          "&:hover": {
            boxShadow: "none",
            borderRadius: "6px",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
}
