import React from "react";
import Box from "@mui/material/Box";

const PhotoCarouselItem = ({ imageUrl }) => {
  return (
    <Box
      sx={{
        height: {xs: '240px', sm: '450px'},
        width: "1",
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    />
  );
};

export default PhotoCarouselItem;
