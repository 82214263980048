import React from "react";

import Box from "@mui/material/Box";

import { Swiper, SwiperSlide } from "swiper/react";
import PhotoCarouselItem from "./PhotoCarouselItem";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const ListingPhotoCarousel = ({photos}) => {
  return (
    <Box
      sx={{
        height: {xs: '240px', sm: '450px'}
      }}
    >
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        onSlideChange={() => null}
        onSwiper={(swiper) => null}
        loop={true}
      >
        {photos.map((photo) => (
          <SwiperSlide key={photo.id}>
            <PhotoCarouselItem imageUrl={photo.url} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
}

export default ListingPhotoCarousel;
