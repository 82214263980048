import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Slider from "@mui/material/Slider";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import NumberFormat from "react-number-format";
import { priceShortener } from "../../../utils/textHelpers";

import { patch } from "utils/restClient";

import {
  propertyClass,
  propertyTypes,
  propertyStatus,
  sizes,
} from "utils/constants";
import { DetailContext } from "./index";

function valuetext(value) {
  return value;
}

const initialValues = {
  minYearBuilt: "",
  maxYearBuilt: "",
  minLotSize: "",
  maxLotSize: "",
  minPrice: "",
  maxPrice: "",
  minHoaFee: "",
  maxHoaFee: "",
  minBedrooms: "",
  maxBedrooms: "",
  minBathrooms: "",
  maxBathrooms: "",
  minGarages: "",
  maxGarages: "",
  minSqFootage: "",
  maxSqFootage: "",
  minStories: "",
  maxStories: "",
  property_subtype_ids: [],
  statusTypes: [],
};

// This value is used in eval
const minPrice_distance = 10000;
const minSqFootage_distance = 10000;
const minLotSize_distance = 1;

const minLotSize_value = 0;
const maxLotSize_value = 100;

const minPrice_value = 0;
const maxPrice_value = 1000000;

const minSqFootage_value = 0;

const maxSqFootage_value = 1000000;

export default () => {
  const { savedSearchDetails } = React.useContext(DetailContext);
  const detailsData = savedSearchDetails?.data?.data?.saved_search;

  if (savedSearchDetails.isLoading) {
    return null;
  }

  const formik = useFormik({
    initialValues: {
      name: detailsData?.name,
      propertyClassType: detailsData?.property_type_id,
      minYearBuilt: detailsData?.min_year_built,
      maxYearBuilt: detailsData?.max_year_built,
      minLotSize: parseFloat(detailsData?.min_lot_size) || null,
      maxLotSize: parseFloat(detailsData?.max_lot_size) || null,
      minPrice: detailsData?.min_price,
      maxPrice: detailsData?.max_price,
      minHoaFee: detailsData?.min_hoa_fee,
      maxHoaFee: detailsData?.max_hoa_fee,
      minBedrooms: detailsData?.min_bedrooms,
      maxBedrooms: detailsData?.max_bedrooms,
      minBathrooms: detailsData?.min_bathrooms,
      maxBathrooms: detailsData?.max_bathrooms,
      minGarages: detailsData?.min_garage_spaces,
      maxGarages: detailsData?.max_garage_spaces,
      minSqFootage: detailsData?.min_sq_footage,
      maxSqFootage: detailsData?.max_sq_footage,
      minStories: detailsData?.min_stories,
      maxStories: detailsData?.max_stories,
      property_subtype_ids: detailsData?.property_subtype_ids || [],
      statusTypes: detailsData?.mls_status_type_ids || [],
      keepExisting: true,
    },
    onSubmit: async (values) => {
      try {
        await patch(
          `/api/v1/saved_searches/${detailsData.id}/update_mobile`,
          values
        );
      } catch (e) {
        console.log("error", e);
      }
    },
  });

  const handleClickProperty = (value) => {
    const parseValue = parseInt(value);
    const copidPropertySubType = [...property_subtype_ids];

    if (copidPropertySubType.includes(parseInt(parseValue))) {
      const propertyWithoutParseValue = copidPropertySubType.filter(
        (subtype) => subtype !== parseValue
      );
      formik.setFieldValue("property_subtype_ids", propertyWithoutParseValue);
    } else {
      copidPropertySubType.push(parseValue);
      formik.setFieldValue("property_subtype_ids", copidPropertySubType);
    }
  };

  const handleClickStatus = (value) => {
    const parseValue = String(value);
    const copidStatusTypes = [...statusTypes];

    if (
      copidStatusTypes.includes(parseValue) ||
      copidStatusTypes.includes(value)
    ) {
      const statusWithoutParseValue = copidStatusTypes.filter(
        (subtype) => subtype !== parseValue || subtype !== value
      );
      formik.setFieldValue("statusTypes", statusWithoutParseValue);
    } else {
      copidStatusTypes.push(parseValue);
      formik.setFieldValue("statusTypes", copidStatusTypes);
    }
  };

  const handleClickButton = (e) => {
    formik.setFieldValue(e.target.name, parseInt(e.target.value));
  };

  const handleChangeSlider = (event, newValue, activeThumb) => {
    const { name } = event.target;

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      formik.setFieldValue(
        `min${name}`,
        Math.min(newValue[0], eval(`max${name}`) - eval(`min${name}_distance`))
      );
    } else {
      formik.setFieldValue(
        `max${name}`,
        Math.max(newValue[1], eval(`min${name}`) + eval(`min${name}_distance`))
      );
    }
  };

  const {
    name,
    minLotSize,
    maxLotSize,
    minYearBuilt,
    maxYearBuilt,
    minPrice,
    maxPrice,
    minHoaFee,
    maxHoaFee,
    minBedrooms,
    maxBedrooms,
    minBathrooms,
    maxBathrooms,
    minGarages,
    maxGarages,
    maxSqFootage,
    minSqFootage,
    minStories,
    maxStories,
    propertyClassType,
    property_subtype_ids,
    statusTypes,
    keepExisting,
  } = formik.values;

  return (
    <Box
      sx={{
        p: "21px",
        bgcolor: "primary.light",
        mr: { xs: 0, md: "33px" },
        borderRadius: "6px",
        mb: "20px",
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between" mb="20px">
        <Typography variant="h6">
          <strong>Filter</strong>
        </Typography>

        <Link
          sx={{ cursor: "pointer" }}
          color="primary"
          variant="h6"
          onClick={() =>
            formik.resetForm({ values: { ...formik.values, ...initialValues } })
          }
        >
          <strong>Reset All</strong>
        </Link>
      </Box>

      <Box mb="24px">
        <InputLabel sx={{ color: "text.primary" }}>
          <b>Search Name</b>
        </InputLabel>
        <TextField
          name="name"
          variant="standard"
          onChange={formik.handleChange}
          value={name}
          disabled
          fullWidth
        />
      </Box>

      <Box mb="24px">
        <InputLabel sx={{ color: "text.primary" }}>
          <b>Property Class</b>
        </InputLabel>
        <TextField
          name="property"
          variant="standard"
          onChange={formik.handleChange}
          value={propertyClass[propertyClassType]}
          disabled
          fullWidth
        />
      </Box>

      {propertyClassType && (
        <Box mb="34px">
          <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
            <b>Types of Property</b>
          </InputLabel>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "4px",
            }}
          >
            {Object.keys(propertyTypes[propertyClassType])?.map((type) => (
              <Button
                key={type}
                onClick={() => handleClickProperty(type)}
                sx={{
                  minWidth: "91px",
                  bgcolor: property_subtype_ids.includes(parseInt(type))
                    ? "#323643 !important"
                    : "background.paper",
                  color: property_subtype_ids.includes(parseInt(type))
                    ? "background.paper"
                    : "#323643 !important",
                  border: "1px solid #DCDFE3",
                  boxSizing: "border-box",
                  borderRadius: "10px",
                }}
              >
                {propertyTypes[propertyClassType][type]}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      <Box mb="24px">
        <Box
          display="flex"
          alignItems="flex-end"
          mb="16px"
          justifyContent="space-between"
        >
          <InputLabel sx={{ color: "text.primary" }}>
            <b>Price Range</b>
          </InputLabel>

          <Typography color="primary" variant="subtitle2">
            {/* <NumberFormat
              value={minPrice}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={0}
              renderText={(value, props) => {
                return <b {...props}>{`${value}`}</b>;
              }}
            /> */}
            <b>
              ${priceShortener(minPrice) || "No Min"} -
              {priceShortener(maxPrice) || "No Max"}
            </b>
          </Typography>
        </Box>

        <Slider
          name="Price"
          value={[minPrice, maxPrice]}
          max={maxPrice_value}
          min={minPrice_value}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>

      <Box mb="24px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Status</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "4px",
          }}
        >
          {Object.keys(propertyStatus)?.map((type, index) => (
            <Button
              key={type + index}
              onClick={() => handleClickStatus(type)}
              sx={{
                minWidth: "91px",
                bgcolor: statusTypes.includes(String(type))
                  ? "#323643 !important"
                  : "background.paper",
                color: statusTypes.includes(String(type))
                  ? "background.paper"
                  : "#323643 !important",
                border: "1px solid #DCDFE3",
                boxSizing: "border-box",
                borderRadius: "10px",
              }}
            >
              {propertyStatus[type]}
            </Button>
          ))}
        </Box>
      </Box>

      <Box mb="24px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Min Bedroom</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "4px",
          }}
        >
          {Object.keys(sizes).map((size, index) => (
            <Button
              name="minBedrooms"
              key={size + index}
              onClick={(e) => handleClickButton(e)}
              value={size}
              sx={{
                minWidth: "48px",
                bgcolor:
                  minBedrooms === parseInt(size) ||
                  (size === 4 && minBedrooms > 3)
                    ? "#323643 !important"
                    : "background.paper",
                color:
                  minBedrooms === parseInt(size) ||
                  (size === 4 && minBedrooms > 3)
                    ? "background.paper"
                    : "#323643 !important",
                border: "1px solid #DCDFE3",
                boxSizing: "border-box",
                borderRadius: "10px",
              }}
            >
              {sizes[size]}
            </Button>
          ))}
        </Box>
      </Box>

      {/* <InputLabel sx={{ fontWeight: 500, color: "primary.dark" }}>
        Max Bedroom
      </InputLabel>
      <br /> */}

      {/* <Box display="flex">
        {Object.keys(sizes).map((size) => (
          <Box key={size}>
            <Button
              variant="contained"
              name="maxBedrooms"
              value={size}
              onClick={(e) => handleClickButton(e)}
              sx={{
                marginLeft: "4px",
                background:
                  maxBedrooms === parseInt(size) ||
                  (size === 4 && maxBedrooms > 3)
                    ? "#323643"
                    : "#FFFFFF",
                color:
                  maxBedrooms === parseInt(size) ||
                  (size === 4 && maxBedrooms > 3)
                    ? "#FFFFFF"
                    : "#323643",
                "&:hover": {
                  color: "#FFFFFF",
                },
              }}
            >
              {sizes[size]}
            </Button>
          </Box>
        ))}
      </Box>

      <br /> */}

      <Box mb="34px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Min Bathroom</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "4px",
          }}
        >
          {Object.keys(sizes).map((size, index) => (
            <Button
              key={size + index}
              name="minBathrooms"
              onClick={(e) => handleClickButton(e)}
              value={size}
              sx={{
                minWidth: "48px",
                bgcolor:
                  minBathrooms === parseInt(size) ||
                  (size === 4 && minBathrooms > 3)
                    ? "#323643 !important"
                    : "background.paper",
                color:
                  minBathrooms === parseInt(size) ||
                  (size === 4 && minBathrooms > 3)
                    ? "background.paper"
                    : "#323643 !important",
                border: "1px solid #DCDFE3",
                boxSizing: "border-box",
                borderRadius: "10px",
              }}
            >
              {sizes[size]}
            </Button>
          ))}
        </Box>
      </Box>

      {/* 
      <InputLabel sx={{ fontWeight: 500, color: "primary.dark" }}>
        Max Bathroom
      </InputLabel>
      <br />

      <Box display="flex">
        {Object.keys(sizes).map((size) => (
          <Box key={size}>
            <Button
              variant="contained"
              name="maxBathrooms"
              value={size}
              onClick={(e) => handleClickButton(e)}
              sx={{
                marginLeft: "4px",
                background:
                  maxBathrooms === parseInt(size) ||
                  (size === 4 && maxBathrooms > 3)
                    ? "#323643"
                    : "#FFFFFF",
                color:
                  maxBathrooms === parseInt(size) ||
                  (size === 4 && maxBathrooms > 3)
                    ? "#FFFFFF"
                    : "#323643",
                "&:hover": {
                  color: "#FFFFFF",
                },
              }}
            >
              {sizes[size]}
            </Button>
          </Box>
        ))}
      </Box>

      <br /> */}

      <Box mb="24px">
        <Box
          display="flex"
          alignItems="flex-end"
          mb="16px"
          justifyContent="space-between"
        >
          <InputLabel sx={{ color: "text.primary" }}>
            <b>Sq Footage</b>
          </InputLabel>

          <Typography color="primary" variant="subtitle2">
            <b>
              {minSqFootage || "No Min"} sqft - {maxSqFootage || "No Max"} sqft
            </b>
          </Typography>
        </Box>
        <Slider
          value={[minSqFootage, maxSqFootage]}
          name="SqFootage"
          max={maxSqFootage_value}
          min={minSqFootage_value}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>

      <Box mb="34px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Year built</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "wrap",
            gap: "12px",
          }}
        >
          <TextField
            type="number"
            name="minYearBuilt"
            value={minYearBuilt}
            variant="standard"
            onChange={formik.handleChange}
            fullWidth
          />

          <Box>-</Box>

          <TextField
            type="number"
            name="maxYearBuilt"
            variant="standard"
            value={maxYearBuilt}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      </Box>

      <Box mb="24px">
        <Box
          display="flex"
          alignItems="flex-end"
          mb="16px"
          justifyContent="space-between"
        >
          <InputLabel sx={{ color: "text.primary" }}>
            <b>Lot Size</b>
          </InputLabel>

          <Typography color="primary" variant="subtitle2">
            <b>
              {minLotSize || 0} acres - {maxLotSize || 0} acres
            </b>
          </Typography>
        </Box>

        <Slider
          value={[minLotSize, maxLotSize]}
          name="LotSize"
          max={maxLotSize_value}
          min={minLotSize_value}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>

      <Box mb="34px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Stories</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "wrap",
            gap: "12px",
          }}
        >
          <TextField
            type="number"
            name="minStories"
            min={0}
            value={minStories}
            variant="standard"
            onChange={formik.handleChange}
            fullWidth
          />

          <Box>-</Box>

          <TextField
            type="number"
            name="maxStories"
            min={0}
            variant="standard"
            value={maxStories}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      </Box>

      <Box mb="34px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Min Garage</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "4px",
          }}
        >
          {Object.keys(sizes).map((size) => (
            <Button
              key={size}
              name="minGarages"
              value={size}
              onClick={(e) => handleClickButton(e)}
              sx={{
                minWidth: "48px",
                bgcolor:
                  minGarages === parseInt(size) ||
                  (size === 4 && minGarages > 3)
                    ? "#323643 !important"
                    : "background.paper",
                color:
                  minGarages === parseInt(size) ||
                  (size === 4 && minGarages > 3)
                    ? "background.paper"
                    : "#323643 !important",
                border: "1px solid #DCDFE3",
                boxSizing: "border-box",
                borderRadius: "10px",
              }}
            >
              {sizes[size]}
            </Button>
          ))}
        </Box>
      </Box>

      {/* <InputLabel sx={{ fontWeight: 500, color: "primary.dark" }}>
        Max Garage
      </InputLabel>
      <br />

      <Box display="flex">
        {Object.keys(sizes).map((size) => (
          <Box key={size}>
            <Button
              variant="contained"
              name="maxGarages"
              value={size}
              onClick={(e) => handleClickButton(e)}
              sx={{
                marginLeft: "4px",
                background:
                  maxGarages === parseInt(size) ||
                  (size === 4 && maxGarages > 3)
                    ? "#323643"
                    : "#FFFFFF",
                color:
                  maxGarages === parseInt(size) ||
                  (size === 4 && maxGarages > 3)
                    ? "#FFFFFF"
                    : "#323643",
              }}
            >
              {sizes[size]}
            </Button>
          </Box>
        ))}
      </Box>
      <br /> */}

      <Box mb="24px">
        <InputLabel sx={{ color: "text.primary", mb: "16px" }}>
          <b>Hoa Fee</b>
        </InputLabel>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "wrap",
            gap: "12px",
          }}
        >
          <TextField
            type="number"
            name="minHoaFee"
            value={minHoaFee}
            variant="standard"
            onChange={formik.handleChange}
            fullWidth
          />

          <Box>-</Box>

          <TextField
            type="number"
            name="maxHoaFee"
            variant="standard"
            value={maxHoaFee}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      </Box>

      <FormControlLabel
        control={
          <Switch
            name="keepExisting"
            value={keepExisting}
            onChange={formik.handleChange}
            defaultChecked
          />
        }
        label={
          <Typography variant="subtitle1">
            <b>Keep All Existing Matches</b>
          </Typography>
        }
      />

      <br />
      <br />

      <Button variant="contained" size="large" type="submit" fullWidth>
        Update
      </Button>
    </Box>
  );
};
