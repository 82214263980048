const initialCurrentUserState = {
  loggedIn: false,
  currentUser: {},
  userInfoFetched: false,
};

export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_USER_INFO = "SET_USER_INFO";
export const FETCHED_USER_INFO_FAIL = "FETCHED_USER_INFO_FAIL";

export const loginUser = async (dispatch, user, token) => {
  window.sessionStorage.setItem("accessToken", token);
  dispatch({ type: SET_LOGGED_IN, user: user });
};

const currentUserReducer = (prevState = initialCurrentUserState, action) => {
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...prevState,
        loggedIn: true,
        currentUser: action.user,
      };
    case SET_USER_INFO:
      return {
        ...prevState,
        loggedIn: true,
        currentUser: action.user,
        userInfoFetched: true,
      };
    case FETCHED_USER_INFO_FAIL:
      return {
        ...prevState,
        loggedIn: false,
        currentUser: {},
        userInfoFetched: true,
      };
    default:
      return prevState;
  }
};

export { currentUserReducer, initialCurrentUserState };
