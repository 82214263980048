import React from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Card from 'components/shared/Card';

const OfferBanner = () => {
    return (
            <Card
                sx={{
                    p: 5,
                    display: {md: 'inline-block'},
                    bgcolor: 'primary.main',
                    background: 'linear-gradient(180deg, #05F0E2 0%, #00DEB7 100%);',
                    height: '1',
                    textAlign: 'center'
                }}
            >
                <Box>
                    <Typography
                        color='primary.dark'
                        variant='h5'
                        fontWeight={800}
                    >
                        <strong>Get Up To</strong>
                    </Typography>

                    <Box my={3}>
                        <Typography variant='h3' color='primary.contrastText' fontWeight={600}>
                            $6,900
                        </Typography>
                    </Box>

                    <Box my={3}>
                        <Typography variant='h6' fontWeight={300}>
                            Cash Back <br /> When you buy with howzer
                        </Typography>
                    </Box>

                    <Link href='#' color='primary.contrastText' underline='always' variant='h6' fontWeight={300}>
                        How does it work?
                    </Link>
                </Box>
            </Card>
    )
}

export default OfferBanner;
