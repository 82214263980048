import React from "react";
import GoogleMap from "google-map-react";
import Marker from "components/Map/Marker.jsx";
import { priceShortener } from "utils/textHelpers";
import { storageKeys, defaultMapProps } from 'utils/constants';

export default function SearchMapScreen({
  handleMapViewChange,
  mapBoundaries,
  listings,
  setSelectedListingID,
}) {
  const INITIAL_REGION = {
    latitude: 40.590433536599626,
    longitude: -111.82664550840855,
    latitudeDelta: 0.09440791166323947,
    longitudeDelta: 0.06884291768074036,
  };

  // const [selectedListingID, setSelectedListingID] = useState();

  // useEffect(() => {
  //   async function fetchListings() {
  //     const res = await post(`api/v1/listings_by_coordinates?page=${1}`, {
  //       minLongitude: defaultMapProps.lng - defaultMapProps.longitudeDelta / 2,
  //       minLatitude: defaultMapProps.lat - defaultMapProps.latitudeDelta / 2,
  //       maxLongitude: defaultMapProps.lng + defaultMapProps.longitudeDelta / 2,
  //       maxLatitude: defaultMapProps.lat + defaultMapProps.latitudeDelta / 2,
  //       centerLatitude: defaultMapProps.lat,
  //       centerLongitude: defaultMapProps.lng,
  //     });
  //     setCurrentListings(res?.data?.data);
  //   }
  //   fetchListings();
  // }, []);

  const onMarkerSelect = (key) => {
    const index = listings.findIndex((l) => l.id == key);
    setSelectedListingID(listings[index].id);
  };

  return (
    <div style={{ height: "calc(100vh - 100px)", width: "100%" }}>
      <GoogleMap
        bootstrapURLKeys={{ key: storageKeys.bootstrapMapUrl }}
        center={defaultMapProps.center}
        zoom={defaultMapProps.zoom}
        // onDragEnd={(e) => console.log(e)}
        onChange={(e) => handleMapViewChange(e)}
        onChildClick={onMarkerSelect}
      >
        <Marker
          lat={mapBoundaries.minLatitude}
          lng={mapBoundaries.maxLongitude}
          text={100}
          listPrice={100}
        />
        <Marker
          lat={mapBoundaries.minLatitude}
          lng={mapBoundaries.minLongitude}
          text={100}
          listPrice={100}
        />
        <Marker
          lat={mapBoundaries.maxLatitude}
          lng={mapBoundaries.minLongitude}
          text={100}
          listPrice={100}
        />
        <Marker
          lat={mapBoundaries.maxLatitude}
          lng={mapBoundaries.maxLongitude}
          text={100}
          listPrice={100}
        />
        {listings.map((listing) => (
          <Marker
            lat={listing.latitude}
            lng={listing.longitude}
            text={priceShortener(listing.list_price)}
            key={listing.id}
            // listing={listing}
            listPrice={listing.list_price}
          />
        ))}
      </GoogleMap>
    </div>
  );
}
