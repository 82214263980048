import React, { useState } from "react";
import { useParams } from "react-router-dom";

import take from "lodash/take";

import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";

import ImageModal from "components/Listings/ImageModal";

import { useGet, post, patch } from "utils/restClient";

import NearbyListings from "components/Listings/NearbyListings.jsx";
import InfoTopSection from "components/Listings/InfoTopSection";
import InfoIconsSection from "components/Listings/InfoIconsSection";
import InfoTab from "components/Listings/Tabs/InfoTab";
import InsightsTab from "components/Listings/Tabs/InsightsTab";
import NotesTab from "components/Listings/Tabs/NotesTab";
import TrendsTab from "components/Listings/Tabs/TrendsTab";

import ListingMap from "components/Listings/ListingMap";
import ListingPhotosCarousel from "components/Listings/ListingPhotosCarousel";
import EstimatedPayments from "components/Listings/EstimatedPayments";
import OfferBanner from "components/Listings/OfferBanner";
import ScheduleShowing from "components/Listings/ScheduleShowing/index";

import TabBar from "components/shared/TabBar";

import { useCompressAppBar } from "components/shared/AppBar";
import { useCurrentUserStore } from "store/Store";
import StatusChip from "components/Landing/StatusChip";
import { Helmet } from "react-helmet";

const TABS = ["Info", "Insights", "Trends", "Notes"];

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function ListingShowScreen() {
  useCompressAppBar();
  const theme = useTheme();
  const isLgScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const { listingID } = useParams();

  const {
    data: detailsResponse,
    isLoading: detailsIsLoading,
    errors: detailsErrors,
  } = useGet(`/api/v1/listings/${listingID}/details`);

  const listing = detailsResponse?.data?.listing || {};

  const [seeAllImages, setSeeAllImage] = React.useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [refetchNotesCount, setRefetchNotesCount] = useState(0);
  const [refetchInsightsCount, setFefetchInsightsCount] = useState(0);
  const [isFavorited, setIsFavorited] = useState(listing?.is_favorited);
  const [userState] = useCurrentUserStore();
  const { loggedIn } = userState;

  React.useEffect(() => {
    if (!detailsIsLoading) {
      setIsFavorited(listing?.is_favorited);
    }
  }, [detailsIsLoading, listing]);

  const {
    data: photosResponse,
    isLoading: photosIsLoading,
    errors: photosErrors,
  } = useGet(`/api/v1/listings/${listingID}/photos`);

  const {
    data: nearbyListingsResponse,
    isLoading: nearbyListingsIsLoading,
    errors: nearbyListingsErrors,
  } = useGet(`/api/v1/listings/${listingID}/nearby_listings`, {}, {});

  const {
    data: insightsResponse,
    isLoading: insightsIsLoading,
    errors: insightsErrors,
  } = useGet(
    `/api/v1/listings/${listingID}/insights_data?refetch=${refetchInsightsCount}`,
    {},
    {},
    loggedIn
  );

  const {
    data: nearbyPlacesResponse,
    isLoading: nearbyPlacesIsLoading,
    errors: nearbyPlacesErrors,
  } = useGet(`/api/v1/listings/${listingID}/nearby_places`, {}, {}, loggedIn);

  const {
    data: trendsResponse,
    isLoading: trendsIsLoading,
    errors: trendsErrors,
  } = useGet(
    `/api/v1/postal_code_trends?postal_code=${listing?.postal_code_number}&property_type_id=${listing?.property_type_id}`,
    {},
    {},
    !detailsIsLoading && loggedIn
  );

  const {
    data: notesResponse,
    isLoading: notesIsLoading,
    errors: notesErrors,
  } = useGet(
    `/api/v1/listings/${listingID}/notes?refetch=${refetchNotesCount}`,
    {},
    {},
    loggedIn
  );

  async function handleAddTravelTime(name, address) {
    const res = await post(`/api/v1/add_travel_time`, {
      name: name,
      address: address,
    });
    // TODO: Loader
    setFefetchInsightsCount(refetchInsightsCount + 1);
  }

  async function removeTravelTime(travelTime) {
    const res = await destroy("/api/v1/saved_search/remove_travel_time", {
      travel_time_id: travelTime.id,
    });
    setFefetchInsightsCount(refetchInsightsCount + 1);
  }

  function handleRefetchNotes() {
    setRefetchNotesCount(refetchNotesCount + 1);
  }

  const handleFavoriteSelect = async () => {
    if (!loggedIn) {
      window.location.href = `login?afterSignIn=/${listingID}`;
    } else {
      const value = isFavorited;
      setIsFavorited(!isFavorited);

      try {
        if (isFavorited) {
          const res = await patch(
            `/api/v1/listings/${listingID}/remove_as_favorite`
          );
        } else {
          const res = await post(
            `/api/v1/listings/${listingID}/add_as_favorite`
          );
        }
      } catch {
        // fallback
        setIsFavorited(value);
      }
    }
  };

  if (detailsIsLoading || photosIsLoading) {
    return (
      <Box textAlign="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        mx: { xs: 0, md: -4, lg: -6 },
      }}
    >
      <Helmet>
        <title>
          {listing.address_1} {listing.city_name}
        </title>
        <meta
          name="description"
          key="description"
          content={`${listing.address_1} ${listing.city_name}`}
        />
        <link rel="canonical" href={listing.icon_image} />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="title"
          key="title"
          content={`${listing.address_1} ${listing.city_name}`}
        />
        <meta
          property="og:title"
          key="og:title"
          content={`${listing.address_1} ${listing.city_name}`}
        />
        <meta property="og:locale" key="og:locale" content="en_US" />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={`${listing.address_1} ${listing.city_name}`}
        />
        <meta property="og:image" key="og:image" content={listing.icon_image} />
      </Helmet>
      <Container
        maxWidth="xl"
        sx={{
          px: { xs: 0, sm: 2 },
        }}
      >
        {photosResponse?.data && (
          <>
            <Box
              sx={{
                position: { xs: "sticky", sm: "relative" },
                top: { xs: "56px", sm: 0 },
              }}
            >
              {isLgScreen ? (
                <ImageList
                  variant="quilted"
                  cols={12}
                  rowHeight={250}
                  gap={14}
                  sx={{
                    borderRadius: { xs: 0, md: "24px" },
                    m: 0,
                  }}
                >
                  {take(photosResponse.data, 3).map((item, index) => {
                    const isLast = index === 2;
                    const props = {
                      cols: 8,
                      rows: 2,
                      onClick: undefined,
                    };

                    if (index > 0) {
                      props.cols = 4;
                      props.rows = 1;
                    }

                    if (isLast) {
                      props.onClick = () => {
                        setSeeAllImage(true);
                      };
                      props.onKeyPress = (e) =>
                        e.key === "Enter" && setSeeAllImage(true);
                      props.tabIndex = 1;
                      props.sx = {
                        cursor: "pointer",
                        outlineColor: (theme) => theme.palette.primary.main,
                      };
                    }

                    return (
                      <ImageListItem key={item.id} {...props}>
                        <>
                          <Box
                            component="img"
                            sx={{ objectFit: "cover", width: "1", height: "1" }}
                            {...srcset(item.url, 250, props.rows, props.cols)}
                          />
                          {item?.order_number === 0 && (
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: "24px",
                                left: "24px",
                              }}
                            >
                              <StatusChip status={listing?.mls_status_id} />
                            </Box>
                          )}
                        </>

                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "24px",
                            right: "24px",
                            p: "8px 24px",
                            height: "30px",
                            borderRadius: "16px",
                            bgcolor: "rgba(0, 0, 0, .6)",
                            color: "primary.contrastText",
                            display: isLast ? "flex" : "none",
                            alignItems: "center",
                          }}
                        >
                          <Box display={{ xs: "none", md: "block" }}>
                            See{" "}
                            {photosResponse.data.length > 0
                              ? "all " + photosResponse.data.length
                              : ""}{" "}
                            photo{photosResponse.data.length > 0 ? "s" : ""}
                          </Box>

                          <Box display={{ xs: "block", md: "none" }}>
                            See all
                          </Box>
                        </Box>
                      </ImageListItem>
                    );
                  })}
                </ImageList>
              ) : (
                <Box position="relative">
                  <ListingPhotosCarousel photos={photosResponse?.data || []} />

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "16px",
                      left: "16px",
                      zIndex: 1,
                    }}
                  >
                    <StatusChip status={listing?.mls_status_id} />
                  </Box>
                </Box>
              )}
            </Box>

            <ImageModal
              imageList={photosResponse.data}
              open={seeAllImages}
              onClose={() => setSeeAllImage(false)}
            />
          </>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { md: "30px" },
            px: { xs: 2, sm: 0 },
            pt: { xs: 2, md: "40px" },
            position: { xs: "sticky", sm: "unset" },
            top: { xs: "56px", sm: 0 },
            zIndex: 1,
            borderRadius: "30px",
            bgcolor: "background.paper",
          }}
        >
          <Box
            sx={{
              flex: "1 auto",
            }}
            component="section"
          >
            <InfoTopSection
              listing={listing}
              loading={detailsIsLoading}
              handleFavoriteSelect={handleFavoriteSelect}
              isFavorited={isFavorited}
            />

            <InfoIconsSection listing={listing} loading={detailsIsLoading} />

            <Box
              sx={{
                mb: { xs: 2, md: "40px" },
                display: detailsIsLoading ? "none" : "block",
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: { xs: "54px", md: "64px" },
                  bgcolor: "background.paper",
                  zIndex: 1,
                }}
              >
                <TabBar
                  tabs={TABS}
                  currentTab={currentTab}
                  setCurrentTab={setCurrentTab}
                />
              </Box>

              {currentTab === 0 && (
                <InfoTab listing={listing} loading={detailsIsLoading} />
              )}

              {currentTab == 1 && (
                <InsightsTab
                  listing={listing}
                  insightsData={insightsResponse?.data}
                  loading={insightsIsLoading}
                  userLoggedIn={loggedIn}
                  nearbyPlaces={nearbyPlacesResponse?.data || []}
                  handleAddTravelTime={handleAddTravelTime}
                  removeTravelTime={removeTravelTime}
                />
              )}

              {currentTab == 2 && (
                <TrendsTab
                  trends={trendsResponse?.data || []}
                  userLoggedIn={loggedIn}
                  loading={trendsIsLoading || detailsIsLoading}
                  listing={listing}
                />
              )}

              {currentTab == 3 && (
                <NotesTab
                  listing={listing}
                  userLoggedIn={loggedIn}
                  notes={notesResponse?.data || []}
                  loading={notesIsLoading}
                  handleRefetchNotes={handleRefetchNotes}
                />
              )}
            </Box>

            <Box
              sx={{
                mb: { xs: 2, md: "40px" },
              }}
            >
              <ScheduleShowing
                listing={listing}
                loading={detailsIsLoading}
                userLoggedIn={loggedIn}
                value={listing?.scheduled_showing_request}
              />
            </Box>

            <Box
              sx={{
                height: { xs: "400px", md: "600px" },
                mb: { xs: 2, md: "40px" },
              }}
            >
              <ListingMap listing={listing} loading={detailsIsLoading} />
            </Box>

            <Box
              sx={{
                mb: { xs: 2, md: "40px" },
              }}
            >
              <Typography paragraph variant="subtitle1">
                <strong>Estimated Payment</strong>
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} xl={8}>
                  <EstimatedPayments
                    listing={listing}
                    loading={detailsIsLoading}
                  />
                </Grid>

                <Grid item xs={12} lg={6} xl={4}>
                  <OfferBanner />
                </Grid>
              </Grid>
            </Box>
          </Box>

          {!!nearbyListingsResponse?.data?.length && !photosIsLoading && (
            <Box
              sx={{
                minWidth: { xs: "100%", md: "340px", xl: "340px" },
                width: { xs: "100%", md: "340px", xl: "340px" },
              }}
              component="aside"
            >
              <Typography variant="subtitle1" paragraph>
                <strong>Related Listings</strong>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  overflow: "auto",
                  gap: "20px",
                  flexDirection: { xs: "row", md: "column" },
                }}
              >
                <NearbyListings nearbyListings={nearbyListingsResponse.data} />
              </Box>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
