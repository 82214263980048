import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { post } from "utils/restClient";

import NotLoggedIn from "./NotLoggedIn";

function NotesTab({
  listing,
  userLoggedIn,
  notes,
  loading,
  handleRefetchNotes,
}) {
  const [newNote, setNewNote] = useState("");
  const [posting, setPosting] = useState(false);

  const handleAddNote = async () => {
    try {
      if (newNote?.length > 0) {
        setPosting(true);
        const res = await post(`/api/v1/listings/${listing.id}/add_note`, {
          text: newNote,
        });

        await setPosting(false);
        handleRefetchNotes();
      }
    } finally {
      setPosting(false);
    }
  };

  if (!userLoggedIn) {
    return <NotLoggedIn listingID={listing.id} />;
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <Typography variant="h6" paragraph>
        <strong>Add New Note</strong>
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <TextareaAutosize
            placeholder="Type here"
            onChange={(e) => setNewNote(e.target.value)}
            maxRows={8}
            minRows={8}
            style={{
              border: "none",
              outline: "none",
              boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.11)",
              padding: "20px",
              borderRadius: "10px",
              resize: "none",
              fontFamily: "inherit",
              marginBottom: "16px",
              boxSizing: "border-box",
              width: "100%",
            }}
          />

          <LoadingButton
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleAddNote}
            loading={posting}
          >
            Submit
          </LoadingButton>
        </Grid>

        <Grid item container xs={12} md={8}>
          {notes.map((note) => (
            <Grid item xs={12} md={6} key={note.id}>
              <Box
                sx={{
                  height: "100%",
                  color: (theme) => theme.palette.primary.contrastText,
                  p: "24px",
                  boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.11)",
                  borderRadius: "10px",
                  background: `linear-gradient(275.01deg, #18AE94 0%, #00DEB7 99.4%);`,
                }}
              >
                <Typography variant="h6" color="inherit" gutterBottom>
                  <strong>{`${(note.author_first_name || "").charAt(0)}${(
                    note.author_last_name || ""
                  ).charAt(0)}`}</strong>
                </Typography>

                <Typography variant="subtitle1" color="inherit">
                  {note.text}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(NotesTab);
