import React from "react";
import NumberFormat from "react-number-format";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import sumBy from 'lodash/sumBy';
import { RadialChart, Hint } from 'react-vis';
import { constants } from "utils/constants";
import Card from 'components/shared/Card';

const calcTotal = (data) => sumBy(data, (i) => i.angle)

const makeData = (listing) => {
  return [
    { angle: Math.trunc(listing.association_fee), label: 'HOA', color: '#00DEB7' },
    { angle: parseInt(listing?.estimated_monthly_taxes), label: 'Taxes', color: '#05F0E2' },
    { angle: parseInt(listing?.estimated_monthly_insurance), label: 'Ins.', color: '#FE718C' }
  ]
};

export default function EstimatedPayments({ listing, loading }) {
  if (loading) {
    return null;
  }

  const data = makeData(listing);
  const total = calcTotal(data);

  return (
    <>
      <Card
        sx={{
          height: 'auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap'
          }}
        >
          <Box
            sx={{
              position: 'relative'
            }}
          >
            <RadialChart
              data={data}
              width={300}
              height={300}
              innerRadius={78}
              radius={100}
              colorType="literal"
              padAngle={() => .04}
              hideRootNode
            >
              {total && (
                <>
                  <NumberFormat
                    value={total}
                    thousandSeparator
                    prefix={constants.currency.symbol}
                    displayType="text"
                    decimalScale={0}
                    renderText={(value) => {
                      return (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: ({ typography }) => typography.h6.fontSize,
                            fontWeight: 600,
                            color: '#393973'
                          }}
                          component='b'
                        >
                          {value}
                        </Box>
                      )
                    }}
                  />
                </>
              )}
            </RadialChart>
          </Box>

          <Box>
            {
              data.map((item, index) => {
                return (
                  <Box
                    key={`data-index-${index}`}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      mb: '14px'
                    }}
                  >
                    <Box
                      sx={{
                        border: `5px solid ${item.color}`,
                        height: '24px',
                        width: '24px',
                        borderRadius: '100%',
                        mr: 1
                      }}
                    />

                    <Box color='#393973'>
                      {item.label}
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        </Box>
      </Card>
    </>
  );
}
