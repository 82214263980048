import React from 'react';
import moment from 'moment';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import {ReactComponent as IconCheckCircle} from 'assets/svgs/icons/icon-check-circle.svg'

import {extractDateAndTime} from './CalendarAndTimeButtons';

const SuccessMessage = ({
    date,
    time,
    handleCloseModal
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Box color='primary.main'>
                <IconCheckCircle height={120} width={120} />
            </Box>

            <Typography color='primary' variant='h4' paragraph>
                Great!
            </Typography>

            <Typography color='textSecondary' variant='subtitle1' paragraph>
                <b>Your showing has been schedule for</b> 
            </Typography>

            <Typography variant='subtitle1' paragraph>
                <b>{date.format('MMMM DD')} {time && `@${extractDateAndTime(time).moment.format('hh:mm A')}`}</b> 
            </Typography>

            <Box my={3}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={handleCloseModal}
                    disableElevation
                >
                    Continue
                </Button>
            </Box>
        </Box>
    )
}

export default SuccessMessage;
