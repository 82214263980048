import React, { useEffect, useState } from "react";
import isUndefined from "lodash/isUndefined";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

const API_URL =
  process.env.REACT_APP_API_URL || "https://howzer-staging.herokuapp.com/";

export const POST = "POST";
export const GET = "GET";
export const PUT = "PUT";
export const PATCH = "PATCH";
export const DELETE = "DELETE";

export const getToken = () => {
  try {
    // const userToken = await AsyncStorage.getItem("userToken");
    const userToken = window.sessionStorage.getItem("accessToken");
    return userToken;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const request = (requestOptions, apiUrl = API_URL) => {
  async function makeRequest(resolve, reject) {
    const injectedOptions = {
      ...requestOptions,
      url: apiUrl + requestOptions.url,
      headers: {
        ...requestOptions.headers,
        "Content-Type": "application/json",
      },
    };

    if (requestOptions.url !== "/users/sign_in") {
      injectedOptions["headers"][
        "Authorization"
      ] = `Bearer ${await getToken()}`;
    }
    axios(injectedOptions)
      .then((res) => resolve(res))
      .catch(async (error) => {
        // console.log(error.response.status);
        switch (error?.response?.status) {
          case 401:
            // await AsyncStorage.removeItem("userToken");
            resolve({ status: 401 });
          // reject(error.response);
          case 500:
            reject(error.response);
          default:
            return resolve(error.response);
        }
      });
  }

  return new Promise((resolve, reject) => {
    makeRequest(resolve, reject);
    const accessToken = getToken();
    // // if (accessToken === null) { reject(new Error('Error in getting token.')) } // dispatch unauthorized to logout?
    // const params = new URLSearchParams();
    // if (accessToken === null) {
    //   // Handle nul access token
    // } else {
    //   // const decodedToken = jwtDecode(accessToken);
    //   // const decodedToken = accessToken
    //   // if (decodedToken.exp - 180 < Math.floor(Date.now() / 1000)) {
    //   if (false) {
    //     // token is set to expire within 3 minutes or is already expired.
    //     // Handle refresh
    //   } else {
    //     makeRequest(resolve, reject);
    //   }
    // }
  });
};

export const get = (route, params = {}, ops = {}) => {
  // console.log("making get for " + route);
  return request({ method: GET, url: route, params, ...ops });
};

export const patch = (route, data, params = {}, ops = {}) =>
  request(
    { method: PATCH, data: data, params, url: route, ...ops },
    ops.apiUrl
  );

export const post = (route, data, params = {}, ops = {}) => {
  // console.log("making post for " + route);
  return request(
    { method: POST, data: data, params, url: route, ...ops },
    ops.apiUrl
  );
};

const destroy = (route, data, params = {}, ops = {}) =>
  request(
    { method: DELETE, data: data, params, url: route, ...ops },
    ops.apiUrl
  );

const useGet = (
  route,
  params = {},
  ops = {},
  executeIfTrue = true,
  maxRetry = 2
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState();
  const [data, setData] = useState({});
  const [reqParams, setReqParams] = useState(params);
  const [retryCount, setRetryCount] = useState(0);

  // const [toastState, toastDispatch] = useToastContext();
  const showRetry = retryCount < maxRetry;

  const handleRetry = (params) => {
    if (!isUndefined(params)) {
      params && setReqParams(params);
    }

    setRetryCount(retryCount + 1);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    const fetchData = async () => {
      try {
        setErrors(null);
        setIsLoading(true);
        const { data: responseBody } = await get(route, reqParams, {
          ...ops,
          cancelToken: source.token,
        });
        setData(responseBody);
        setIsLoading(false);
      } catch (e) {
        if (axios.isCancel(e)) return console.warn(route, e.message);
        // addToastNotification(toastDispatch, {
        //   kind: "error",
        //   title: "Fetch Error",
        //   message: e.message,
        // });
        console.log("about to error");
        console.log(e);

        setErrors(e);
        setIsLoading(false);
      }
    };
    if (executeIfTrue) fetchData();
    return () =>
      source.cancel(
        "Operation canceled by the user, a few of these are expected"
      );
  }, [route, reqParams, executeIfTrue, retryCount]);

  // useEffect(() => {
  //   if (reqParams !== params) {
  //     setReqParams(params);
  //   }
  // }, [params]);

  return { data, isLoading, errors, showRetry, handleRetry, reqParams };
};

const useExecuteDelete = (
  route,
  initialLoadingValue = false,
  successToastTitle = false
) => {
  const [isLoading, setIsLoading] = useState(initialLoadingValue);
  const [errors, setErrors] = useState();
  const [data, setData] = useState({});
  const [toastState, toastDispatch] = useToastContext();
  const cancelTokenRef = useRef();

  const cancel = () => {
    if (cancelTokenRef.current)
      cancelTokenRef.current.cancel(
        "Operation canceled by the user, a few of these are expected"
      );
  };

  const execute = async (
    payload = {},
    params = {},
    ops = {},
    sucessToastMsg = ""
  ) => {
    if (cancelTokenRef.current)
      cancelTokenRef.current.cancel(
        "Operation canceled by the user, a few of these are expected"
      );
    const source = axios.CancelToken.source();
    cancelTokenRef.current = source;
    try {
      setIsLoading(true);
      const { data: responseBody } = await destroy(route, payload, params, {
        ...ops,
        cancelToken: source.token,
      });
      setData(responseBody);
      setIsLoading(false);
      if (successToastTitle)
        addToastNotification(toastDispatch, {
          kind: TOAST_KINDS.SUCCESS,
          title: successToastTitle,
          message: sucessToastMsg,
        });
    } catch (e) {
      if (axios.isCancel(e)) return console.warn(route, e.message); // we don't want to setIsLoading to false in this case.
      addToastNotification(toastDispatch, {
        kind: TOAST_KINDS.ERROR,
        title: "Fetch Error",
        message: e.message,
      });
      setErrors(e);
      setIsLoading(false);
    }
  };

  return { data, isLoading, errors, execute, cancel };
};

const useDelete = (
  route,
  payload = {},
  params = {},
  ops = {},
  maxRetry = 2
) => {
  const [retryCount, setRetryCount] = useState(0);
  const [reqPayload, setReqPayload] = useState(payload);
  const [reqParams, setReqParams] = useState(
    JSON.parse(JSON.stringify(params))
  );
  const { data, isLoading, errors, execute, cancel } = useExecuteDelete(
    route,
    true
  );
  const showRetry = retryCount < maxRetry;

  const handleRetry = () => {
    setRetryCount(retryCount + 1);
  };

  useEffect(() => {
    if (retryCount <= maxRetry) {
      execute(reqPayload, reqParams, ops);
    }
    return cancel;
  }, [route, retryCount, reqPayload, reqParams]);

  useEffect(() => {
    if (!isEqual(reqPayload, payload)) {
      setReqPayload(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (!isEqual(reqParams, params)) {
      setReqParams(JSON.parse(JSON.stringify(params)));
    }
  }, [params]);

  return { data, isLoading, errors, showRetry, handleRetry };
};

const useExecutePost = (route, initialLoadingValue = false) => {
  const [isLoading, setIsLoading] = useState(initialLoadingValue);
  const [errors, setErrors] = useState();
  const [data, setData] = useState({});
  const cancelTokenRef = useRef();

  const cancel = () => {
    if (cancelTokenRef.current)
      cancelTokenRef.current.cancel(
        "Operation canceled by the user, a few of these are expected"
      );
  };

  const execute = async (payload = {}, params = {}, ops = {}) => {
    if (cancelTokenRef.current)
      cancelTokenRef.current.cancel(
        "Operation canceled by the user, a few of these are expected"
      );
    const source = axios.CancelToken.source();
    cancelTokenRef.current = source;
    try {
      setIsLoading(true);
      const { data: responseBody } = await post(route, payload, params, {
        ...ops,
        cancelToken: source.token,
      });
      setData(responseBody);
      setIsLoading(false);
    } catch (e) {
      if (axios.isCancel(e)) return console.warn(route, e.message); // we don't want to setIsLoading to false in this case.
      setErrors(e);
      setIsLoading(false);
    }
  };

  return { data, isLoading, errors, execute, cancel };
};

export default request;
export { useGet, useDelete, useExecuteDelete, destroy, useExecutePost };
