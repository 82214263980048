import React from "react";
import isEmpty from "lodash/isEmpty";
import { Navigate, Link } from "react-router-dom";

import One from "./steps/1";
import Two from "./steps/2";
import Three from "./steps/3";
import Four from "./steps/4";
import { useCurrentUserStore } from "store/Store";

import { ReactComponent as Logo } from "assets/svgs/howzerLogoTransparent.svg";

import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Hidden from "@mui/material/Hidden";

import { initialOnboardingState } from "utils/constants";

const steps = ["1", "2", "3", "4"];

export const lsConstants = {
  LS_VALUES: "onboardingValues",
  LS_STEP: "onboardingStep",
  Ls_TOKEN: "onboardingToken"
};

const getLocalStorageValue = () => {
  let values = localStorage.getItem(lsConstants.LS_VALUES);

  if (values === "undefined") {
    values = undefined;
  }

  if (values) {
    values = JSON.parse(values);
  }

  const activeStep = localStorage.getItem(lsConstants.LS_STEP);

  return { values, activeStep };
};

export const setLocalStorageValue = (values, activeStep) => {
  localStorage.setItem(lsConstants.LS_VALUES, JSON.stringify(values));
  localStorage.setItem(lsConstants.LS_STEP, activeStep);
};

export const resetLocalStorageValue = () => {
  localStorage.removeItem(lsConstants.LS_VALUES);
  localStorage.removeItem(lsConstants.LS_STEP);
  localStorage.removeItem(lsConstants.LS_TOKEN);
};

export default function OnBoarding() {
  const [state] = useCurrentUserStore();
  const [activeStep, setActiveStep] = React.useState(0);
  const [values, setValues] = React.useState();

  React.useLayoutEffect(() => {
    const { values, activeStep } = getLocalStorageValue();

    if (typeof activeStep === "string") {
      setActiveStep(Number(activeStep));
    }

    if (isEmpty(values)) {
      setValues(initialOnboardingState);
    } else {
      setValues(values);
    }
  }, []);

  React.useEffect(() => {
    setLocalStorageValue(values, activeStep);
  }, [values, activeStep]);

  const updateValues = (newValues = {}) => {
    setValues({ ...values, ...newValues });
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <One
            values={values}
            updateValues={updateValues}
            handleStep={handleStep}
          />
        );
      case 1:
        return (
          <Two
            values={values}
            updateValues={updateValues}
            handleStep={handleStep}
          />
        );
      case 2:
        return (
          <Three
            values={values}
            updateValues={updateValues}
            handleStep={handleStep}
          />
        );
      case 3:
        return <Four values={values} updateValues={updateValues} />;
      default:
        throw new Error("Unknown step");
    }
  }

  const handleStep = () => {
    setActiveStep(activeStep + 1);
  };

  if (state.loggedIn) {
    return (
      <Navigate
        to={{
          pathname: "/search",
        }}
      />
    );
  }

  return (
    <>
      <Container maxWidth="sm">
        <Link to="/" style={{ textDecoration: "none" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: "12px",
              color: (theme) => theme.palette.primary.main,
              mt: { xs: "30px", md: 0 },
            }}
          >
            <Logo color="inherit" width={46} height={46} />

            <Hidden mdDown>
              <Typography variant="h5" color="text.primary">
                <strong>HOWZER.APP</strong>
              </Typography>
            </Hidden>
          </Box>
        </Link>

        <Box mt="60px" mb="104px">
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    "& .MuiStepIcon-text": {
                      fontSize: "10px",
                    },

                    "& .MuiStepIcon-root": {
                      height: "46px",
                      width: "46px",
                    },
                  }}
                ></StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          {values && getStepContent(activeStep)}
        </Box>
      </Container>
    </>
  );
}
