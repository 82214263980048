const getBlockShadow = (shadow) => {
  let shadowStyles = {
    top: "-30%",
    left: "-30%",
    height: "60%",
    transform: "translate(30%, 38%)",
  };

  if (shadow === "center") {
    delete shadowStyles.top;
    shadowStyles.bottom = "59%";
    shadowStyles.left = "50%";
    shadowStyles.height = "114%";
    shadowStyles.transform = "translate(-50%, 58%)";
  }

  if (shadow === "bottom-right") {
    shadowStyles.top = "";
    shadowStyles.left = "";
    shadowStyles.right = "-30%";
    shadowStyles.bottom = "-3%";
    shadowStyles.transform = "translate(-30%, 7%)";
  }

  return {
    "&::after": {
      content: '""',
      position: "absolute",
      zIndex: -1,
      backgroundColor: "#D6E8E5",
      width: "70%",
      ...shadowStyles,
    },
  };
};

export default getBlockShadow;
