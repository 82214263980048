import React, { useState } from "react";
import Text from "../../../shared/Text";
import TouchableOpacity from "../../../shared/TouchableOpacity.jsx";
import { colors, fonts } from "../../../../utils/constants";
import removeIcon from "../../../../assets/images/Listing/remove_icon.png";
import addIcon from "../../../../assets/images/Listing/add_icon.png";
import RemoveTravelTimeModal from "./RemoveTravelTimeModal";
import { makeStyles } from "@mui/styles";

const styles = () => ({
  container: {
    padding: 20,
  },
  label: {
    fontFamily: fonts.bold,
    fontSize: 20,
    color: colors.darkGreyText,
    letterSpacing: 0.22,
    marginBottom: 20,
  },
  value: {
    fontFamily: fonts.regular,
    fontSize: 16,
    color: colors.lightGreyText,
    letterSpacing: 0.22,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  removeWrapper: {
    display: "flex",
  },
  remove: {
    height: 23,
    width: 23,
  },
  addIcon: {
    height: 85,
    width: 85,
  },
  zeroWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 22,
  },
  zeroStateText: {
    fontFamily: fonts.medium,
    fontSize: 14,
    color: colors.mainGreen,
    letterSpacing: 0.25,
    marginTop: 12,
  },
});

const useStyles = makeStyles(styles);

export default function EditTravelTimes({
  travelTimes,
  removeTravelTime,
  setShowAddModal,
  handleDismiss,
}) {
  const classes = useStyles();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [travelTimeToDelete, setTravelTimeToDelete] = useState(null);

  const Row = ({ travelTime }) => {
    const combined = `${travelTime.property_pretty} - ${
      travelTime.property.split(",")[0]
    }`;

    return (
      <div key={travelTime.id} className={classes.row}>
        <Text className={classes.value}>{combined.substring(0, 30)}</Text>
        <TouchableOpacity
          onPress={() => {
            setTravelTimeToDelete(travelTime);
            setShowRemoveModal(true);
          }}
          className={classes.removeWrapper}
        >
          <img className={classes.remove} src={removeIcon} />
        </TouchableOpacity>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <RemoveTravelTimeModal
        travelTime={travelTimeToDelete}
        showRemoveModal={showRemoveModal}
        handleConfirm={removeTravelTime}
        handleDismiss={() => {
          setShowRemoveModal(false);
          setTravelTimeToDelete(null);
        }}
      />
      <Text className={classes.label}>Edit Travel Times</Text>
      {(travelTimes || []).map((travelTime) => (
        <Row key={travelTime.id} travelTime={travelTime} />
      ))}
      <TouchableOpacity
        className={classes.zeroWrapper}
        onPress={() => {
          handleDismiss();
          setShowAddModal(true);
        }}
      >
        <img className={classes.addIcon} src={addIcon} />
        <Text className={classes.zeroStateText}>Add New Travel Time</Text>
      </TouchableOpacity>
    </div>
  );
}
