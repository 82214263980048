import React from "react";

const CurrentUserStore = React.createContext();
CurrentUserStore.displayName = "CurrentUser";

export const useCurrentUserStore = () => React.useContext(CurrentUserStore);

export const CurrentUserStoreProvider = ({
  children,
  initialState,
  reducer,
}) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CurrentUserStore.Provider value={[state, dispatch]}>
      {children}
    </CurrentUserStore.Provider>
  );
};

const SearchesStore = React.createContext();
SearchesStore.displayName = "Searches";

export const useSearchesStore = () => React.useContext(SearchesStore);

export const SearchesStoreProvider = ({ children, initialState, reducer }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <SearchesStore.Provider value={[state, dispatch]}>
      {children}
    </SearchesStore.Provider>
  );
};
