import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { ReactComponent as BedIcon } from "assets/svgs/icons/icon-bed.svg";
import { ReactComponent as BathIcon } from "assets/svgs/icons/icon-bath.svg";
import { ReactComponent as ShapeIcon } from "assets/svgs/icons/icon-shape.svg";
import { ReactComponent as ActiveIcon } from "assets/svgs/icons/icon-active.svg";
import { ReactComponent as CalendarIcon } from "assets/svgs/icons/icon-calendar.svg";
import { ReactComponent as DollarIcon } from "assets/svgs/icons/icon-dollar.svg";
import { ReactComponent as ClockIcon } from "assets/svgs/icons/icon-clock.svg";
import { ReactComponent as Shape2Icon } from "assets/svgs/icons/icon-shape2.svg";
import { ReactComponent as AcresIcon } from "assets/svgs/icons/icon-acres.svg";

import { convertPropertyTypeIDToText } from "utils/textHelpers";

const CommonBox = ({ icon, label }) => {
  return (
    <Box
      sx={{
        bgcolor: "#FAFAFA",
        display: "inline-flex",
        p: { xs: "10px", md: "20px" },
        flexDirection: "column",
        alignItems: "center",
        minWidth: { xs: "83px", md: "120px" },
        maxWidth: { xs: "83px", md: "120px" },
        borderRadius: "6px",

        "& svg": {
          width: { xs: "20px", md: "40px" },
          height: { xs: "20px", md: "40px" },
        },

        "& p": {
          fontSize: { xs: "12px", md: "14px" },
        },
      }}
    >
      <Box color="primary.main">{icon}</Box>

      <Typography color="inherit" noWrap>
        {label}
      </Typography>
    </Box>
  );
};

export default function InfoIconsSection({ listing, loading }) {
  if (loading) {
    return null;
  }

  const daysListedText =
    listing.days_listed > 1 ? `${listing.days_listed} days` : "Just Listed";
  const homeType = convertPropertyTypeIDToText(
    listing.property_type_id,
    listing.property_subtype_id
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: { xs: 1, md: 2 },
        mb: { xs: 3, md: "60px" },
      }}
    >
      <CommonBox icon={<BedIcon />} label={`${listing.bedrooms_total} Beds`} />

      <CommonBox
        icon={<BathIcon />}
        label={`${listing.bathrooms_total} Baths`}
      />

      <CommonBox
        icon={<ShapeIcon />}
        label={`${
          listing.living_area ? Math.round(listing.living_area) : "-"
        } sf`}
      />

      <CommonBox icon={<CalendarIcon />} label={`${listing.year_built}`} />

      <CommonBox
        icon={<DollarIcon />}
        label={`$${Math.round(listing.price_per_sq_foot)}/sf`}
      />

      {daysListedText && (
        <CommonBox icon={<ClockIcon />} label={daysListedText} />
      )}

      {homeType && <CommonBox icon={<Shape2Icon />} label={homeType} />}

      {listing?.lot_size_in_acres && (
        <CommonBox
          icon={<AcresIcon />}
          label={`${parseFloat(listing.lot_size_in_acres).toFixed(2)} acres`}
        />
      )}
    </Box>
  );
}
