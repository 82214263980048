import React from "react";
import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import {
  propertyClass,
  propertyTypes as propertyTypesCollection,
  propertyStatus,
  sizes,
} from "utils/constants";

function valuetext(value) {
  return value;
}

const initialValues = {
  minYearBuilt: "",
  maxYearBuilt: "",
  minLotSize: "",
  maxLotSize: "",
  minPrice: "",
  maxPrice: "",
  minHoaFee: "",
  maxHoaFee: "",
  minBedrooms: "",
  maxBedrooms: "",
  minBathrooms: "",
  maxBathrooms: "",
  minGarages: "",
  maxGarages: "",
  minSqFootage: "",
  maxSqFootage: "",
  minStories: "",
  maxStories: "",
  propertyTypes: [],
  statusTypes: [],
};

// This value is used in eval
const minPrice_distance = 10000;
const minSqFootage_distance = 10000;
const minLotSize_distance = 1;

const minLotSize_value = 0;
const maxLotSize_value = 100;

const minPrice_value = 0;
const maxPrice_value = 1000000;

const minSqFootage_value = 0;

const maxSqFootage_value = 1000000;

export default ({state, handleSetFilters}) => {
  const detailsData = state?.filterState;

  const formik = useFormik({
    initialValues: {
      propertyClassType: detailsData?.propertyClassType,
      propertyTypes: detailsData?.propertyTypes || [],
      minYearBuilt: detailsData?.min_year_built,
      maxYearBuilt: detailsData?.max_year_built,
      minLotSize: parseFloat(detailsData?.min_lot_size) || null,
      maxLotSize: parseFloat(detailsData?.max_lot_size) || null,
      minPrice: detailsData?.minPrice,
      maxPrice: detailsData?.maxPrice,
      minHoaFee: detailsData?.minHoaFee,
      maxHoaFee: detailsData?.maxHoaFee,
      minBedrooms: detailsData?.minBedrooms,
      maxBedrooms: detailsData?.maxBedrooms,
      minBathrooms: detailsData?.minBathrooms,
      maxBathrooms: detailsData?.maxBathrooms,
      minGarages: detailsData?.minGarages,
      maxGarages: detailsData?.maxGarages,
      minSqFootage: detailsData?.minSqFootage,
      maxSqFootage: detailsData?.maxSqFootage,
      minStories: detailsData?.minStories,
      maxStories: detailsData?.maxStories,
      statusTypes: detailsData?.statusTypes || [],
    },
    onSubmit: async (values) => {
      try {
        handleSetFilters(values)
      } catch (e) {
        console.log("error", e);
      }
    },
  });

  const handleClickProperty = (value) => {
    const parseValue = parseInt(value);
    const copidPropertySubType = [...propertyTypes];

    if (copidPropertySubType.includes(parseInt(parseValue))) {
      const propertyWithoutParseValue = copidPropertySubType.filter(
        (subtype) => subtype !== parseValue
      );
      formik.setFieldValue("propertyTypes", propertyWithoutParseValue);
    } else {
      copidPropertySubType.push(parseValue);
      formik.setFieldValue("propertyTypes", copidPropertySubType);
    }
  };

  const handleClickStatus = (value) => {
    const parseValue = String(value);
    const copidStatusTypes = [...statusTypes];

    if (
      copidStatusTypes.includes((parseValue)) ||
      copidStatusTypes.includes(value)
    ) {
      const statusWithoutParseValue = copidStatusTypes.filter(
        (subtype) => subtype !== parseValue || subtype !== value
      );
      formik.setFieldValue("statusTypes", statusWithoutParseValue);
    } else {
      copidStatusTypes.push(parseValue);
      formik.setFieldValue("statusTypes", copidStatusTypes);
    }
  };

  const handleClickButton = (e) => {
    formik.setFieldValue(e.target.name, parseInt(e.target.value));
  };

  const handleChangeSlider = (event, newValue, activeThumb) => {
    const { name } = event.target;

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      formik.setFieldValue(
        `min${name}`,
        Math.min(newValue[0], eval(`max${name}`) - eval(`min${name}_distance`))
      );
    } else {
      formik.setFieldValue(
        `max${name}`,
        Math.max(newValue[1], eval(`min${name}`) + eval(`min${name}_distance`))
      );
    }
  };

  const {
    minLotSize,
    maxLotSize,
    minYearBuilt,
    maxYearBuilt,
    minPrice,
    maxPrice,
    minHoaFee,
    maxHoaFee,
    minBedrooms,
    minBathrooms,
    minGarages,
    maxSqFootage,
    minSqFootage,
    minStories,
    maxStories,
    propertyClassType,
    propertyTypes,
    statusTypes,
  } = formik.values;

  return (
    <Box
      sx={{
        p: "24px 12px 12px 16px",
        bgcolor: "primary.light",
        borderRadius: "6px",
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <Box mb='18px'>
        <Typography variant='subtitle1'>
          <strong>More Filters</strong>
        </Typography>
      </Box>

      <Box mb='14px'>
        <InputLabel sx={{color: 'text.primary'}}>
          <b>Property Class</b>
        </InputLabel>
        <TextField
          name="propertyClassType"
          variant="standard"
          onChange={formik.handleChange}
          value={propertyClass[propertyClassType]}
          disabled
          fullWidth
        />
      </Box>

      {propertyClassType && (
        <Box mb='14px'>
          <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
            <b>Property Types(s)</b>
          </InputLabel>
          
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: '4px',
            }}
          >
            {Object.keys(propertyTypesCollection[propertyClassType])?.map((type) => (
              <Button
                key={type}
                onClick={() => handleClickProperty(type)}
                sx={{
                  minWidth: '91px',
                  bgcolor: propertyTypes.includes(parseInt(type)) ? '#323643 !important' : 'background.paper',
                  color: propertyTypes.includes(parseInt(type)) ? 'background.paper' : '#323643 !important',
                  border: '1px solid #DCDFE3',
                  boxSizing: 'border-box',
                  borderRadius: '10px'
                }}
              >
                {propertyTypesCollection[propertyClassType][type]}
              </Button>
            ))}
          </Box>
        </Box>
      )}

      <Box mb='14px'>
        <Box display="flex" alignItems='flex-end' mb='16px' justifyContent="space-between">
          <InputLabel sx={{color: 'text.primary'}}>
            <b>Price Range</b>
          </InputLabel>

          <Typography color='primary' variant='subtitle2'>
            <b>${minPrice || 0}K -{maxPrice || 0}K</b>
          </Typography>
        </Box>

        <Slider
          name="Price"
          value={[minPrice, maxPrice]}
          max={maxPrice_value}
          min={minPrice_value}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>

      <Box mb='14px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Status</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '4px'
          }}
        >
          {Object.keys(propertyStatus)?.map((type, index) => (
            <Button
              key={type + index}
              onClick={() => handleClickStatus(type)}
              sx={{
                minWidth: '91px',
                bgcolor: statusTypes.includes(String(type)) ? '#323643 !important' : 'background.paper',
                color: statusTypes.includes(String(type)) ? 'background.paper' : '#323643 !important',
                border: '1px solid #DCDFE3',
                boxSizing: 'border-box',
                borderRadius: '10px'
              }}
            >
              {propertyStatus[type]}
            </Button>
          ))}
        </Box>
      </Box>

      <Box mb='14px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Min Bedroom</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '4px'
          }}
        >
          {Object.keys(sizes).map((size, index) => (
            <Button
              name='minBedrooms'
              key={size + index}
              onClick={(e) => handleClickButton(e)}
              value={size}
              sx={{
                minWidth: '48px',
                bgcolor: minBedrooms === parseInt(size) || (size === 4 && minBedrooms > 3) ? '#323643 !important' : 'background.paper',
                color: minBedrooms === parseInt(size) || (size === 4 && minBedrooms > 3) ? 'background.paper' : '#323643 !important',
                border: '1px solid #DCDFE3',
                boxSizing: 'border-box',
                borderRadius: '10px'
              }}
            >
              {sizes[size]}
            </Button>
          ))}
        </Box>
      </Box>

      <Box mb='24px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Min Bathroom</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '4px'
          }}
        >
          {Object.keys(sizes).map((size, index) => (
            <Button
              key={size + index}
              name='minBathrooms'
              onClick={(e) => handleClickButton(e)}
              value={size}
              sx={{
                minWidth: '48px',
                bgcolor: (minBathrooms === parseInt(size) || (size === 4 && minBathrooms > 3)) ? '#323643 !important' : 'background.paper',
                color: (minBathrooms === parseInt(size) || (size === 4 && minBathrooms > 3)) ? 'background.paper' : '#323643 !important',
                border: '1px solid #DCDFE3',
                boxSizing: 'border-box',
                borderRadius: '10px'
              }}
            >
              {sizes[size]}
            </Button>
          ))}
        </Box>
      </Box>

      <Box mb='14px'>
        <Box display="flex" alignItems='flex-end' mb='16px' justifyContent="space-between">
          <InputLabel sx={{color: 'text.primary'}}>
            <b>Sq Footage</b>
          </InputLabel>

          <Typography color='primary' variant='subtitle2'>
            <b>{minSqFootage || 0} sqft - {maxSqFootage || 0} sqft</b>
          </Typography>
        </Box>

        <Slider
          value={[minSqFootage, maxSqFootage]}
          name="SqFootage"
          max={maxSqFootage_value}
          min={minSqFootage_value}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>

      <Box mb='24px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Year built</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 'wrap',
            gap: '12px'
          }}
        >
          <TextField
            type="number"
            name="minYearBuilt"
            value={minYearBuilt}
            variant="standard"
            onChange={formik.handleChange}
            fullWidth
          />

          <Box>
            -
          </Box>

          <TextField
            type="number"
            name="maxYearBuilt"
            variant="standard"
            value={maxYearBuilt}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      </Box>

      <Box mb='14px'>
        <Box display="flex" alignItems='flex-end' mb='16px' justifyContent="space-between">
          <InputLabel sx={{color: 'text.primary'}}>
            <b>Lot Size</b>
          </InputLabel>

          <Typography color='primary' variant='subtitle2'>
            <b>{minLotSize || 0} acres - {maxLotSize || 0} acres</b>
          </Typography>
        </Box>

        <Slider
          value={[minLotSize, maxLotSize]}
          name="LotSize"
          max={maxLotSize_value}
          min={minLotSize_value}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
        />
      </Box>

      <Box mb='24px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Stories</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 'wrap',
            gap: '12px'
          }}
        >
          <TextField
            type="number"
            name="minStories"
            min={0}
            value={minStories}
            variant="standard"
            onChange={formik.handleChange}
            fullWidth
          />

          <Box>
            -
          </Box>

          <TextField
            type="number"
            name="maxStories"
            min={0}
            variant="standard"
            value={maxStories}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      </Box>

      <Box mb='24px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Min Garage</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: '4px'
          }}
        >
          {Object.keys(sizes).map((size) => (
            <Button
              key={size}
              name="minGarages"
              value={size}
              onClick={(e) => handleClickButton(e)}
              sx={{
                minWidth: '48px',
                bgcolor: (minGarages === parseInt(size) || (size === 4 && minGarages > 3)) ? '#323643 !important' : 'background.paper',
                color: (minGarages === parseInt(size) || (size === 4 && minGarages > 3)) ? 'background.paper' : '#323643 !important',
                border: '1px solid #DCDFE3',
                boxSizing: 'border-box',
                borderRadius: '10px'
              }}
            >
              {sizes[size]}
            </Button>
          ))}
        </Box>
      </Box>

      <Box mb='34px'>
        <InputLabel sx={{color: 'text.primary', mb: '16px'}}>
          <b>Hoa Fee</b>
        </InputLabel>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flex: 'wrap',
            gap: '12px'
          }}
        >
          <TextField
            type="number"
            name="minHoaFee"
            value={minHoaFee}
            variant="standard"
            onChange={formik.handleChange}
            fullWidth
          />

          <Box>
            -
          </Box>

          <TextField
            type="number"
            name="maxHoaFee"
            variant="standard"
            value={maxHoaFee}
            onChange={formik.handleChange}
            fullWidth
          />
        </Box>
      </Box>

      <Button variant="contained" type="submit" fullWidth>
        Update
      </Button>
    </Box>
  );
};
