import React from "react";
import { makeStyles } from "@mui/styles";
import { fonts } from "../../utils/constants";
import PropTypes from "prop-types";

const styles = (theme) => ({
  container: {
    fontFamily: fonts.regular,
  },
});

const useStyles = makeStyles(styles);

function Text({ className, children = "", ...props }) {
  const classes = useStyles();
  return (
    <div className={`${className} ${classes.container}`} {...props}>
      {children?.toString()}
    </div>
  );
}

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Text;
