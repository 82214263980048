import React from 'react';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

import {searchSortAttributes} from "utils/constants";

const SortAttribute = ({state, handleSetFilters}) => {
    const value = state?.filterState?.sortAttribute || ''

    return (
        <MenuList>
            {
                searchSortAttributes.map((item, index) => {
                    const selected = item.value === value;
                    
                    return (
                        <MenuItem
                            key={item.value + index}
                            sx={{
                                backgroundColor: (theme) => selected ? theme.palette.primary.light : ''
                            }}
                            onClick={() => {
                                handleSetFilters({
                                    sortAttribute: item.value
                                })
                            }}
                        >
                            <ListItemIcon>
                                {selected ? <Check color='primary' /> : ''}
                            </ListItemIcon>

                            <ListItemText>
                                {item.label}
                            </ListItemText>
                        </MenuItem>
                    )
                })
            }
        </MenuList>
    )
}

export default SortAttribute;
