import React from "react";
import Modal from "../../../shared/Modal.jsx";
import Text from "../../../shared/Text";
import { colors, fonts } from "../../../../utils/constants";
import Button from "../../../shared/Button/Button";
import { makeStyles } from "@mui/styles";

const styles = () => ({
  removeModalView: {
    borderRadius: 20,
    padding: 30,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  removeButton: {
    marginTop: 10,
    width: 200,
    height: 40,
  },
  button: {
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  buttonOpen: {
    backgroundColor: "#F194FF",
  },
  buttonClose: {
    backgroundColor: "#2196F3",
  },
  modalText: {
    fontFamily: fonts.bold,
    fontSize: 16,
    color: colors.darkGreyText,
    letterSpacing: 0.17,
    textAlign: "center",
  },
});
const useStyles = makeStyles(styles);

export default function RemoveTravelTimeModal({
  travelTime,
  showRemoveModal,
  handleDismiss,
  handleConfirm,
}) {
  const classes = useStyles();
  return (
    <Modal visible={showRemoveModal} handleDismiss={() => handleDismiss()}>
      <div className={classes.removeModalView}>
        <Text className={classes.modalText}>
          {`Are you sure you want to delete: ${travelTime?.property_pretty}`}
        </Text>
        <Button
          color={colors.removeRed}
          title="DELETE"
          className={classes.removeButton}
          onPress={() => {
            handleDismiss();
            handleConfirm(travelTime);
          }}
        />
        <Button
          color={colors.lightGreyBackground}
          textColor={colors.darkGreyText}
          title="CANCEL"
          className={classes.removeButton}
          onPress={() => handleDismiss()}
        />
      </div>
    </Modal>
  );
}
