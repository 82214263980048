// import Constants from "expo-constants";

export const constants = {
  // host: Constants.manifest.extra.host,
  host: "https://howzer.app/",
  // host: "http://192.168.1.59:3000",
  defaultHeaders: { "Content-Type": "application/json" },
  currency: {
    code: "USD",
    symbol: "$",
  },
};

export const colors = {
  mainGreen: "#3DC1A9",
  landingBanner: "rgba(61, 193, 169, 0.15)",
  mainGreenText: "#00DEB7",
  markerLightGreen: "#F3FFFD",
  markerBorder: "#5BC4B1",
  mediumGreyBackground: "#AFAFAF",
  darkGreyText: "#323643",
  lightGreyText: "#989898",
  mediumGreyText: "#757575",
  lightGreyBackground: "#EFF3F8",
  shadowGrey: "#333",
  disabledGrey: "#D0D9D7",
  white: "#fff",
  darkBlack: "#0D0D0D",
  circleGrey: "#D8D8D8",
  tabBarBorder: "#EBEAEA",
  greyLine: "#D8D8D8",
  lightGreyCard: "#F5F9F9",
  lighterGreyCard: "#FFFAFE",
  removeRed: "#FA5F5F",
  iconGrey: "#50505A",
  markerGrey: "#C1BABA",
  inputBackground: "#DBE2E8",
  selectInputBackground: "#F2F5F8",
  noteCardBackground: "#CACDE1",
  greyButtonOutline: "#E7E7E7",
  landingFooter: "#EEEEEE",
  gradientStart: "#0D9DA6",
  gradientEnd: "#11DBB3",
  settingsDivider: "#F2F2F2",
  showingCardBackground: "#F2FAF9",
  showingCardBorder: "#257787",
  messageBlue: "#2C7EBF",
  messageGrey: "#D8D8D8",
  // pallete
  darkGreen: "#354B46",
  lightGreen: "#97B1AA",
  lightPurple: "#CA9DE2",
  darkPurple: "#9369AB",
  statusActive: "#00DEB7",
  statusUnderContract: "#DEBE00",
  statusBackup: "#DE5200",
  salmon: "#c25158",
  blue: "#4896c2",
  gold: "#f4d02d",
};

export const fonts = {
  regular: "SFProText-Regular",
  semibold: "SFProText-Semibold",
  medium: "SFProText-Medium",
  bold: "SFProText-Bold",
  heavy: "SFProText-Heavy",
};

export const boxShadow = [
  "4px 4px 10px rgba(0, 0, 0, 0.08)",
  "0px 2px 18px rgba(0, 0, 0, 0.26)",
];

export const storageKeys = {
  deviceID: "HOWZER_APP::DEVICE_ID",
  bootstrapMapUrl: "AIzaSyAQP6iYrwWIkScXMVRCaOkTrf2Z-uRW2RA",
};

export const defaultMapProps = {
  center: {
    lat: 40.590433536599626,
    lng: -111.82664550840855,
    latitudeDelta: 0.09440791166323947,
    longitudeDelta: 0.06884291768074036,
  },
  lat: 40.590433536599626,
  lng: -111.82664550840855,
  latitudeDelta: 0.09440791166323947,
  longitudeDelta: 0.06884291768074036,
  zoom: 12,
};

export const propertyClass = {
  1: "Residential",
  2: "Land",
};

export const propertySubtypes = {
  1: "Single Family",
  5: "TownHome",
  6: "Condo/Apt",
  2: "Residential Land",
  3: "Agricultural",
  4: "Commerical",
  8: "Recreation",
  9: "Other",
  26: "Multi Family",
};

export const propertyTypes = {
  1: {
    1: "Single Family",
    5: "TownHome",
    6: "Condo/Apt",
  },
  2: {
    2: "Residential Land",
    3: "Agricultural",
    4: "Commerical",
    8: "Recreation",
    9: "Other",
    26: "Multi Family",
  },
};

export const propertyStatus = {
  1: "Under Contract",
  2: "Active",
  3: "Backup",
};

export const searchSortAttributes = [
  { value: "distance", label: "Distance" },
  { value: "price", label: "Price" },
  { value: "date_listed", label: "Date Listed" },
  { value: "square_footage", label: "Square Footage" },
  { value: "bedrooms", label: "Bedrooms" },
  { value: "bathrooms", label: "Bathrooms" },
  { value: "acres", label: "Acres" },
];

export const sizes = { 1: "1", 2: "2", 3: "3", 4: "4+" };

export const initialOnboardingState = {
  name: "",
  propertyClassType: 1,
  propertyTypes: [],
  statusTypes: [],
  locations: [],
  drawnBoundaries: [],
  minPrice: 0,
  maxPrice: 0,
  minBedrooms: "",
  minBathrooms: "",
  minSqFootage: 0,
  maxSqFootage: 0,
  minYearBuilt: 0,
  maxYearBuilt: 0,
  minLotSize: 0,
  maxLotSize: 0,
  minGarages: "",
  minStories: "",
  maxStories: "",
  minHoaFee: "",
  maxHoaFee: "",
  isLoading: false,
};
