import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "85%",
    maxHeight: "70%",
    overflowY: "auto",
    margin: "auto",
    maxWidth: "700px",
    marginTop: "25%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "5px",
  },
}));

export default function SimpleModal({ visible, handleDismiss, children }) {
  const classes = useStyles();

  const body = <div className={classes.paper}>{children}</div>;

  useEffect(() => {
    if (visible) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    }
    return function cleanup() {
      document.getElementsByTagName("html")[0].style.overflow = "unset";
    };
  }, [visible]);

  return (
    <div>
      <Modal
        open={visible}
        onClose={handleDismiss}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
