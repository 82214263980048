import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";

export default function ListingShareShowScreen() {
  const [redirectToWebRoute, setRedirectToWebRoute] = useState(false);
  const { listingID } = useParams();

  useEffect(() => {
    window.open(`howzer://search?listing_id=${listingID}`, "_self");
    setTimeout(() => {
      setRedirectToWebRoute(true);
    }, 100);
  }, []);

  if (redirectToWebRoute) {
    return <Navigate to={`/${listingID}`} />;
  }

  return null;
}
