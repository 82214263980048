import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { priceShortener } from "../../utils/textHelpers";

class Marker extends React.Component {
  render() {
    const { listPrice, showPrice = true } = this.props;

    return (
      <Box sx={styles.wrapper}>
        <Box sx={styles.circle} />
        {showPrice && (
          <Box sx={styles.priceBox}>
            <Typography sx={styles.priceText}>
              {priceShortener(listPrice)}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
}

const CIRCLE_WIDTH = 30;
const CIRCLE_HEIGHT = 30;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  circle: {
    width: CIRCLE_WIDTH,
    height: CIRCLE_HEIGHT,
    left: -CIRCLE_WIDTH / 2,
    top: -CIRCLE_HEIGHT / 2,
    border: "11px solid #A1FFAB",
    borderRadius: CIRCLE_WIDTH / 2,
    backgroundColor: "#12DE26",
    textAlign: "center",
    // fontSize: 12,
    fontWeight: "bold",
    cursor: "pointer",
    marginBottom: 0.5,
  },
  priceBox: {
    height: 20,
    width: 50,
    backgroundColor: "white",
    color: "black",
    boxShadow: "0px 1px 7px rgba(0, 222, 183, 0.27)",
    borderRadius: 4,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  priceText: {
    fontSize: (theme) => theme.typography.pxToRem(12),
    fontWeight: (theme) => theme.typography.fontWeightBold,
  },
};

export default Marker;
