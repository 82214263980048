import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";

import Card from "components/shared/Card";

import Modal from "components/shared/Modal.jsx";
import EditTravelTimes from "./EditTravelTimes";
import AddTravelTimeModal from "./AddTravelTimeModal";

const CommonWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",

        "& > *": {
          flex: "1 auto",
        },
      }}
    >
      {children}
    </Box>
  );
};

const CommonTitle = ({ label }) => {
  return (
    <Typography color="textSecondary" fontWeight={300}>
      {label}
    </Typography>
  );
};

const CommonSubtitle = ({ label }) => {
  return (
    <Typography align="right">
      <b>{label}</b>
    </Typography>
  );
};

export default function NearbyPlaces({
  travelTimes,
  removeTravelTime,
  handleAddTravelTime,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <>
      <AddTravelTimeModal
        visible={showAddModal}
        handleDismiss={() => setShowAddModal(false)}
        handleConfirm={handleAddTravelTime}
      />

      <Modal
        visible={showEditModal}
        handleDismiss={() => setShowEditModal(false)}
      >
        <EditTravelTimes
          travelTimes={travelTimes}
          removeTravelTime={removeTravelTime}
          handleAddTravelTime={handleAddTravelTime}
          setShowAddModal={setShowAddModal}
          handleDismiss={() => setShowEditModal(false)}
        />
      </Modal>

      <Card sx={{ height: "100%", maxHeight: "100%" }}>
        <Typography
          variant="h6"
          color="primary"
          paragraph
          sx={{ fontWeight: 800 }}
        >
          <strong>Your Travel Times</strong>
          &nbsp;
          <IconButton onClick={() => setShowEditModal(true)} color="primary">
            <EditIcon />
          </IconButton>
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // gap: "16px",
            flex: "1 auto",
            // pr: 3,÷
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              maxHeight: 125,
              gap: "16px",
              pr: 3,
            }}
          >
            {travelTimes?.length > 0 ? (
              <>
                {travelTimes.map((time) => (
                  <CommonWrapper key={time.id}>
                    <CommonTitle label={time.property_pretty} />
                    <CommonSubtitle label={time.listing_value_pretty} />
                  </CommonWrapper>
                ))}
              </>
            ) : (
              <Box>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => setShowAddModal(true)}
                >
                  Add New Travel Time
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Card>
    </>
  );
}
