import React from "react";
// import MortgagePaymentPieChart from "../../Charts/MortagePaymentPieChart";
import NumberFormat from "react-number-format";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Text from "../../shared/Text";

const CommontTitle = ({ label }) => {
  return (
    <Typography variant="subtitle1" gutterBottom>
      <strong>{label}</strong>
    </Typography>
  );
};

const CommontSubtitle = ({ label }) => {
  return (
    <Typography
      variant="subtitle1"
      sx={{ fontWeight: 300 }}
      color="textSecondary"
    >
      {label}
    </Typography>
  );
};

export default function ListingMatchInfoTab({ listing, loading }) {
  if (loading) {
    return null;
  }

  return (
    <>
      <Box mb={{ xs: 2, md: "40px" }} component="section">
        <CommontTitle label="DESCRIPTION" />

        <CommontSubtitle label={listing.description} />
      </Box>

      <Box
        component="section"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "40px",
        }}
      >
        {listing.stories && (
          <Box>
            <CommontTitle label="Stories" />

            <CommontSubtitle label={Math.trunc(listing.stories)} />
          </Box>
        )}

        {listing.garage_spaces && (
          <Box>
            <CommontTitle label="Garage Size" />

            <CommontSubtitle label={Math.trunc(listing.garage_spaces)} />
          </Box>
        )}

        {listing.rooms_total && (
          <Box>
            <CommontTitle label="Total Rooms" />

            <CommontSubtitle label={Math.trunc(listing.rooms_total)} />
          </Box>
        )}

        {listing.association_fee && (
          <Box>
            <CommontTitle label="HOA Fee" />

            <NumberFormat
              value={Math.trunc(listing.association_fee)}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={0}
              renderText={(value, props) => <CommontSubtitle label={value} />}
            />
          </Box>
        )}

        <Box>
          <CommontTitle label="BSMT Direction" />

          <CommontSubtitle
            label={
              listing?.raw_response?.Basement == "None,Slab"
                ? "N/A"
                : listing.BasementFinished || "N/A"
            }
          />
        </Box>

        <Box>
          <CommontTitle label="Face Direction" />

          <CommontSubtitle
            label={
              listing.direction_faces == "" ? "-" : listing.direction_faces
            }
          />
        </Box>

        {listing?.raw_response?.AssociationAmenities && (
          <Box>
            <CommontTitle label="HOA Amenities" />

            <CommontSubtitle
              label={listing?.raw_response?.AssociationAmenities || "N/A"}
            />
          </Box>
        )}
      </Box>
    </>
  );
}
