import React from 'react';
import { Navigate, Link, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import MUILink from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import LoadingButton from "@mui/lab/LoadingButton";

import EmailIcon from '@mui/icons-material/Email';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import logingBackgroundSrc from 'assets/images/Login/login-background.svg'

import { loginUser } from "reducers/currentUserReducer";
import { post } from "utils/restClient";
import { useCurrentUserStore } from "store/Store";

const removeHighlightStyle = {
	'& > input:-webkit-autofill': {
		'&, &:hover, &:focus, &:active': {
			transition: 'background-color 5000s ease-in-out 0s',
			WebkitTextFillColor: '#fff'
		},
	}
};

const Background = ({ children }) => {
	return (
		<Box
			sx={{
				height: {xs: `calc(100vh - 56px)`, md: `calc(100vh - 64px)`},
				background: `url(${logingBackgroundSrc}) no-repeat center center fixed`,
				color: 'primary.contrastText'
			}}
		>
			{/* layer 1 */}
			<Box
				sx={{
					position: 'fixed',
					background: '#000',
					opacity: 0.68,
					top: {xs: '56px', md: '64px'},
					right: 0,
					bottom: 0,
					left: 0,
				}}
			/>

			{/* layer 2 */}
			<Box
				sx={{
					position: 'fixed',
					background: 'linear-gradient(180deg, #00DEB7 0%, rgba(0, 222, 183, 0) 60.71%)',
					opacity: 0.3,
					top: {xs: '56px', md: '64px'},
					right: 0,
					bottom: 0,
					left: 0,
				}}
			/>

			<Box
				sx={{
					position: 'fixed',
					top: {xs: '56px', md: '64px'},
					right: 0,
					bottom: 0,
					left: 0,
					zIndex: 1,
					overflow: 'auto'
				}}
			>
				{children}
			</Box>
		</Box>
	)
}

const CommonTextField = (props) => {
	return (
		<TextField
			{...props}
			fullWidth
			size='medium'
			variant='filled'
			InputProps={{
				disableUnderline: true,
				sx: {
					backgroundColor: 'transparent !important',
					border: (theme) => `1px solid ${theme.palette.primary.main}`,
					borderRadius: 1.5,
					color: (theme) => `${theme.palette.primary.contrastText} !important`,
					...removeHighlightStyle
				},
				...props.InputProps
			}}
			InputLabelProps={{
				sx: {
					color: (theme) => `inherit !important`,
				}
			}}
			sx={{
				mb: 2.5
			}}
		/>
	)
}

const emailDOMName = `email`;
const passwordDOMName = `password`;

const LoginScreen = () => {
	const [searchParams] = useSearchParams();
	const [state, dispatch] = useCurrentUserStore();
	const [showPassword, setShowPassword] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState('');

	const formik = useFormik({
		initialValues: {
			[emailDOMName]: '',
			[passwordDOMName]: ''
		},
		onSubmit: async ({ email, password }) => {
			try {
				setLoading(true);

				const result = await post(
					`/users/sign_in`,
					JSON.stringify({
						user: {
							email,
							password,
						},
					})
				);

				if (result.status === 200) {
					const token = result.headers?.authorization;

					const user = {
						// userToken: token.split("Bearer ")[1],
						// userName: userName,
						// email: userName,
					};

					loginUser(dispatch, user, token.split("Bearer ")[1]);
				} else {
					throw 'Invalid Password or Email.'
				}
			} catch (error) {
				if (typeof error === 'string') {
					setError(error)
				} else {
					setError('Something went wrong!')
				}

				console.error('login-error:', error)
			} finally {
				setLoading(false);

				setTimeout(() => {
					setError('')
				}, 5000)
			}
		},
	});

	if (state.loggedIn) {
		return (
			<Navigate
				to={{
					pathname: searchParams?.get?.('afterSignIn') || '/search'
				}}
			/>
		);
	}

	return (
		<>
			<Snackbar
				open={!!error}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				<Alert severity="error" sx={{ width: '100%' }}>
					{error}
				</Alert>
			</Snackbar>

			<Box
				sx={{
					m: { xs: 0, md: -4, lg: -6 }
				}}
			>
				<Background>
					<Container
						maxWidth='xs'
					>
						<Box
							sx={{
								height: {xs: `calc(100vh - 56px)`, md: `calc(100vh - 64px)`},
								py: 4,
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<Box
								onSubmit={formik.handleSubmit}
								component='form'
								sx={{
									flex: '1 auto',
									display: 'grid',
									placeItems: 'center'
								}}
							>
								<Box>
									<Typography color='inherit' align='center' variant='h5'>
										<b>Sign in to continue</b>
									</Typography>

									<Box
										sx={{
											my: 3.5
										}}
									>
										<CommonTextField
											name={emailDOMName}
											label='Email'
											type='email'
											required
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton color='primary' sx={{ pointerEvents: 'none' }}>
															<EmailIcon />
														</IconButton>
													</InputAdornment>
												)
											}}
											onChange={formik.handleChange}
										/>

										<CommonTextField
											name={passwordDOMName}
											label='Password'
											required
											type={showPassword ? 'text' : 'password'}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														<IconButton
															onClick={() => {
																setShowPassword(!showPassword)
															}}
															color='primary'
														>
															{
																showPassword ? (
																	<VisibilityIcon />
																) : (
																	<VisibilityOffIcon />
																)
															}
														</IconButton>
													</InputAdornment>
												)
											}}
											onChange={formik.handleChange}
										/>

										<LoadingButton
											fullWidth
											type='submit'
											size='large'
											color='primary'
											variant='contained'
											loading={loading}
											sx={{
												bgcolor: (theme) => `${theme.palette.primary.main} !important`
											}}
										>
											<Typography component='span' variant='h6' color='inherit'>
												<strong>Sign in</strong>
											</Typography>
										</LoadingButton>

										<Box sx={{ my: 2, textAlign: 'center' }}>
											<MUILink
												variant='body1'
												color='primary'
												to='/forgot-password'
												underline='hover'
												component={Link}
											>
												Forgot Password?
											</MUILink>
										</Box>
									</Box>
								</Box>
							</Box>

							<Box textAlign='center'>
								<Typography color='inherit' variant='subtitle1'>
									Don’t have any account? 
								</Typography>
								<MUILink
									variant='subtitle1'
									color='primary'
									to='/register'
									underline='hover'
									component={Link}
								>
									Register
								</MUILink>
							</Box>
						</Box>
					</Container>
				</Background>
			</Box>
		</>
	)
}

export default LoginScreen;
