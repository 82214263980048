import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { post } from "utils/restClient";
import { boxShadow, sizes } from "utils/constants";
import { lsConstants } from "../index"

const roomBox = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  gap: "12px",
};

const minMaxTextField = {
  "& .MuiOutlinedInput-root": {
    width: "157px",
    background: "rgba(0,0,0,0.02)",
    borderRadius: "10px",
  },
};

const sliderCustomCSS = {
  "& .MuiSlider-thumb": {
    backgroundColor: "background.paper",
    height: "28px",
    width: "28px",
    boxShadow: boxShadow[1],
  },
};

function valuetext(value) {
  return value;
}

export default function StepThree({ values, updateValues, handleStep }) {

  const handleSubmitValues = async () => {
    updateValues({ isLoading: true })
    try {
      const res = await post(`api/v1/onboarding/submit_filters`, { ...values });

      if (res.status === 200) {
        const token = res?.data?.data?.token;
        localStorage.setItem(lsConstants.Ls_TOKEN, token)
        // window.sessionStorage.setItem("accessToken", token);
        handleStep();
      }
    } catch (e) {
      console.error(e);
    } finally {
      updateValues({ isLoading: false })
    }
  };

  const handleStatus = (e) => {
    const name = e.target.name;
    if (name === "active") {
      updateValues({ statusTypes: ["active"] });
    } else if (name === "underContract") {
      updateValues({ statusTypes: ["underConstruct"] });
    } else {
      updateValues({ statusTypes: ["backup"] });
    }
  };

  const textHandler = (e) => {
    updateValues({ [e.target.name]: e.target.value });
  };

  const sliderHandler = (event, newValue) => {
    const name = event.target.name;
    if (name === "sqFootage") {
      updateValues({ minSqFootage: newValue[0], maxSqFootage: newValue[1] });
    } else if (name === "priceRange") {
      updateValues({ minPrice: newValue[0], maxPrice: newValue[1] });
    } else {
      updateValues({
        minLotSize: newValue[0],
        maxLotSize: newValue[1],
      });
    }
  };

  const roomButtons = React.useMemo(() => ({ active }) => ({
    minWidth: "48px",
    minHeight: "43px",
    bgcolor: active
      ? (theme) => `${theme.palette.primary.main} !important`
      : (theme) => theme.palette.primary.contrastText,
    color: active
      ? (theme) => theme.palette.primary.contrastText
      : (theme) => theme.palette.primary.main,
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "10px",
  }));

  const statusButton = React.useMemo(() => ({ active }) => ({
    bgcolor: active
      ? (theme) => `${theme.palette.primary.main} !important`
      : (theme) => theme.palette.primary.contrastText,
    color: active
      ? (theme) => theme.palette.primary.contrastText
      : (theme) => theme.palette.primary.main,
    p: "15px 20px",
    fontSize: (theme) => theme.typography.htmlFontSize,
    boxShadow: boxShadow[0],
    borderRadius: "5px",
  }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography variant="h5" align="center" color="text.primary" gutterBottom>
        <b>Other Filters</b>
      </Typography>

      <TextField
        name="name"
        placeholder="My First Search"
        variant="outlined"
        fullWidth
        sx={{
          mb: "20px",

          "& .MuiInputBase-root": {
            padding: "7.5px 4px 7.5px 6px",
            background: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
          },
        }}
        value={values.name}
        onChange={textHandler}
      />

      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Typography variant="subtitle2" color="primary.main">
          <Typography variant="inherit" color="text.primary" component="span">
            <strong>Price Range</strong> &nbsp;&nbsp;
          </Typography>

          <b>
            ${values?.minPrice}k - ${values?.maxPrice}K
          </b>
        </Typography>

        <Slider
          name="priceRange"
          max={1000000}
          value={[values?.minPrice, values?.maxPrice]}
          onChange={sliderHandler}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          sx={{ ...sliderCustomCSS }}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Typography variant="subtitle2" color="text.primary">
          <strong>Statuses</strong>
        </Typography>

        <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
          <Button
            name="active"
            sx={{
              ...statusButton({ active: values.statusTypes[0] === "active" }),
            }}
            onClick={handleStatus}
          >
            Active
          </Button>

          <Button
            name="underContract"
            sx={{
              ...statusButton({
                active: values.statusTypes[0] === "underConstruct",
              }),
            }}
            onClick={handleStatus}
          >
            Under Contract
          </Button>

          <Button
            name="backup"
            sx={{
              ...statusButton({ active: values.statusTypes[0] === "backup" }),
            }}
            onClick={handleStatus}
          >
            Backup
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: "15px",
        }}
      >
        <Typography variant="subtitle2">
          <strong>Min Bedrooms</strong>
        </Typography>

        <Box sx={{ ...roomBox }}>
          {Object.keys(sizes).map((item) => {
            return (
              <Button
                key={item}
                name="minBedrooms"
                size="small"
                sx={{
                  ...roomButtons({ active: item === values?.minBedrooms }),
                }}
                onClick={() => updateValues({ minBedrooms: item })}
              >
                {sizes[item]}
              </Button>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: "15px",
        }}
      >
        <Typography variant="subtitle2">
          <strong>Min Bathrooms </strong>
        </Typography>

        <Box sx={{ ...roomBox }}>
          {Object.keys(sizes).map((item) => {
            return (
              <Button
                key={item}
                name="minBathrooms"
                size="small"
                sx={{
                  ...roomButtons({ active: item === values.minBathrooms }),
                }}
                onClick={() => updateValues({ minBathrooms: item })}
              >
                {sizes[item]}
              </Button>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: "50px",
        }}
      >
        <Typography variant="subtitle2" color="primary.main">
          <Typography variant="inherit" color="text.primary" component="span">
            <strong>Square Footage </strong> &nbsp;&nbsp;
          </Typography>

          <b>
            {values?.minSqFootage || "No Min"} sqft -&nbsp;
            {values?.maxSqFootage || "No Max"} sqft
          </b>
        </Typography>

        <Slider
          name="sqFootage"
          value={[values?.minSqFootage, values?.maxSqFootage]}
          onChange={sliderHandler}
          max={1000000}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          sx={{ ...sliderCustomCSS }}
        />
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Typography variant="subtitle2">
          <strong>Year built</strong>
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "wrap",
            gap: "12px",
          }}
        >
          <TextField
            type="number"
            name="minYearBuilt"
            label="No Min"
            variant="outlined"
            value={values?.minYearBuilt}
            onChange={textHandler}
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ ...minMaxTextField }}
          />

          <Box>-</Box>

          <TextField
            type="number"
            name="maxYearBuilt"
            label="No Max"
            variant="outlined"
            value={values?.maxYearBuilt}
            onChange={textHandler}
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ ...minMaxTextField }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: "15px",
        }}
      >
        <Typography variant="subtitle1" color="primary.main">
          <Typography variant="inherit" color="text.primary" component="span">
            <strong>Lot Size </strong> &nbsp;&nbsp;
          </Typography>

          <b>
            {values.minLotSize || "No Min" } acres
            -&nbsp;
            {values.maxLotSize || "No Max" } acres
          </b>
        </Typography>

        <Slider
          name="lotSize"
          max={100}
          value={[values?.minLotSize, values?.maxLotSize]}
          onChange={sliderHandler}
          valueLabelDisplay="auto"
          getAriaValueText={valuetext}
          disableSwap
          sx={{ ...sliderCustomCSS }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          mt: "15px",
        }}
      >
        <Typography variant="subtitle2">
          <strong>Min Garage</strong>
        </Typography>

        <Box sx={{ ...roomBox }}>
          {Object.keys(sizes).map((item) => {
            return (
              <Button
                key={item}
                name="minGarage"
                size="small"
                sx={{ ...roomButtons({ active: item === values.minGarages }) }}
                onClick={() => updateValues({ minGarages: item })}
              >
                {sizes[item]}
              </Button>
            );
          })}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          mb: "20px",
        }}
      >
        <Typography variant="subtitle2">
          <strong>HOA Fee</strong>
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: "wrap",
            gap: "12px",
          }}
        >
          <TextField
            type="number"
            name="minHoaFee"
            value={values?.minHoaFee}
            label="No Min"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ ...minMaxTextField }}
            onChange={textHandler}
          />

          <Box>-</Box>

          <TextField
            type="number"
            name="maxHoaFee"
            value={values?.maxHoaFee}
            label="No Max"
            variant="outlined"
            InputProps={{ inputProps: { min: 0 } }}
            sx={{ ...minMaxTextField }}
            onChange={textHandler}
          />
        </Box>
      </Box>
      
      <LoadingButton
        loading={values?.isLoading}
        variant="contained"
        fullWidth
        onClick={handleSubmitValues}
        sx={{
          padding: "15px",
          boxShadow: "none",
          borderRadius: "6px",
          fontSize: (theme) => theme.typography.h6,

          "&:hover": {
            boxShadow: "none",
            borderRadius: "6px",
          },
        }}
      >
        Next
      </LoadingButton>
    </Box>
  );
}
