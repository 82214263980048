import React, { useEffect, useState, Component } from "react";
import GoogleMap from "google-map-react";
import { post } from "utils/restClient";
import { priceShortener } from "../utils/textHelpers";
import { storageKeys, defaultMapProps } from "utils/constants";
import Marker from "../components/Map/Marker.jsx";

const mapOptions = {
  fullscreenControl: false,
};

export default function MapTest({ height, disableFullScreen }) {
  const INITIAL_REGION = {
    latitude: 40.590433536599626,
    longitude: -111.82664550840855,
    latitudeDelta: 0.09440791166323947,
    longitudeDelta: 0.06884291768074036,
  };

  const [currentListings, setCurrentListings] = useState([]);
  const [selectedListingID, setSelectedListingID] = useState();

  useEffect(() => {
    async function fetchListings() {
      const res = await post(`api/v1/listings_by_coordinates?page=${1}`, {
        minLongitude: defaultMapProps.lng - defaultMapProps.longitudeDelta / 2,
        minLatitude: defaultMapProps.lat - defaultMapProps.latitudeDelta / 2,
        maxLongitude: defaultMapProps.lng + defaultMapProps.longitudeDelta / 2,
        maxLatitude: defaultMapProps.lat + defaultMapProps.latitudeDelta / 2,
        centerLatitude: defaultMapProps.lat,
        centerLongitude: defaultMapProps.lng,
      });
      setCurrentListings(res?.data?.data);
    }
    fetchListings();
  }, []);

  const onMarkerSelect = (key) => {
    const index = currentListings.findIndex((l) => l.id == key);
    setSelectedListingID(currentListings[index].id);
  };

  return (
    <div style={{ height: height || "calc(100vh - 56px)", width: "100%" }}>
      <GoogleMap
        bootstrapURLKeys={{ key: storageKeys.bootstrapMapUrl }}
        center={defaultMapProps.center}
        zoom={defaultMapProps.zoom}
        options={disableFullScreen ? mapOptions : ""}
        onDragEnd={(e) => console.log(e)}
        onChange={(e) => console.log(e)}
        onChildClick={onMarkerSelect}
      >
        {currentListings.map((listing) => (
          <Marker
            lat={listing.latitude}
            lng={listing.longitude}
            text={priceShortener(listing.list_price)}
            key={listing.id}
            // listing={listing}
            listPrice={listing.list_price}
          />
        ))}
      </GoogleMap>
    </div>
  );
}
