import { get } from "../utils/restClient";

const INITIAL_REGION = {
  latitude: 40.590433536599626,
  longitude: -111.82664550840855,
  latitudeDelta: 0.09440791166323947,
  longitudeDelta: 0.06884291768074036,
};

const initialSearchState = {
  locationOptions: [],
  fetchedLocationOptions: false,
  currentListing: null,
  initialListingTab: 0,
  filterState: {
    propertyClassType: 1,
    propertyTypes: [],
    statusTypes: [],
    selectedLocations: [],
    minPrice: null,
    maxPrice: null,
    minBedrooms: null,
    minBathrooms: null,
    minSqFootage: null,
    maxSqFootage: null,
    minYearBuilt: "no_min",
    maxYearBuilt: "no_max",
    minLotSize: null,
    maxLotSize: null,
    minGarages: null,
    minStories: null,
    maxStories: null,
    minHoaFee: "no_min",
    maxHoaFee: "no_max",
    currentRegion: INITIAL_REGION,
    centerLatitude: 40.578645596759,
    centerLongitude: -111.84233515650419,
    maxLatitude: 40.60967584115391,
    maxLongitude: -111.7970594683328,
    minLatitude: 40.5476153523641,
    minLongitude: -111.88761084467558,
    sortAttribute: "price",
    sortDirection: "asc",
  },
  recentLocationSearches: null,
};

export const SET_LOCATION_OPTIONS = "SET_LOCATION_OPTIONS";
export const SET_CURRENT_LISTING = "SET_CURRENT_LISTING";
export const CLEAR_CURRENT_LISTING = "CLEAR_CURRENT_LISTING";
export const RESET_STORE = "RESET_STORE";
export const SET_FILTER_STATE = "SET_FILTER_STATE";
export const SET_RECENT_LOCATION_SEARCHES = "SET_RECENT_LOCATION_SEARCHES";

const fetchLocationOptions = async (dispatch) => {
  const res = await get("/api/v1/search/locations");
  if (res?.status === 200) {
    dispatch({ type: SET_LOCATION_OPTIONS, locationOptions: res?.data?.data });
  } else {
    // TODO:
  }
};

const searchesReducer = (prevState = initialSearchState, action) => {
  switch (action.type) {
    case SET_LOCATION_OPTIONS:
      return {
        ...prevState,
        locationOptions: action.locationOptions,
        fetchedLocationOptions: true,
      };
    case SET_CURRENT_LISTING:
      return {
        ...prevState,
        currentListing: action.listing,
        initialListingTab: action.tab || 0,
      };
    case CLEAR_CURRENT_LISTING:
      return {
        ...prevState,
        currentListing: null,
      };
    case RESET_STORE:
      return {
        ...initialSearchState,
        filterState: {
          ...initialSearchState.filterState,
          currentRegion: prevState.filterState.currentRegion,
        },
        locationOptions: prevState.locationOptions,
      };
    case SET_FILTER_STATE:
      return { ...prevState, filterState: { ...action.filterState } };
    case SET_RECENT_LOCATION_SEARCHES:
      return {
        ...prevState,
        recentLocationSearches: [...action.recentLocationSearches],
      };
    default:
      return prevState;
  }
};

export { searchesReducer, initialSearchState, fetchLocationOptions };
