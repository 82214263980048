import React from "react";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

const HowzerModal = ({
    children,
    maxWidth,
    onClose,
    open,
    onKeyDown
}) => {
    return (
        <Dialog
			onClose={onClose}
			open={open}
			maxWidth={maxWidth}
			PaperProps={{
				sx: { bgcolor: "transparent" },
				elevation: 0,
			}}
			onKeyDown={onKeyDown}
		>
            <Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					margin: '6px 0'
				}}
			>
				<ButtonBase
					onClick={onClose}
					sx={{
						height: '40px',
						width: '40px',
						bgcolor: 'background.paper',
						borderRadius: '4px'
					}}
				>
					<CloseIcon />
				</ButtonBase>
			</Box>

			{children}
		</Dialog>
    )
}

export default HowzerModal;
