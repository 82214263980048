import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import Card from "components/shared/Card";

const CommonWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",

        "& > *": {
          flex: "1 auto",
        },
      }}
    >
      {children}
    </Box>
  );
};

const CommonTitle = ({ label }) => {
  return (
    <Typography color="textSecondary" fontWeight={300}>
      {label}&nbsp;
    </Typography>
  );
};

const CommonSubtitle = ({ label }) => {
  return (
    <Typography align="right">
      <b>{label}</b>
    </Typography>
  );
};

export default function NearbyPlaces({ nearbyPlaces }) {
  return (
    <Card sx={{ height: "100%" }}>
      <Typography
        variant="h6"
        color="primary"
        paragraph
        sx={{ fontWeight: 800 }}
      >
        <strong>Nearby Places</strong>
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          flex: "1 auto",
          pr: 3,
          maxHeight: { md: 450, lg: 255 },
          overflowY: "auto",
        }}
      >
        {(nearbyPlaces || []).map((place) => (
          <CommonWrapper key={place.id}>
            <CommonTitle label={place.name} />

            <CommonSubtitle
              label={`${parseFloat(place.distance_in_miles).toFixed(
                0
              )} mi away`}
            />
          </CommonWrapper>
        ))}
      </Box>
    </Card>
  );
}
