import React from "react";
import NumberFormat from "react-number-format";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Portal from "@mui/material/Portal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import { copyAndShareURL, copyAndShareURLStatus } from "utils/functions";

export default function InfoTopSection({
  listing,
  loading,
  handleFavoriteSelect,
  isFavorited,
}) {
  const [showCopiedSuccess, setShowCopiedSuccess] = React.useState(false);

  if (loading) {
    return null;
  }

  const handleShare = async () => {
    const status = await copyAndShareURL({
      title: listing?.address_1 || "Howzer.app",
      URL: window.location.href,
    });

    if (copyAndShareURLStatus.CLIPBOARD === status) {
      setShowCopiedSuccess(true);

      setTimeout(() => {
      	setShowCopiedSuccess(false)
      }, 5000);
    }
  };

  return (
    <Box
      sx={{
        mb: { xs: 2, md: "30px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          mb: "16px",
          justifyContent: "space-between",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <NumberFormat
          value={listing.list_price}
          thousandSeparator
          prefix={"$"}
          displayType="text"
          decimalScale={0}
          renderText={(value) => {
            return (
              <Box
                sx={{
                  bgcolor: "primary.light",
                  color: "primary.main",
                  minWidth: "120px",
                  p: { xs: "4px 8px", md: "8px 16px" },
                  textAlign: "center",
                  borderRadius: "20px",
                  fontSize: (theme) => ({
                    xs: "18px",
                    md: "20px",
                  }),
                }}
                component="b"
              >
                {value}
              </Box>
            );
          }}
        />

        <Box>
          <IconButton color="inherit" size="large" onClick={handleShare}>
            <IosShareOutlinedIcon fontSize="inherit" />
          </IconButton>

          <IconButton
            color="inherit"
            size="large"
            onClick={handleFavoriteSelect}
          >
            {isFavorited ? (
              <BookmarkIcon fontSize="inherit" />
            ) : (
              <BookmarkBorderOutlinedIcon fontSize="inherit" />
            )}
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: { xs: "24px", md: "48px" },
            lineHeight: 1.3,
          }}
        >
          <strong>{listing.address_1}</strong>
        </Typography>

        <Typography
          color="textSecondary"
          fontWeight={300}
          sx={{
            fontSize: { xs: "18px", md: "32px" },
          }}
        >
          {`${listing.city_name}, UT ${listing.postal_code_number}`}
        </Typography>

      </Box>

      {/* <>
				<Button
					text="Schedule Showing"
					onPress={() => setShowScheduleModal(true)}
					borderRadius={25}
					fontFamily={fonts.semibold}
					fontSize={10}
				/>
			</> */}

      <Portal>

        <Snackbar
          open={showCopiedSuccess}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success" sx={{ width: "100%" }}>
            Link copied to clipboard
          </Alert>
        </Snackbar>

      </Portal>
    </Box>
  );
}
