import React from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { colors, fonts } from "utils/constants";

const HowzerTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

const HowzerTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    color: colors.darkGreyText,
    marginRight: theme.spacing(4),
    fontWeight: theme.typography.fontWeightLight,

    "&:hover": {
      color: colors.darkGreyText,
      opacity: 1,
    },
    "&$selected": {
      color: `${colors.darkGreyText} !important`,
      fontWeight: theme.typography.fontWeightBold,
    },

    "&:focus": {
      color: colors.darkGreyText,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(1),
  },
}));

export default function TabBar({ tabs, currentTab, setCurrentTab }) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <HowzerTabs
          // centered
          value={currentTab}
          onChange={handleChange}
          aria-label="Howzer tab"
          variant="scrollable"
          scrollButtons='auto'
          selectionFollowsFocus
        >
          {tabs.map((tab) => (
            <HowzerTab label={tab} key={tab} />
          ))}
        </HowzerTabs>
        <Typography className={classes.padding} />
      </div>
    </div>
  );
}
