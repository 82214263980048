import { colors } from "./constants";

export const validateEmail = (text) => {
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (reg.test(text) === false) {
    return false;
  } else {
    return true;
  }
};

export function priceShortener(price) {
  if (price === 1500000) return "1.5M+";
  else if (price === 1000000) return "1M";
  else if (price > 999 && price < 1000000) {
    return (price / 1000).toFixed(0) + "K"; // convert to K for price from > 1000 < 1 million
  } else if (price >= 1000000) {
    return (price / 1000000).toFixed(2) + "M"; // convert to M for price from > 1 million
  } else if (price < 900) {
    return price?.toFixed(); // if value < 1000, nothing to do
  }
}

export function priceShortenerForMap(price) {
  if (price >= 1000000) return (price / 1000000).toFixed(2) + "M";
  else return (price / 1000).toFixed(0) + "K";
}

export function convertMlsStatusIdToText(id) {
  switch (id) {
    case 1:
      return "Under Contract";
    case 3:
      return "Backup";
    default:
      return "Active";
  }
}

export function convertMlsStatusIdToColor(id) {
  switch (id) {
    case 1:
      return colors.statusUnderContract;
    case 3:
      return colors.statusBackup;
    default:
      return colors.statusActive;
  }
}

export function convertPropertyTypeIDToText(propertyTypeID, subTypeID) {
  if (propertyTypeID === 2) {
    return "Land";
  } else {
    switch (subTypeID) {
      case 5:
        return "Town Home";
      case 6:
        return "Condo";
      case 9:
        return "Mobile Home";
      case 10:
        return "Duplex";
      default:
        return "Single Family";
    }
  }
}
