import React from "react";
import map from "lodash/map";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { boxShadow, propertyTypes } from "utils/constants";

const getStatusButtonStyle = (active) => ({
  bgcolor: active
    ? (theme) => `${theme.palette.primary.main} !important`
    : (theme) => `${theme.palette.primary.light} !important`,
  color: active
    ? (theme) => `${theme.palette.primary.contrastText} !important`
    : (theme) => `${theme.palette.text.primary} !important`,
  padding: "15px",
  boxShadow: active ? boxShadow[0] : "none",
  borderRadius: "5px",
  fontSize: (theme) => theme.typography.fontSize,
});

export default function StepOne({ values, updateValues, handleStep }) {
  const [hasValue, setHasValue] = React.useState(false);

  const changedValue = (_, newValue) => {
    updateValues({ propertyTypes: newValue });
  };

  React.useEffect(() => {
    setHasValue(
      !!values.propertyTypes.length &&
      !!values.propertyClassType
    );
  }, [values]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography align="center" variant="h5" color="text.primary" gutterBottom>
        <b>What Property Type?</b>
      </Typography>

      <Autocomplete
        fullWidth
        multiple
        sx={{
          "& .MuiInputBase-root": {
            padding: "15px",
            background: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
          },
        }}
        options={map(propertyTypes[2], (_, value) => Number(value))}
        filterSelectedOptions
        value={values.propertyTypes}
        getOptionLabel={(option) => propertyTypes[2][option]}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Property Type"
            variant="outlined"
          />
        )}
        ChipProps={{
          color: "primary",
        }}
        onChange={changedValue}
      />

      <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        {map(propertyTypes[1], (label, value) => {
          const active = values.propertyClassType == value;

          return (
            <Button
              sx={getStatusButtonStyle(active)}
              key={`propertyclass-type-${value}`}
              id={`propertyclass-type-${value}`}
              onClick={() => {
                updateValues({
                  propertyClassType: Number(value),
                });
              }}
            >
              {label}
            </Button>
          );
        })}
      </Box>

      <Button
        variant="contained"
        disabled={!hasValue}
        fullWidth
        onClick={handleStep}
        sx={{
          padding: "15px",
          boxShadow: "none",
          borderRadius: "6px",
          fontSize: (theme) => theme.typography.h6,

          "&:hover": {
            boxShadow: "none",
            borderRadius: "6px",
          },
        }}
      >
        Next
      </Button>
    </Box>
  );
}
