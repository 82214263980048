import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import IconSearch from "@mui/icons-material/Search";

import { useSearchesStore } from "store/Store.js";
import { colors } from "utils/constants.js";
import { fetchLocationOptions } from "reducers/searchesReducer";

import HowzerAutoComplete from "../shared/Inputs/AutoComplete.jsx";

const searchButtonWidth = {
  xs: '80px',
  md: '100px'
};

export default function BannerLocationSearch({ }) {
  const navigate = useNavigate();
  const [searchesState, searchesDispatch] = useSearchesStore();
  const { locationOptions, fetchedLocationOptions } = searchesState;

  useEffect(() => {
    if (!fetchedLocationOptions) {
      fetchLocationOptions(searchesDispatch);
    }
  }, [fetchedLocationOptions]);

  const onSearchChange = (e, newValue) => {
    if (newValue.location_type === "City") {
      navigate(`/search?cityIDs=${newValue.location_id}`);
    } else if (newValue.location_type === "PostalCode") {
      navigate(`/search?postalCodeIDs=${newValue.location_id}`);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#F3F3F3',
        boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.1);",
        height: { xs: '68px', md: "88px" },
        maxWidth: { xs: 'auto', md: "411px" },
        width: "100%",
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        pr: searchButtonWidth
      }}
    >
      <Box flex='1 auto'>
        <HowzerAutoComplete
          options={locationOptions || []}
          onChange={onSearchChange}
          focusOnRender={true}
          placeholder="City/Postal Code"
        />
      </Box>

      <ButtonBase
        sx={{
          bgcolor: colors.gold,
          position: 'absolute',
          right: 0,
          width: searchButtonWidth,
          height: `calc(100% + 20%)`,
          top: `-10%`,
          bottom: `-5%`,
          color: 'primary.contrastText',
          pointerEvents: 'none',
        }}
        disableRipple
      >
        <IconSearch fontSize='large' color='inherit' />
      </ButtonBase>
    </Box>
  );
}
