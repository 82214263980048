import React from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";

import heroImageSrc from "assets/images/Search/hero-image.png";
import { useGet } from "utils/restClient";
import VerifyAuthenticated from "components/shared/VerifyAuthenticated";

import Filter from "./Filter";
import Matches from "./Matches";

export const DetailContext = React.createContext({});

function SavedSearchesDetail() {
  const { id } = useParams();
  const savedSearchDetails = useGet(`/api/v1/saved_searches/${id}`);
  const savedSearchMatches = useGet(`/api/v2/listing_matches`, {
    saved_search_id: id,
    items: 30,
    page: 1,
  });

  const locations = useGet(`/api/v1/saved_searches/${id}/locations`);

  return (
    <>
      <VerifyAuthenticated />

      <Box
        sx={{
          mb: "40px",
        }}
      >
        <Typography
          variant="h2"
          color="inherit"
          sx={{
            letterSpacing: "0.923077px",
            fontSize: (theme) => theme.typography.pxToRem(28),
            color: (theme) => theme.palette.text.primary,
          }}
        >
          {savedSearchDetails?.data?.data?.saved_search?.name && (
            <strong>{savedSearchDetails.data.data.saved_search.name}</strong>
          )}
        </Typography>
      </Box>

      <DetailContext.Provider
        value={{
          savedSearchMatches,
          savedSearchDetails,
        }}
      >
        <Box
          sx={{
            display: { lg: "flex" },
            flexWrap: "wrap",
          }}
          component="main"
        >
          <Box
            sx={{
              flexGrow: 1,
              flexBasis: "416px",
              alignSelf: "start",
              position: { lg: "sticky" },
              top: "0",
              "--offset": "0",
              overflow: "hidden",
            }}
            component="aside"
          >
            <Filter />
          </Box>
          <Box
            sx={{
              flexBasis: 0,
              flexGrow: 999,
              minWidth: "40%",
            }}
            component="article"
          >
            <Matches />
          </Box>
        </Box>
      </DetailContext.Provider>
    </>
  );
}

export default SavedSearchesDetail;
