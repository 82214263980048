import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/styles/useTheme";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Rating from '@mui/material/Rating'
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden'

import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconArrowForward from '@mui/icons-material/ArrowForward'

import LocationSearch from "components/Landing/BannerLocationSearch";
import FeaturedListings from "components/Landing/FeaturedListings";
import FooterBlock from "components/Landing/FooterBlock";
import getBlockShadow from "components/Landing/getBlockShadow";
import { useGet } from "utils/restClient";
import { colors } from "utils/constants";

import landingBannerSrc from "assets/images/Marketing/LandingBanner1.png";
import landingMobileAppSrc from "assets/images/Marketing/MobileApp1.png";
import iconAnalyticsSrc from "assets/svgs/Landing/analytics.svg";
import iconNotificationSrc from "assets/svgs/Landing/notification.svg";
import iconBusinessmanSrc from "assets/svgs/Landing/businessman.svg";
import { ReactComponent as IconFocus } from "assets/svgs/Landing/icon-focus.svg";
import { ReactComponent as IconFilter } from "assets/svgs/Landing/icon-filter.svg";
import { ReactComponent as IconDownload } from "assets/svgs/Landing/icon-download.svg";
import { ReactComponent as IconChat } from "assets/svgs/Landing/icon-chat.svg";
import { ReactComponent as IconAppStores } from "assets/svgs/Landing/app-store.svg";
import { appBarSection1ClassName, useCompressAppBar } from 'components/shared/AppBar'

const TransparentToolbar = () => {
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window,
	});

	useCompressAppBar();

	React.useEffect(() => {
		const changeColor = (trigger) => {
			const DOM = document.querySelector(`.${appBarSection1ClassName}`);

			if (DOM) {
				if (trigger) {
					DOM.childNodes.forEach((node) => {
						node.style.color = 'inherit';
					})
				} else {
					DOM.childNodes.forEach((node) => {
						node.style.color = '#fff';
					})
				}
			}
		}

		changeColor(trigger);

		return () => {
			changeColor(true)
		}
	}, [appBarSection1ClassName, trigger])

	return (
		<Box
			sx={{
				position: 'fixed',
				width: '100%',
				top: 0,
				background: (theme) => ({ xs: theme.palette.primary.main, md: `linear-gradient(to right, ${theme.palette.primary.main} 60%, #F6F0E4 40%)` }),
			}}
		>
			<Toolbar sx={{ bgcolor: 'transparent', px: '0 !important' }} />
		</Box>
	)
}

const QuoteText = ({ text }) => {
	return (
		<Typography
			fontSize={(theme) => ({
				xs: theme.typography.body1.fontSize,
				md: theme.typography.subtitle1.fontSize
			})}
			color="primary.contrastText"
		>
			<strong>{text}</strong>
		</Typography>
	);
};

const RatingBox = ({ text, captionName }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				backgroundColor: "#307466",
				maxWidth: "280px",
				flex: '1 auto',
				p: 3
			}}
		>
			<Typography color="primary.contrastText" paragraph>{text}</Typography>

			<Typography paragraph color={colors.mainGreenText} align="right">
				<b>{captionName}</b>
			</Typography>

			<Rating name="read-only" value={5} readOnly />
		</Box>
	);
};

const FeatureCard = ({
	icon,
	title,
	description,
	shadow = 'top-left'
}) => {
	const shadowStyles = getBlockShadow(shadow)

	return (
		<Box
			sx={{
				mt: { xs: 6 },
				position: "relative",
				zIndex: 1,
				width: "100%",
			}}
		>
			<Box
				sx={{
					p: '60px 40px',
					minHeight: '400px',
					backgroundColor: (theme) => theme.palette.primary.contrastText,
					color: "inherit",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					...shadowStyles
				}}
			>
				<Box mb="24px">{icon}</Box>

				<Typography variant="h6" paragraph>
					<strong>{title}</strong>
				</Typography>

				<Typography fontWeight={400} align="center">
					{description}
				</Typography>
			</Box>
		</Box>
	);
};

const FeaturedIconList = ({ icon, iconTxt }) => {
	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				gap: "30px",
				mb: "24px",
			}}
		>
			<Box
				sx={{
					height: "60px",
					width: '60px',
					backgroundColor: "#fff",
					borderRadius: "100%",
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',

					'& > *': {
						height: '50%',
						width: '50%'
					}
				}}
			>
				{icon}
			</Box>

			<Typography variant="subtitle1" sx={{ mt: "10px" }}>
				<b>{iconTxt}</b>
			</Typography>
		</Box>
	);
};

const useSwiperSettings = ({ theme }) => {
	const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
	const isTablet = useMediaQuery(theme.breakpoints.down("md")) && !isPhone;
	const isSmallDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

	return React.useMemo(() => {
		let height = 175;
		let slidesPerView = 4;

		if (isPhone) {
			height = 175;
			slidesPerView = 1.2;
		} else if (isTablet) {
			height = 200;
			slidesPerView = 2;
		} else if (isDesktop) {
			height = 254;
			slidesPerView = 4;
		} else if (isSmallDesktop) {
			height = 175;
			slidesPerView = 4;
		}

		return { height: height, slidesPerView: slidesPerView };
	}, [isPhone, isTablet, isSmallDesktop, isDesktop]);
};

const LandingPage = () => {
	const { data, isLoading } = useGet(`api/v1/featured_listings`);
	const theme = useTheme();
	const swiperSettings = useSwiperSettings({ theme });
	const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
	const featuredListings = data?.data || [];

	return (
		<Box sx={{ backgroundColor: "#eef8f6", m: { xs: 0, md: -4, lg: -6 } }}>
			<TransparentToolbar />

			<Box
				sx={{
					background: (theme) => ({ xs: theme.palette.primary.main, md: `linear-gradient(to right, ${theme.palette.primary.main} 60%, #F6F0E4 40%)` }),
				}}
			>
				<Container
					maxWidth="xl"
					sx={{
						display: "flex",
						alignItems: "stretch",
						flexWrap: { xs: 'wrap', md: 'unset' },
						minHeight: {lg: 'calc(100vh - 64px)'},
						overflow: 'hidden'
					}}
				>
					<Box
						sx={{
							p: { xs: '48px 0px', md: "160px 260px 40px 0px" },
							// ml: {md: -3},
							flex: { xs: '1 auto', md: "1 0 60%" },
							color: 'primary.contrastText'
						}}
					>
						<Typography
							component="div"
							color='inherit'
							sx={{
								fontWeight: 600,
								fontSize: theme => ({ xs: '30px', md: theme.typography.h3.fontSize }),
								textAlign: { xs: 'center', md: 'unset' },
								mb: { xs: '48px', md: '16px' }
							}}
						>
							<Typography
								variant="inherit"
								component="strong"
								fontSize='inherit'
								sx={{ color: "#f4d02d" }}
							>
								Howzer&nbsp;
							</Typography>

							The Better <br /> Way To Buy a Home
						</Typography>

						<Hidden mdDown>
							<Typography
								variant="h6"
								sx={{ fontWeight: 400, color: 'inherit' }}
							>
								Empowering you with data, insights, and expertise <br /> to give
								you confidence buying a home
							</Typography>

							<br />
							<br />
						</Hidden>

						<Box
							sx={{
								display: "flex",
								alignItems: "flex-start",
								flexWrap: 'wrap',
								mb: '68px',
								justifyContent: { xs: 'center', md: 'unset' },

								'& > *': {
									position: 'relative',
									mr: { xs: '20px !important', md: 'unset' },
									pl: { md: 2 },

									'&:last-child': {
										'&::after': {
											display: { xs: 'none', md: 'block' }
										}
									},

									'&::after': {
										content: '""',
										height: { xs: '50px', md: "2px" },
										width: { xs: '2px', md: "50px" },
										mt: { md: 1 },
										ml: { md: -2 },
										display: { xs: 'inline-block', md: 'block' },
										background: "#f4d02d",
										position: { xs: 'absolute', md: 'unset' },
										top: 0,
										right: '-10px'
									}
								}
							}}
						>
							<QuoteText
								leftText
								text={
									<>
										A simpler <br /> process.
									</>
								}
							/>

							<QuoteText
								centerText
								text={
									<>
										A house full <br /> of savings.
									</>
								}
							/>

							<QuoteText
								text={
									<>
										An agent who <br /> has your back.
									</>
								}
							/>
						</Box>

						<Hidden mdDown>
							<Box sx={{ display: "flex", gap: "12px" }}>
								<RatingBox
									text={
										<>
											The perfect help in a stressful <br /> market and the cash
											back
											<br /> was incredible
										</>
									}
									captionName="- James M"
								/>

								<RatingBox
									text={
										<>
											The perfect help in a stressful <br /> market and the cash
											back
											<br /> was incredible
										</>
									}
									captionName="- James M"
								/>
							</Box>
						</Hidden>
					</Box>

					<Box
						sx={{
							flex: { xs: '1 auto', md: "1 0 40%" },
							display: "flex",
							alignItems: "center",
							pb: 'calc(48 * 2px)',
							m: {md: -10}
						}}
					>
						<Box
							sx={{
								display: "block",
								height: { xs: "300px", md: '440px' },
								width: { xs: 1, md: `calc(100% + 160px)` },
								minWidth: '300px',
								ml: { md: "-220px" },
								mt: -4,
								position: "relative",
							}}
						>
							<Box
								src={landingBannerSrc}
								component="img"
								sx={{
									height: "100%",
									width: "calc(100% - 16px)",
									position: "absolute",
									zIndex: 2,
								}}
							/>

							<Box
								sx={{
									position: "absolute",
									height: "160px",
									width: "120px",
									top: "-16px",
									bgcolor: "#B1E6DD",
									zIndex: 1,
									right: 0,
								}}
							/>

							<Box
								sx={{
									position: "absolute",
									zIndex: 3,
									bottom: 0,
									right: { xs: '32px', md: '16px' },
									width: { xs: "300px", md: "400px" },
									my: "-50px",
								}}
							>
								<LocationSearch />
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>

			<Container
				maxWidth='lg'
				sx={{
					display: 'flex',
					justifyContent: 'center',
					py: { xs: 10, md: 20 },
					overflow: 'hidden'
				}}
			>
				<Grid
					container
					spacing={4}
				>
					<Grid item xs={12} md={4} justifyContent='center'>
						<FeatureCard
							key="item1"
							shadow='top-left'
							icon={<img height="120px" width="120px" src={iconAnalyticsSrc} />}
							title="Data Insights"
							description="Smart simple data. Buy like an investor, not a first-time home buyer"
						/>
					</Grid>

					<Grid item xs={12} md={4} justifyContent='center'>
						<FeatureCard
							key="item2"
							shadow='center'
							icon={<img height="120px" width="120px" src={iconNotificationSrc} />}
							title="Instant Alerts"
							description="Relevant, real-time new listing alerts. Choose between text, mobile, and email"
						/>
					</Grid>

					<Grid item xs={12} md={4} justifyContent='center'>
						<FeatureCard
							key="item3"
							shadow='bottom-right'
							icon={<img height="120px" width="120px" src={iconBusinessmanSrc} />}
							title="Expert Help"
							description="Let our experienced agents navigate the process with you. No need to be on your own."
						/>
					</Grid>
				</Grid>
			</Container>

			<Container
				maxWidth="xl"
				sx={{
					display: "flex",
					justifyContent: "center",
					flexWrap: 'wrap',
					gap: { xs: '0px', md: '100px' },
					py: 4
				}}
			>
				<Box
					sx={{
						minWidth: { xs: '360px', md: '480px' },
						width: { xs: '360px', md: '480px' },
						position: 'relative',
						zIndex: 1,

						'&::after': {
							content: '""',
							background: '#D6E8E5',
							height: '60%',
							width: '40%',
							position: 'absolute',
							left: '-8%',
							top: '-4%',
							zIndex: -1,
						}
					}}
				>
					<Box
						sx={{
							width: '100%'
						}}
						component="img"
						src={landingMobileAppSrc}
					/>
				</Box>

				<Box
					sx={{
						pt: { xs: 4, md: 8 }
					}}
				>
					<Typography variant="h5">
						<b>Clean and Simple Mobile App</b>
					</Typography>

					<br />

					<FeaturedIconList
						icon={<IconFocus />}
						iconTxt="Draw Boundaries on Map"
					/>

					<FeaturedIconList
						icon={<IconFilter />}
						iconTxt="All the Filters you Need"
					/>

					<FeaturedIconList
						icon={<IconDownload />}
						iconTxt="Save Multiple Searches"
					/>

					<FeaturedIconList
						icon={<IconChat />}
						iconTxt="Real-Time Agent Chat"
					/>

					<IconAppStores width="325px" />
				</Box>
			</Container>

			{
				!isLoading && (
					<Box
						sx={{
							py: { xs: 4, md: 8 },
							position: 'relative',
							zIndex: 1,

							'&::after': {
								content: '""',
								height: { xs: '60%', md: '56%' },
								width: { xs: '60%', md: '40%' },
								backgroundColor: '#D6E8E5',
								top: '24px',
								position: 'absolute',
								zIndex: -1,
								left: 0,
							}
						}}
					>
						<Container maxWidth='xl'>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '16px'
								}}
							>
								<Hidden mdDown implementation="css">
									<IconButton
										className='featured-listings-prev'
									>
										<IconArrowBack />
									</IconButton>
								</Hidden>

								<Box overflow='hidden'>
									<FeaturedListings
										featuredListings={featuredListings}
										swiperSettings={swiperSettings}
										isDesktop={isLargeScreen}
									/>
								</Box>

								<Hidden mdDown implementation="css">
									<IconButton
										className='featured-listings-next'
									>
										<IconArrowForward />
									</IconButton>
								</Hidden>
							</Box>
						</Container>
					</Box>
				)
			}

			<Container maxWidth="xl" sx={{ py: { xs: 4, md: 8 }, px: { xs: 2, md: 10 }, overflow: 'hidden' }}>
				<FooterBlock />
			</Container>

			<br />
			<br />

			<Divider />

			<Box p={2}>
				<Typography align="center" gutterBottom>
					Copyright: © {new Date().getFullYear()} Howzer.app. All rights
					reserved.
				</Typography>
			</Box>
		</Box>
	);
};

export default LandingPage;
