import React from "react";
import { Formik } from "formik";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import EmailIcon from "@mui/icons-material/Email";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { post, getToken } from "utils/restClient";
import { useCurrentUserStore } from "store/Store";
import { loginUser } from "reducers/currentUserReducer";
import { resetLocalStorageValue, lsConstants } from "../index";

const CommonTextField = (props) => {
  return (
    <TextField
      {...props}
      variant="filled"
      fullWidth
      InputProps={{
        disableUnderline: true,
        ...props.InputProps,

        sx: {
          borderRadius: 1.5,
        },
      }}
    />
  );
};

export default function StepFour({ values, updateValues }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [state, dispatch] = useCurrentUserStore();
  const isLoading = values.isLoading

  const handleSubmission = async (values) => {
    updateValues({ isLoading: true })
    try {
      const lsToken = localStorage.getItem(lsConstants.Ls_TOKEN);
      window.sessionStorage.setItem("accessToken", lsToken);
      const result = await post(
        `/api/v1/onboarding/finalize_user_credentials`,
        {
          ...values,
        }
      );

      if (result.status === 200) {
        const user = {
          // userToken: token.split("Bearer ")[1],
          // userName: userName,
          // email: userName,
        };

        const token = getToken();
        loginUser(dispatch, user, token);
        resetLocalStorageValue();
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      updateValues({ isLoading: false })
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Typography variant="h5" align="center">
        <strong>
          <Typography variant="inherit" component="span" color="primary">
            Nice!&nbsp;
          </Typography>
          
          Looks like there’s already 10 <br /> homes matching your critiera!
        </strong>
      </Typography>

      <Typography variant="h6" align="center" fontWeight={400}>
        All that’s left is to create your login.
      </Typography>

      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          password: "",
        }}
        onSubmit={(values) => handleSubmission(values)}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }

          if (!values.phone) {
            errors.phone = "Required";
          }

          if (!values.password) {
            errors.password = "Required";
          } else if (values.password.length < 6) {
            errors.password = "Invalid password, must be at least 6 characters";
          }
          if (!values.firstName) {
            errors.firstName = "Required";
          }
          if (!values.lastName) {
            errors.lastName = "Required";
          }
          return errors;
        }}
      >
        {({ handleChange, handleSubmit, values, errors, touched }) => (
          <>
            <Grid container spacing="20px">
              <Grid item xs={12}>
                <CommonTextField
                  name="firstName"
                  label="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  required
                />

                <Typography color="error" component="span">
                  {errors.firstName && touched.firstName && errors.firstName}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CommonTextField
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  required
                />

                <Typography color="error" component="span">
                  {errors.lastName && touched.lastName && errors.lastName}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CommonTextField
                  label="Phone"
                  name="phone"
                  placeholder="801-123-4567"
                  value={values.phone}
                  onChange={handleChange}
                  required
                />

                <Typography color="error" component="span">
                  {errors.phone && touched.phone && errors.phone}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CommonTextField
                  name="email"
                  type="email"
                  label="Email Address"
                  value={values.email}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          sx={{ pointerEvents: "none" }}
                        >
                          <EmailIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography color="error" component="span">
                  {errors.email && touched.email && errors.email}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <CommonTextField
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          color="primary"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography color="error" component="span">
                  {errors.password && touched.password && errors.password}
                </Typography>
              </Grid>
            </Grid>

            <LoadingButton
              loading={isLoading}
              variant="contained"
              fullWidth
              type="submit"
              onClick={handleSubmit}
              sx={{
                padding: "15px",
                boxShadow: "none",
                borderRadius: "6px",
                fontSize: (theme) => theme.typography.h6,

                "&:hover": {
                  boxShadow: "none",
                  borderRadius: "6px",
                },
              }}
            >
              Finish
            </LoadingButton>
          </>
        )}
      </Formik>
    </Box>
  );
}
