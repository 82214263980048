import React from "react";
import moment from 'moment';

import Box from '@mui/material/Box';
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import CalendarPicker from '@mui/lab/CalendarPicker';

const TimeButton = ({ value, active, onClick, disabled }) => {
    return (
        <ButtonBase
            sx={{
                bgcolor: active ? 'primary.main' : 'primary.light',
                color: active ? 'primary.light' : 'text.primary',
                px: { xs: 1, lg: 6 },
                height: '56px',
                boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.08)',
                borderRadius: '6px',
                fontWeight: (theme) => theme.typography.fontWeightBold,
                transition: `all .08s ease-in`,

                '&:disabled': {
                    bgcolor: 'divider',
                    boxShadow: 'none'
                }
            }}
            onClick={() => onClick(value)}
            disabled={disabled}
        >
            {value}
        </ButtonBase>
    );
};

export const extractDateAndTime = (value) => {
    const dataTimeArray = value.split("T");
    const date = dataTimeArray[0]
    const time= dataTimeArray[1].slice(0, 5)

    return {
        date,
        time,
        moment: moment(date + ' ' + time)
    }
}

const CalendarAndTimeButtons = ({
    time,
    date,
    handleSetDate,
    handleSetTime,
    handleConfirm,
    availabilities,
    loading
}) => {
    const validDates = availabilities.dates;
    const availabilitiesDates = availabilities.availabilities;
    
    return (
        <>
            <Typography variant='h6'>
                Schedule Showing
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: { xs: '20px', lg: '60px' },
                    mt: 4
                }}
            >
                <Box
                    sx={{
                        width: { xs: '1', lg: 'auto' }
                    }}
                >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Typography color='textSecondary' paragraph>
                            Select Date:
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Box
                                sx={{
                                    bgcolor: 'primary.light',
                                    borderRadius: '6px'
                                }}
                            >
                                <CalendarPicker
                                    date={date.toDate()}
                                    onChange={(value) => {
                                        const date = moment(value);
                                        handleSetDate(moment(date.toDate()))
                                    }}
                                    minDate={new Date()}
                                    shouldDisableDate={(value) => {
                                        const date = moment(value).format('YYYY-MM-DD');
                                        return !validDates.includes(date);
                                    }}
                                />
                            </Box>
                        </Box>
                    </LocalizationProvider>
                </Box>

                <Box
                    sx={{
                        flex: '1 auto',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography color='textSecondary' paragraph>
                        Select Time:
                    </Typography>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: { xs: `repeat(2, minmax(100px, 1fr))`, lg: `repeat(4, minmax(100px, 1fr))` },
                            gridGap: '20px',
                            pr: { xl: 14 },
                            mb: 6
                        }}
                    >
                        {
                            availabilitiesDates[date.format('YYYY-MM-DD')].map((t, index) => {
                                const extractedTime = extractDateAndTime(t).moment;
                                let active = time === t;
                                
                                return (
                                    <TimeButton
                                        key={`schedule-time-${index}`}
                                        value={extractedTime.format('hh:mm A')}
                                        onClick={() => handleSetTime(t)}
                                        active={active}
                                    />
                                )
                            })
                        }
                    </Box>

                    {
                        time && (
                            <Box
                                sx={{
                                    mb: 6
                                }}
                            >
                                <Typography variant='h5' color='textPrimary'>
                                    {/* {date.format('ddd, MMMM DD')} {time} */}
                                    {date.format('ddd, MMMM DD')} {time && `at ${extractDateAndTime(time).moment.format('hh:mm A')}`}
                                </Typography>
                            </Box>
                        )
                    }

                    <Box
                        sx={{
                            mt: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            size='large'
                            sx={{
                                minWidth: '160px',
                                width: { xs: 1, lg: 'auto' }
                            }}
                            onClick={handleConfirm}
                            disabled={!time}
                            loading={loading}
                        >
                            Confirm
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default CalendarAndTimeButtons;
