import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import NumberFormat from "react-number-format";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import Typography from "@mui/material/Typography";
import { constants } from "utils/constants";
import Image from "components/Image";
import StatusChip from "components/Landing/StatusChip";

/*
item should have data:

icon_image,
address_1,
list_price,
city_name,
living_area,
bedrooms_total,
bathrooms_total
*/

export default function ListingCardNew({
  item,
  isSelected,
  scrollToRef,
  imageHeight,
  width = "340px",
  href,
}) {
  const ref = useRef(null);
  const navigate = useNavigate();
  const redirectTo = href || `/${item.id}`;

  useEffect(() => {
    if (isSelected) {
      scrollToRef && scrollToRef(ref);
    }
  }, [isSelected]);

  const itemStatus = item?.mls_status_id

  return (
    <Box
      sx={{
        mb: "16px",
        boxShadow: "0px 1px 11px rgba(0, 0, 0, 0.1)",
        border: "1px solid rgba(0, 0, 0, 0.03)",
        borderRadius: "8px",
        overflow: "hidden",
        cursor: "pointer",
        minWidth: {md: width},
        backgroundColor: 'background.paper'
      }}
      ref={ref}
      onClick={() => navigate(redirectTo)}
    >
      <Box
        sx={{
          height: "auto",
          width: "1",
          height: imageHeight || "140px",
          position: 'relative'
        }}
      >
        <Box
          src={item?.icon_image}
          sx={{
            height: "100%",
            width: "100%",
            objectFit: "cover",

          }}
          component={Image}
        />

        {
          itemStatus && (
            <Box position='absolute' left={10} bottom={10}>
              <StatusChip status={itemStatus} />
            </Box>
          )
        }
      </Box>

      <Box
        sx={{
          p: "15px 21px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            flexWrap: "wrap",
            mb: "12px",
          }}
        >
          <Typography variant="subtitle1" sx={{ flex: "1 auto" }}>
            <strong>{item?.address_1}</strong>
          </Typography>

          {item?.list_price && (
            <NumberFormat
              value={item.list_price}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
              renderText={(value) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "#50505A",
                    }}
                  >
                    <Typography color="primary" variant="subtitle1">
                      {constants.currency.symbol}
                    </Typography>

                    <strong>{value}</strong>
                  </Box>
                );
              }}
            />
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            ml: "-8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              fontSize: "14px",
              flex: "1 auto",
            }}
          >
            {item?.city_name && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlinedIcon color="primary" />

                {item?.city_name}
              </Box>
            )}

            {item.living_area && (
              <NumberFormat
                value={item.living_area}
                displayType={"text"}
                decimalScale={0}
                thousandSeparator={true}
                renderText={(value) => {
                  if (!value) return null;

                  return (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HomeOutlinedIcon color="primary" />

                      <>{value} SQ Ft</>
                    </Box>
                  );
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              fontWeight: 300,
              gap: "6px",
            }}
          >
            {item?.bedrooms_total && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BedOutlinedIcon fontSize="inherit" color="primary" />
                &nbsp;{item?.bedrooms_total}
              </Box>
            )}

            {item?.bathrooms_total && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BathtubOutlinedIcon fontSize="inherit" color="primary" />
                &nbsp;{item?.bathrooms_total}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
