import React from "react";
import Button from "@mui/material/Button";

function MiniButton({ name, icon, onClick, ...rest }) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        borderRadius: "10px",
        fontSize: (theme) => theme.typography.caption,
        minWidth: "72px",
        display: "flex",
        flexDirection: "column",
      }}
      {...rest}
    >
      {icon}
      {name}
    </Button>
  );
}

export default MiniButton;
