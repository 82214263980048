import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useCurrentUserStore } from "../../store/Store";

export default function VerifyAuthenticated() {
  const [userState] = useCurrentUserStore();

  if (!userState.loggedIn) {
    return <Navigate replace to={{ pathname: "/login" }} />;
  }
  return null;
}
