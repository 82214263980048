import React from 'react';
import Chip from '@mui/material/Chip';
import {colors, propertyStatus} from 'utils/constants';

const StatusChip = ({status}) => {
    const value = propertyStatus?.[status];

    if (!value) {
        return null;
    }

    let bgcolor = colors.mainGreenText;

    if (status == 3) {
        bgcolor = '#DE5200';
    } else if (status == 1) {
        bgcolor = '#DEBE00';
    }

    return (
        <>
            <Chip 
                label={value} 
                sx={{
                    height: 25, 
                    zIndex: 1, 
                    color: '#323643',
                    px: 2,
                    fontWeight: 'bold', 
                    bgcolor,
                    lineHeight: 'initial'
                }} 
            />
        </>
    )
}

export default StatusChip;