import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Modal from 'components/shared/ModalNew';

const ImagePreview = ({url}) => {
	return (
		<Box
			sx={{
				m: "12px",
				flex: "1 auto",
				borderRadius: '6px',
				backgroundSize: "contain",
				backgroundPosition: 'center',
				backgroundColor: '#000',
				backgroundRepeat: 'no-repeat',
				backgroundImage: `url(${url})`,
				height: {xs: '400px', md: 'auto'}
			}}
		/>
	)
}

const Thumbnail = ({onClick, index, imageURL, active}) => (
	<ButtonBase
		className={"image-modal-" + index}
		sx={{
			color: "primary.main",
			display: "block",
			borderRadius: "10px",
			p: 0,
			width: {xs: '80px', md: "180px"},
			minWidth: {xs: '80px', md: "180px"},
			height: active ? `calc(100% + 16px)` : `1`,
			marginTop: active ? '-16px' : 0,
			transition: 'height .02s ease-in, margin-top .02s ease-in'
		}}
		onClick={onClick}
	>
		<Box
			src={imageURL}
			sx={{
				borderRadius: "8px",
				height: "1",
				width: "1",
				objectFit: "cover",
				boxShadow:
					active
						? "4px 4px 10px rgba(0, 0, 0, 0.45)"
						: "unset",
			}}
			component="img"
			loading='lazy'
		/>
	</ButtonBase>
)

const ImageModal = ({ open, onClose, imageList }) => {
	const imageListContainer = React.useRef();
	const [activeStep, setActiveStep] = React.useState(0);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => {
			const index = prevActiveStep + 1;
			scrollToImage(index);
			return index;
		});
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => {
			const index = prevActiveStep - 1;
			scrollToImage(index);
			return index;
		});
	};

	const handleClose = () => {
		setActiveStep(0);
		onClose();
	}

	const scrollToImage = (index) => {
		const element = document.querySelector(`.image-modal-${index}`);

		if (element) {
			element.scrollIntoView({
				behavior: "smooth",
				block: "end",
				inline: "center",
			});
		}
	};

	return (
		<Modal
			onClose={handleClose}
			open={open}
			maxWidth="lg"
			onKeyDown={(event) => {
				const e = event || window.event;

				if (e.keyCode === 37 && activeStep > 0) {
					return handleBack();
				}

				if (e.keyCode === 39 && imageList?.length !== activeStep + 1) {
					return handleNext();
				}
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					height: "100%",
					bgcolor: "#ECFFFC",
					borderRadius: '6px',
					height: '90vh'
				}}
			>
				<ImagePreview
					url={imageList[activeStep]?.url}
				/>

				<Box>
					<Box
						sx={{
							m: "18px 0",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							gap: "12px",
						}}
					>
						<Button
							startIcon={<ArrowBackIcon />}
							onClick={handleBack}
							disabled={activeStep === 0}
							color='inherit'
						>
							Prev
						</Button>

						<Box
							sx={{
								p: '6px',
								minWidth: '100px',
								bgcolor: 'primary.main',
								color: 'primary.contrastText',
								textAlign: 'center',
								borderRadius: '20px'
							}}
						>
							<Typography color='inherit'>
								<strong>{activeStep + 1} of {imageList.length}</strong>
							</Typography>
						</Box>

						<Button
							endIcon={<ArrowForwardIcon />}
							onClick={handleNext}
							disabled={imageList?.length === activeStep + 1}
						>
							Next
						</Button>
					</Box>

					<Box
						sx={{
							display: "flex",
							alignItems: "stretch",
							gap: {xs: '6px', md: "12px"},
							height: {xs: '100px', md: "200px"},
							p: 2,
							overflow: "auto",
						}}
						ref={imageListContainer}
					>
						{imageList.map((item, index) => {
							const active = index === activeStep;

							return (
								<Thumbnail
									key={"image-modal-" + index}
									index={index}
									active={active}
									imageURL={item.url}
									onClick={(event) => {
										setActiveStep(index);
										scrollToImage(index)
									}}
								/>
							);
						})}
					</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default ImageModal;
