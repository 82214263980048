import { createTheme } from "@mui/material/styles";
import { colors } from "utils/constants";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "'Poppins', sans-serif",
    },

    htmlFontSize: 16,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,

    button: {
      textTransform: "none",
    },

    subtitle2: {
      fontSize: "1rem",
      fontWeight: 300,
    },

    body1: {
      fontSize: "0.875rem",
    },

    caption: {
      fontSize: "0.75rem",
    },
  },

  palette: {
    primary: {
      main: colors.mainGreen,
      contrastText: colors.white,
      light: colors.markerLightGreen,
    },

    text: {
      primary: colors.darkGreyText,
      secondary: colors.mediumGreyText,
    },
  },
});

export default theme;
