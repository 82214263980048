import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import footerBlock1 from "assets/svgs/footerblock1.svg";
import footerBlock2 from "assets/svgs/footerblock2.svg";
import footerBlock3 from "assets/svgs/footerblock3.svg";

import getBlockShadow from "./getBlockShadow";
import { useCurrentUserStore } from "../../store/Store";

const BlockImage = ({ img, shadow = "top-left" }) => {
  const shadowStyles = getBlockShadow(shadow);

  return (
    <Box
      sx={{
        height: { xs: "240px", md: "440px" },
        width: "33.3%",
        position: "relative",
        zIndex: 1,
        ...shadowStyles,
      }}
    >
      <Box
        src={img}
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        component="img"
      />
    </Box>
  );
};

const FooterBlock = () => {
  const navigate = useNavigate();
  const [userState] = useCurrentUserStore();

  const handleSignIn = () => {
    navigate({
      pathname: `/onboarding`,
    });
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: { xs: "wrap", md: "unset" },
        gap: { md: "40px" },
      }}
    >
      <Box
        mb="60px"
        flex={{ xs: "1 auto", md: "1 40%" }}
        width={{ xs: "500px", md: "auto" }}
      >
        <Typography variant="h3" paragraph>
          <strong>
            Start Your First
            <br />
            Search Now
          </strong>
        </Typography>

        <br />

        {!userState.loggedIn ? (
          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleSignIn}
            sx={{
              minWidth: "160px",
              boxShadow: "none",
              width: { xs: "1", md: "auto" },
            }}
          >
            {`Let's Go`}
          </Button>
        ) : null}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          flex: "1 auto",
        }}
      >
        <BlockImage shadow="top-left" img={footerBlock1} />

        <BlockImage shadow="center" img={footerBlock2} />

        <BlockImage shadow="bottom-right" img={footerBlock3} />
      </Box>
    </Box>
  );
};

export default FooterBlock;
