import React from "react";
import placeHolderImageSrc from "assets/images/image-placeholder.jpg";

const Image = ({ src, ...rest }) => {
    const addDefaultSrc = (event) => {
        event.target.src = placeHolderImageSrc;
    };


    return <img src={src || placeHolderImageSrc} onError={addDefaultSrc} {...rest} />;
};

export default Image;
