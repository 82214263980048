import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";

import { DetailContext } from "./index";

import ListingCard from "components/Listings/ListingCardNew";

// eslint-disable-next-line react/display-name
export default () => {
  const { savedSearchMatches } = React.useContext(DetailContext);

  const matchedData = savedSearchMatches?.data?.data;

  const handlePagination = (_event, value) => {
    savedSearchMatches.handleRetry({
      ...savedSearchMatches.reqParams,
      page: value,
    });
  };

  return (
    <>
      <Box mb={4}>

        {savedSearchMatches.isLoading ? (

          <Box textAlign="center">
            <CircularProgress />
          </Box>

        ) : (

          <Typography variant="h4">
            <strong>
              {matchedData?.length}&nbsp;
              {`match${matchedData?.length > 1 ? "es" : ""}`}
            </strong>
          </Typography>

        )}

      </Box>

      <Grid container spacing={2}>

        {!savedSearchMatches.isLoading &&
          matchedData.map((item, index) => {
            return (
              <Grid
                xs={12}
                md={3}
                item
                key={`saved-search-${index}-${item.id}`}
              >
                <ListingCard item={item} width="auto" />
              </Grid>
            );
          })}

      </Grid>

      {savedSearchMatches?.data?.meta?.total_pages > 1 && (

        <Stack spacing={2} mb="34px">
          <Pagination
            count={savedSearchMatches?.data?.meta?.total_pages}
            page={savedSearchMatches?.data?.meta?.current_page}
            onChange={handlePagination}
            size="large"
            variant="outlined"
            shape="rounded"
            disabled={savedSearchMatches?.isLoading}
          />
        </Stack>
        
      )}
    </>
  );
};
