import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import { ReactComponent as Logo } from "assets/svgs/howzerLogoTransparent.svg";
import MobileDrawer from "./AppBar/MobileDrawer";
import Search from "./AppBar/Search";
import { boxShadow } from "utils/constants";
import { useCurrentUserStore } from "../../store/Store";

export const appBarSection1ClassName = "howzer-appbar-section-1";
export const appBarSectionClassName = "howzer-appbar-section";

const hideAppBarOn = ["/onboarding"];

export const useCompressAppBar = () => {
	React.useEffect(() => {
		const compress = (trigger) => {
			const DOM = document.querySelector(`.${appBarSectionClassName}`);

			if (DOM) {
				DOM.style.maxWidth = trigger ? '1560px' : null;
				DOM.style.margin = trigger ? '0 auto' : null;
				DOM.style.width = trigger ? '100%' : null;
			}	
		}

		compress(true);

		return () => {
			compress(false);
		}
	}, [])

	return null;
}

const TransparentScroll = (props) => {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return children(trigger);
};

export default function HowzerAppBar() {
  let { pathname } = useLocation();
  const navigate = useNavigate();
  const [showSmScreenSearch, setShowSmScreenSearch] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userState] = useCurrentUserStore();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSearch = (params) => {
    let search = undefined;

    if (params?.location_id && params.location_type === "City") {
      search = `?cityID=${params?.location_id}`;
    }

    navigate({
      pathname: `/search`,
      search,
    });
  };

  if (hideAppBarOn.includes(pathname)) {
    return null;
  }

  const handleSignIn = () => {
    navigate({
      pathname: `/login`,
    });
  };

  const handleSignUp = () => {
    navigate({
      pathname: `/onboarding`,
    });
  };

  return (
    <TransparentScroll>
      {(trigger) => {
        return (
          <>
            <AppBar
              sx={{
                bgcolor: trigger ? "background.paper" : "transparent",
                boxShadow: trigger ? "0px 3px 8px rgba(0, 0, 0, 0.07)" : "none",
              }}
              elevation={0}
            >
              <div className={appBarSectionClassName}>
                <Toolbar
                  sx={{
                    display: "grid",
                    placeItems: "center",
                    gridTemplateColumns: "repeat(2, 1fr)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "1",
                      color: "primary.main",
                    }}
                    className={appBarSection1ClassName}
                  >
                    <Link
                      to="/"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          gap: "16px",
                          color: "inherit",
                        }}
                      >
                        <Logo color="inherit" width={30} height={30} />

                        <Hidden mdDown>
                          <Typography variant="subtitle1" color="inherit">
                            <b>HOWZER.APP</b>
                          </Typography>
                        </Hidden>
                      </Box>
                    </Link>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ml: 4,
                        color: "text.primary",
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "auto", md: "376px" },
                          position: { xs: "absolute", md: "unset" },
                          display: {
                            xs: showSmScreenSearch ? "flex" : "none",
                            md: "block",
                          },
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          padding: { xs: "10px", md: 0 },
                          zIndex: 1,
                        }}
                      >
                        <Search onChange={handleSearch} />

                        <Hidden mdUp>
                          <IconButton
                            onClick={() => setShowSmScreenSearch(false)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </Hidden>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "1",
                      color: "text.primary",
                      gap: "10px",
                    }}
                  >
                    <Hidden mdUp>
                      <IconButton onClick={() => setShowSmScreenSearch(true)}>
                        <SearchIcon />
                      </IconButton>
                    </Hidden>

                    <Hidden mdDown>
                    {!userState.loggedIn ? (
                      <Button
                        sx={{
                          color: "text.primary",
                          fontSize: (theme) => theme.typography.subtitle1,
                          p: 2.5,
                          height: "30px",
                        }}
                        onClick={handleSignIn}
                      >
                        Sign in
                      </Button>
                    ) : null}

                    {!userState.loggedIn ? (
                      <Button
                        variant="contained"
                        onClick={handleSignUp}
                        sx={{
                          height: "30px",
                          fontSize: (theme) => theme.typography.subtitle1,
                          boxShadow: boxShadow[0],
                          p: 2.5,
                          "&:hover": { boxShadow: boxShadow[0] },
                        }}
                      >
                        Try it out
                      </Button>
                    ) : null}
                    </Hidden>

                    <MobileDrawer
                      toggleDrawer={toggleDrawer}
                      open={drawerOpen}
                    />
                  </Box>
                </Toolbar>
              </div>
            </AppBar>

            <Toolbar />
          </>
        );
      }}
    </TransparentScroll>
  );
}
