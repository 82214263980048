import React from "react";
import moment from "moment";
import isUndefined from 'lodash/isUndefined';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "components/shared/ModalNew";

import NotLoggedIn from "../Tabs/NotLoggedIn";
import CalendarAndTimeButtons from "./CalendarAndTimeButtons";
import SuccessMessage from "./SuccessMessage";
import { post, useGet } from "utils/restClient";
import { extractDateAndTime } from "./CalendarAndTimeButtons";

export default function ScheduleShowing({
  listing,
  userLoggedIn,
  loading,
  value,
}) {
  const [showModal, setShowModal] = React.useState(false);
  const [showSchedule, setShowSchedule] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [date, setDate] = React.useState({});
  const [time, setTime] = React.useState();
  const { data: availabilities, isLoading: availabilitiesIsLoading } = useGet(
    `api/v1/showing_availability/availabilities`
  );

  if (loading || availabilitiesIsLoading) {
    return null;
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setStatus("");
  };

  const handleOpenModal = () => {
    setShowModal(true);

    if (userLoggedIn) {
      const markedDates = Object.keys(availabilities.data.marked_dates);

      if (value && isValidValue(value)) {
        setDate(scheduledShow);
        setTime(value);
        return;
      }

      setDate(moment(markedDates[0]));
      setTime("");
    }
  };

  const isValidValue = (value) => {
    if (value) {
      const scheduledShow = extractDateAndTime(value).moment;

      if (!isUndefined(availabilities.data.availabilities[scheduledShow.format('YYYY-MM-DD')])) {
        return true;
      }
    }

    return false;
  }

  const handleConfirm = async () => {
    try {
      setStatus("loading");

      const payload = extractDateAndTime(time).moment;

      const res = await post(
        `/api/v1/listings/${listing.listing_id}/request_showing`,
        { time: payload.utc().valueOf() }
      );

      res.status === 200 && setStatus("success");
    } catch (error) {
      setStatus("");
      console.error(error);
    }
  };

  const handleSetDate = (value) => {
    setDate(value);
    setTime("");
  };

  const handleSetTime = (time) => {
    setTime(time);
  };

  const ModalChildren = () => {
    if (!userLoggedIn) {
      return <NotLoggedIn listingID={listing.id} />;
    }

    if (status === "success") {
      return (
        <SuccessMessage
          time={time}
          date={date}
          handleCloseModal={handleCloseModal}
        />
      );
    }

    return (
      <CalendarAndTimeButtons
        date={date}
        time={time}
        handleSetDate={handleSetDate}
        handleSetTime={handleSetTime}
        handleConfirm={handleConfirm}
        availabilities={availabilities.data}
        loading={status === "loading"}
      />
    );
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {
            (isValidValue(value) && userLoggedIn) ? (
              <Box
                sx={{
                  padding: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: { sx: 200, md: 877 },
                  backgroundColor: "#F1FFFD",
                  borderRadius: 5,
                  justifyContent: "center",
                }}
              >
                <Typography variant="h6">
                  Your Showing is scheduled for&nbsp;
                  {`${extractDateAndTime(value).moment.format("ddd, MMMM DD")} @${extractDateAndTime(value).moment.format("hh:mm A")}`}{" "}
                </Typography>
                <br />
                <Button variant="contained" size="large" onClick={handleOpenModal}>
                  Manage Your Showings
                </Button>
              </Box>
            ) : (
              <Button
                variant="contained"
                size="large"
                onClick={handleOpenModal}
                fullWidth
              >
                Schedule Showing
              </Button>
            )
          }
        </Grid>
      </Grid>
      
      {showModal && (
        <Modal open={showModal} onClose={handleCloseModal} maxWidth="xl">
          <Box
            sx={{
              height: "100%",
              bgcolor: "background.paper",
              borderRadius: "6px",
              p: 4,
              overflow: "auto",
            }}
          >
            <ModalChildren />
          </Box>
        </Modal>
      )}
    </>
  );
}
