import React from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function NotLoggedIn({ listingID }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="subtitle1" paragraph>
        Must Be Logged In.
      </Typography>

      <Button
        variant="contained"
        size="large"
        to={`/login?afterSignIn=/${listingID}`}
        component={Link}
      >
        Sign in
      </Button>
    </Box>
  );
}
