import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AnalysisRequestIcon from "../../../../assets/images/Listing/analysis-request-icon.png";
import Card from "components/shared/Card";
import { Box } from "@mui/material";

const ExpertAnalysis = () => {
  return (
    <Card sx={{ height: "100%" }}>
      <Typography
        variant="h6"
        color="primary"
        paragraph
        sx={{ fontWeight: 800 }}
      >
        <strong>Expert Analysis</strong>
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <img style={{ height: 75 }} src={AnalysisRequestIcon} />
      </Box>
      <Button variant="outlined" size="large" fullWidth onClick={() => {}}>
        Request Expert Analysis
      </Button>
    </Card>
  );
};

export default ExpertAnalysis;
