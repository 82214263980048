import React from "react";
import ListingCard from "components/Listings/ListingCardNew";
import Hidden from "@mui/material/Hidden";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/styles/useTheme";

const swiperNavigationClassNames = {
  nextEl: "featured-listings-next",
  prevEl: "featured-listings-prev",
};

export default function NearbyListings({ nearbyListings = [] }) {
  const theme = useTheme();
  const swiperSettings = useSwiperSettings({ theme });

  return (
    <>
      <Hidden mdDown>
        {nearbyListings.map((listing) => (
          <ListingCard key={listing.id} item={listing} />
        ))}
      </Hidden>
      <Hidden mdUp>
        <Swiper
          spaceBetween={15}
          slidesPerView={swiperSettings.slidesPerView}
          loop={true}
          centeredSlides={true}
          pagination={{
            el: ".swiper-pagination",
            clickable: true,
          }}
          navigation={{
            nextEl: "." + swiperNavigationClassNames.nextEl,
            prevEl: "." + swiperNavigationClassNames.prevEl,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        >
          {nearbyListings.map((listing) => (
            <SwiperSlide key={listing.id}>
              <ListingCard item={listing} imageHeight={swiperSettings.height} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Hidden>
    </>
  );
}

const useSwiperSettings = ({ theme }) => {
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md")) && !isPhone;
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("xl"));

  return React.useMemo(() => {
    let height = 175;
    let slidesPerView = 4;

    if (isPhone) {
      height = 175;
      slidesPerView = 1.2;
    } else if (isTablet) {
      height = 200;
      slidesPerView = 2;
    } else if (isDesktop) {
      height = 254;
      slidesPerView = 4;
    } else if (isSmallDesktop) {
      height = 175;
      slidesPerView = 4;
    }

    return { height: height, slidesPerView: slidesPerView };
  }, [isPhone, isTablet, isSmallDesktop, isDesktop]);
};
