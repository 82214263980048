import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import Box from '@mui/material/Box';
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import ListingCard from "components/Listings/ListingCardNew";
import { Typography } from "@mui/material";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export const swiperNavigationClassNames = {
	nextEl: 'featured-listings-next',
	prevEl: 'featured-listings-prev',
};

class FeaturedListings extends React.Component {
	shouldComponentUpdate(nextProps, nextState) {
		return (
			this.props.featuredListings.length !==
			nextProps.featuredListings.length ||
			this.props.swiperSettings?.height !== nextProps.swiperSettings?.height ||
			this.props.swiperSettings?.slidesPerView !==
			nextProps.swiperSettings?.slidesPerView
		);
	}

	render() {
		const { swiperSettings, isDesktop, title = 'Featured Listings' } = this.props;

		const { featuredListings: listings } = this.props;

		return (
			<Box>
				<Typography variant='h5'>
					<b>{title}</b>
				</Typography>

				<br />

				<Box overflowX='auto'>
					<Swiper
						spaceBetween={30}
						slidesPerView={swiperSettings.slidesPerView}
						loop={true}
						centeredSlides={!!!isDesktop}
						pagination={{
							el: ".swiper-pagination",
							clickable: true,
						}}
						navigation={{
							nextEl: '.' + swiperNavigationClassNames.nextEl,
							prevEl: '.' + swiperNavigationClassNames.prevEl
						}}
						autoplay={{
							delay: 2500,
							disableOnInteraction: false,
						}}
					>
						{listings.map((listing) => (
							<SwiperSlide key={listing.id}>
								<ListingCard
									item={listing}
									imageHeight={swiperSettings.height}
								/>
							</SwiperSlide>
						))}
					</Swiper>
				</Box>
			</Box>
		);
	}
}

export default FeaturedListings;