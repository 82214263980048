import React from 'react';
import map from 'lodash/map';
import compact from 'lodash/compact';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ReactComponent as BankIcon } from "assets/svgs/icons/icon-bank.svg";
import { ReactComponent as CooperationIcon } from "assets/svgs/icons/icon-cooperation.svg";
import { ReactComponent as Home1Icon } from "assets/svgs/icons/icon-home1.svg";
import { ReactComponent as FamilyIcon } from "assets/svgs/icons/icon-family.svg";
import { ReactComponent as TreeIcon } from "assets/svgs/icons/icon-tree.svg";
import { ReactComponent as CondoIcon } from "assets/svgs/icons/icon-condo.svg";
import { ReactComponent as TownhoseIcon } from "assets/svgs/icons/icon-townhouse.svg";
import { ReactComponent as HomeIcon } from "assets/svgs/icons/icon-home.svg";

import { propertySubtypes } from "utils/constants";

const list = map(propertySubtypes, (_item, key) => {
    let Icon = () => <></>;
    let label = '';

    if (key == 1) {
        Icon = <HomeIcon />;
        label = 'House'
    }

    if (key == 5) {
        Icon = <TownhoseIcon />;
        label = 'Townhouse'
    }

    if (key == 6) {
        Icon = <CondoIcon />;
        label = 'Condo/Apt'
    }

    if (key == 2) {
        Icon = <TreeIcon />;
        label = 'Residential Land'
    }

    if (key == 26) {
        Icon = <FamilyIcon />;
        label = 'Multy-Family'
    }

    if (key == 8) {
        Icon = <Home1Icon />;
        label = 'Mobile'
    }

    if (key == 4) {
        Icon = <CooperationIcon />;
        label = 'Co-op'
    }

    if (key == 9) {
        Icon = <BankIcon />;
        label = 'Other'
    }

    if (label) {
        return {
            Icon,
            label,
            key
        }
    }
})

const HomeType = ({state, handleSetFilters}) => {
    const [tempHomeTypes, setTempHomeTypes] = React.useState([]);

    React.useEffect(() => {
        setTempHomeTypes(state?.filterState?.propertyTypes || [])
    }, [state?.filterState?.propertyTypes])

    const hasTempHomeType = (key) => tempHomeTypes.findIndex((type) => type === key) !== -1;

    const handleAddTempHomeType = (key) => {
        const temp = tempHomeTypes;

        if (hasTempHomeType(key)) {
            const index = temp.findIndex((type) => type === key)
            temp.splice(index, 1);
        } else {
            temp.push(key);
        }

        setTempHomeTypes(() => [...temp]);
    }

    const handleReset = () => {
        setTempHomeTypes([]);

        handleSetFilters({
            propertyTypes: []
        })
    }

    const handleSave = () => {
        handleSetFilters({
            propertyTypes: tempHomeTypes
        })
    }

    return (
        <form
            onSubmit={(event) => {
                event?.preventDefault();
                handleSave();
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridGap: '8px',
                    gridTemplateColumns: `repeat(3, 95px)`
                }}
            >
                {
                    compact(list).map((item, index) => {
                        const active = hasTempHomeType(item.key);

                        return (
                            <ButtonBase
                                key={`home-type-item-${index}-${item.key}${active ? '-active' : ''}`}
                                id={`home-type-item-${index}-${item.key}`}
                                sx={{
                                    overflow: 'hidden',
                                    width: '100%',
                                    display: 'inline-flex',
                                    padding: '16px 6px',
                                    borderRadius: '10px',
                                    color: theme => theme.palette.primary.main,
                                    backgroundColor: theme => active ? 'transparent' : theme.palette.primary.light,
                                    boxShadow: active ? `0px 0px 15px rgba(0, 0, 0, 0.15)` : '',
                                    transition: `box-shadow 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`
                                }}
                                onClick={() => {
                                    handleAddTempHomeType(item.key)
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Box mb='4px'>
                                        {item.Icon}
                                    </Box>

                                    <Typography color='textPrimary' title={item.label} varaint='caption' sx={{fontWeight: (theme) => theme.typography.fontWeightLight}}>
                                        {item.label}
                                    </Typography>
                                </Box>
                            </ButtonBase>
                        )
                    })
                }
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: '20px',
                    gap: '10px'
                }}
            >
                <Button
                    onClick={handleReset}
                    type='button'
                    color='primary'
                >
                    Reset
                </Button>

                <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                >
                    Done
                </Button>
            </Box>
        </form>
    )
}

export default HomeType;
