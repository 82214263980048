import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { fonts, colors } from "../../../utils/constants";

const styles = (theme) => ({
  howzerTextField: {
    zIndex: 100,
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
    },
    "& .MuiInputBase-input": {
      fontFamily: fonts.regular,
      fontSize: "14px",
      letterSpacing: "0.17px",
      zIndex: 10,
    },
    "& fieldset": {
      border: "none",
    },
    "& legent": {
      backgroundColor: colors.selectInputBackground,
      border: "none",
    },
    "& .MuiFormLabel-root": {
      top: -4,
      color: `${colors.lightGreyText} !important`,
    },
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.lightGreyText
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.lightGreyText
    }
  },
});

const useStyles = makeStyles(styles);

export default function HowzerTextField({
  label,
  value,
  onChange,
  className,
  type = "text",
  backgroundColor = "#F2F5F8",
  ...props
}) {
  const classes = useStyles();

  return (
    <TextField
      className={`${classes.howzerTextField} ${className}`}
      classes={classes}
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      backgroundcolor={backgroundColor}
      fullWidth={true}
      variant="outlined"
      {...props}
    />
  );
}
