import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";

const styles = (theme) => ({
  overlay: {
    position: "fixed",
    left: 0,
    top: 0,
    height: "100vh",
    width: "100%",
    flex: 1,
    display: "flex",
    zIndex: 100,
    justifyContent: "center",
    paddingBottom: "50%",
    backgroundColor: "grey",
    opacity: 0.5,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const useStyles = makeStyles(styles);

export default function Loader({ active }) {
  const classes = useStyles();
  if (!active) return null;

  return (
    <div className={classes.overlay}>
      <div className={classes.container}>
        <CircularProgress />
      </div>
    </div>
  );
}
