import React from 'react';
import Popover from "@mui/material/Popover";
import Fade from '@mui/material/Fade';

const WithPopover = ({
    renderTarget,
    renderPopover,
    ...rest
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            {renderTarget({
                onClick,
                onClose,
                open
            })}

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.25)',
                        padding: '18px',
                        fontSize: (theme) => theme.typography.fontSize,
                        borderRadius: '14px',
                        margin: '8px 0'
                    }
                }}
                TransitionComponent={Fade}
                {...rest}
            >
                {renderPopover({
                    onClick,
                    onClose,
                    open
                })}
            </Popover>
        </>
    )
}

export default WithPopover;
