export const copyAndShareURLStatus = {
  COPIED: "COPIED",
  CLIPBOARD: "CLIPBOARD",
};

export const copyAndShareURL = async ({ title, URL }) => {
  let status = copyAndShareURLStatus.CLIPBOARD;

  navigator.clipboard.writeText(URL);

  try {
    if (navigator.share) {
      await navigator.share({
        title,
        url: URL,
      });

      status = copyAndShareURLStatus.COPIED;
    }
  } catch (error) {
    console.error("Error while sharing URL:", error);
  }

  return status;
};
