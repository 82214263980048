import React, { useState } from "react";
import Modal from "../../../shared/Modal.jsx";
import { colors, fonts } from "../../../../utils/constants";
import Button from "../../../shared/Button/Button";
import TextInput from "../../../shared/Inputs/TextField.jsx";
import Text from "../../../shared/Text";
import { Formik } from "formik";
import { post } from "../../../../utils/restClient";
import Loader from "../../../shared/Loader.jsx";
import { makeStyles } from "@mui/styles";

const styles = () => ({
  container: {
    borderRadius: 20,
    padding: "30px 0",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    width: "100%",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  inputWrapper: {
    marginBottom: 10,
    width: "100%",
  },
  header: {
    fontFamily: fonts.bold,
    fontSize: 14,
    color: colors.darkGreyText,
    letterSpacing: 0.17,
    marginBottom: 10,
  },
  verifiedName: {
    fontFamily: fonts.regular,
    fontSize: 16,
    color: colors.lightGreyText,
    letterSpacing: 0.2,
    marginTop: 10,
  },
  verifiedAddress: {
    fontFamily: fonts.semibold,
    fontSize: 14,
    color: colors.darkGreyText,
    letterSpacing: 1.18,
    marginTop: 5,
    marginBottom: 30,
  },
  backButton: {
    marginTop: 15,
  },
  buttonsWrapper: {
    marginTop: 10,
    alignItems: "center",
  },
});

const useStyles = makeStyles(styles);

export default function AddTravelTimeModal({
  visible,
  handleConfirm,
  handleDismiss,
}) {
  const classes = useStyles();
  const [verifiedAddress, setVerifiedAddress] = useState(null);
  const [verifiedName, setVerifiedName] = useState(null);
  const [loadingAddress, setLoadingAddress] = useState(false);

  async function checkAddress({ address, name }) {
    setLoadingAddress(true);
    const res = await post("/api/v1/saved_search/address_lookup", {
      address: address,
    });

    if (res?.data?.results || [].length > 0) {
      setVerifiedAddress(res?.data?.results[0]?.formatted_address);
      setVerifiedName(name);
    }
    setLoadingAddress(false);
  }

  function handleAddConfirm() {
    handleConfirm(verifiedName, verifiedAddress);
    handleDismiss();
    setVerifiedAddress(null);
    setVerifiedAddress(null);
  }

  const FoundAddress = () => {
    return (
      <div>
        <Text className={classes.header}>Found Address:</Text>

        <Text className={classes.verifiedName}>{verifiedName}</Text>
        <Text className={classes.verifiedAddress}>{verifiedAddress}</Text>
        <div className={classes.buttonsWrapper}>
          <Button onClick={() => handleAddConfirm()} text="ADD" />
          <Button
            color={colors.lightGreyBackground}
            textColor={colors.darkGreyText}
            onClick={() => setVerifiedName(null)}
            className={classes.backButton}
            text="BACK"
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={visible}
      handleDismiss={() => {
        setVerifiedName(null);
        handleDismiss();
      }}
    >
      <div className={classes.container}>
        {verifiedName ? (
          <FoundAddress />
        ) : (
          <div>
            <Loader active={loadingAddress} />
            <Text className={classes.header}>Add Travel Time</Text>
            <Formik
              initialValues={{ name: "", address: "" }}
              onSubmit={(values) => checkAddress(values)}
            >
              {({ handleChange, handleBlur, handleSubmit, values }) => (
                <div>
                  <TextInput
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    className={classes.inputWrapper}
                    label="Name"
                    placeholder="Title (e.g. Work)"
                  />
                  <TextInput
                    onChange={handleChange("address")}
                    onBlur={handleBlur("address")}
                    value={values.address}
                    className={classes.inputWrapper}
                    label="Address"
                    placeholder="Address (100 E St, Alpine 84004)"
                  />
                  <div className={classes.buttonsWrapper}>
                    <Button onClick={handleSubmit} text="FIND" />
                    <Button
                      color={colors.lightGreyBackground}
                      textColor={colors.darkGreyText}
                      onClick={() => handleDismiss()}
                      className={classes.backButton}
                      text="CANCEL"
                    />
                  </div>
                </div>
              )}
            </Formik>
          </div>
        )}
      </div>
    </Modal>
  );
}
