import React from "react";
import find from 'lodash/find';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ReactComponent as FilterIcon } from "assets/svgs/icons/icon-filter.svg";

import { useSearchesStore } from "store/Store";
import {searchSortAttributes} from "utils/constants";
import { SET_FILTER_STATE } from "reducers/searchesReducer";

import WithPopover from "./components/WithPopover";

import SortAttribute from './SortAttribute';
import HomeType from './HomeType';
import MoreFilters from './MoreFilters';

const CommonButton = ({ children, active, ...rest }) => {
	return (
		<Button
			sx={{
				p: '2px 12px',
				fontSize: (theme) => theme.typography.caption.fontSize,
				fontWeight: (theme) => active ? theme.typography.fontWeightLight : 'unset',
				borderColor: active ? '#99F2E2 !important' : '#3E4958 !important',
				borderWidth: '.02em !important',
				color: active ? 'primary.dark' : 'text.primary',
				backgroundColor: active ? '#99F2E2 !important' : 'transparent'
			}}
			variant='outlined'
			size='small'
			{...rest}
		>
			{children}
		</Button>
	);
};

const Filters = () => {
	const [searchesState, searchesDispatch] = useSearchesStore();

	const handleSetFilters = (newData = {}) => {
		searchesDispatch({
			type: SET_FILTER_STATE,
			filterState: {
				...searchesState.filterState,
				...newData
			},
		});
	}

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				gap: '6px',
				flexWrap: 'wrap'
			}}
		>
			<CommonButton>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center'
					}}
				>
					For Sale

					<ArrowDropDownIcon fontSize="small" />
				</Box>
			</CommonButton>

			<WithPopover
				renderTarget={({ onClick, open }) => {
					return (
						<CommonButton active={open} onClick={onClick}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								{find(searchSortAttributes, {value: searchesState.filterState.sortAttribute})?.label}

								<ArrowDropDownIcon fontSize="small" />
							</Box>
						</CommonButton>
					)
				}}
				renderPopover={({onClose}) => {
					return (
						<SortAttribute
							state={searchesState}
							handleSetFilters={(values) => {
								handleSetFilters(values)
								onClose();
							}}
						/>
					)
				}}
				PaperProps={{
					sx: {
						padding: '0px',
						fontSize: (theme) => theme.typography.fontSize,
						borderRadius: '0px',
						margin: '12px 0',
						boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.25)',
						borderRadius: '6px'
					},
					elevation: 0
				}}
			/>

			<WithPopover
				renderTarget={({ onClick, open }) => {
					return (
						<CommonButton active={open} onClick={onClick}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								Home Type

								<ArrowDropDownIcon fontSize="small" />
							</Box>
						</CommonButton>
					)
				}}
				renderPopover={() => {
					return <HomeType state={searchesState} handleSetFilters={handleSetFilters} />
				}}
			/>

			<CommonButton>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center'
					}}
				>
					Bed/Baths

					<ArrowDropDownIcon fontSize="small" />
				</Box>
			</CommonButton>

			<WithPopover
				renderTarget={({ onClick, open }) => {
					return (
						<CommonButton
							onClick={onClick}
							active={open}
						>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								More Filters

								&nbsp;

								<FilterIcon />
							</Box>
						</CommonButton>
					)
				}}
				renderPopover={({ onClose }) => {
					return (
						<MoreFilters
							state={searchesState}
							handleSetFilters={(values) => {
								handleSetFilters(values)
								onClose();
							}}
						/>
					);
				}}
				PaperProps={{
					sx: {
						padding: '0px',
						fontSize: (theme) => theme.typography.fontSize,
						borderRadius: '0px',
						margin: '12px 0',
						left: '0 !important',
						width: '362px',
						height: 'calc(100% - (42px * 4.6))'
					},
					elevation: 0
				}}
			/>
		</Box>
	);
};

export default Filters;
