import React from "react";
import GoogleMap from "google-map-react";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Marker from "components/Map/Marker";
import { storageKeys } from "utils/constants";

const ListingMap = ({ listing, loading }) => {
  if (loading) {
    return null;
  }

  return (
    <GoogleMap
      center={{
        lat: parseFloat(listing.latitude),
        lng: parseFloat(listing.longitude),
      }}
      zoom={18}
      bootstrapURLKeys={{ key: storageKeys.bootstrapMapUrl }}
      lat={parseFloat(listing.latitude)}
      lng={parseFloat(listing.longitude)}
    >
      <Marker
        lat={parseFloat(listing.latitude)}
        lng={parseFloat(listing.longitude)}
        showPrice={false}
        sx={{
          height: { xs: "60px", md: "100px" },
          width: { xs: "60px", md: "100px" },
          backgroundColor: "blue",
        }}
        // color="primary"
      />
    </GoogleMap>
  );
};

export default ListingMap;
