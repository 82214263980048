import React from "react";
import Box from "@mui/material/Box";

export default function Card({ children, sx }) {
  return (
    <Box
      sx={{
        boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.11)",
        p: 3,
        borderRadius: "10px",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
