import React from 'react';
import sortBy from 'lodash/sortBy';
import 'react-vis/dist/style.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
	XYPlot,
	HorizontalGridLines,
	XAxis,
	YAxis,
	LineSeries
} from 'react-vis';

import NotLoggedIn from "./NotLoggedIn";

const makeData = (trends = [], pick = 'all') => {
	const data = sortBy(trends, 'end_date');

	if (pick === 'all') {
		return data
	}

	return data.slice(Math.max(data.length - pick, 1));
}

const ProvideSizeWrapper = ({title, children}) => {
	const [size, setSize] = React.useState({
		height: 325,
		width: 100
	});

	const wrapper = React.useRef();

	React.useLayoutEffect(() => {
		const onResize = () => {
			if (wrapper?.current) {
				const DOM = wrapper.current;
				const parentDOM = DOM.parentElement;

				const computedStyle = getComputedStyle(parentDOM);
				let width = parentDOM.clientWidth;   // width with padding
				width -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);

				setSize({
					height: size.height,
					width
				})
			}
		}

		onResize();

		window.addEventListener('resize', onResize);

		return () => {
			window.removeEventListener('resize', onResize);
		}
	}, [])

	return (
		<Box
			sx={{
				boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.11)',
				borderRadius: '10px',
				height: '1',
				width: '1',
				py: 3,
				px: 2
			}}
		>
			<Typography variant='body1' paragraph>
				<b>{title}</b>
			</Typography>

			<Box ref={wrapper}>
				{
					children(size)
				}
			</Box>
		</Box>
	)
}

const commonLineStyle = {
	fill: 'none',
	strokeWidth: '6px',
	stroke: '#00DEB7',
}

const TrendsTab = ({
	trends,
	loading,
	userLoggedIn,
	listing
}) => {
	if (!userLoggedIn) {
		return <NotLoggedIn listingID={listing?.id} />;
	}

	if (loading) {
		return null;
	}

	const data = makeData(trends, 4);

	const data1 = data.map((item) => ({
		x: item.end_date,
		y: parseFloat(item.average_list_price_per_sq_ft)
	}))

	const data2 = data.map((item) => ({
		x: item.end_date,
		y: parseFloat(item.average_days_on_market)
	}))

	const data3 = data.map((item) => ({
		x: item.end_date,
		y: parseFloat(item.average_price_drop_per_new_listing)
	}))

	return (
		<Box p={1}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={4}>
					<ProvideSizeWrapper title='Average List Price/Sq Ft'>
						{({height, width}) => (
							<XYPlot
								xType='ordinal'
								width={width}
								height={height}
							>
								<XAxis />
								<YAxis />
								<HorizontalGridLines />
			
								<LineSeries
									style={commonLineStyle}
									curve={'curveMonotoneX'}
									data={data1}
								/>
							</XYPlot>
						)}
					</ProvideSizeWrapper>
				</Grid>

				<Grid item xs={12} lg={4}>
					<ProvideSizeWrapper title='Average Days on Market'>
						{({height, width}) => (
							<XYPlot
								xType='ordinal'
								width={width}
								height={height}
							>
								<XAxis />
								<YAxis />
								<HorizontalGridLines />
			
								<LineSeries
									style={commonLineStyle}
									curve={'curveMonotoneX'}
									data={data2}
								/>
							</XYPlot>
						)}
					</ProvideSizeWrapper>
				</Grid>

				<Grid item xs={12} lg={4}>
					<ProvideSizeWrapper title='Average Price Drop/Listing'>
						{({height, width}) => (
							<XYPlot
								xType='ordinal'
								width={width}
								height={height}
							>
								<XAxis />
								<YAxis />
								<HorizontalGridLines />
			
								<LineSeries
									style={commonLineStyle}
									curve={'curveMonotoneX'}
									data={data3}
								/>
							</XYPlot>
						)}
					</ProvideSizeWrapper>
				</Grid>
			</Grid>
		</Box>
	)
}

export default TrendsTab;
