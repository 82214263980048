import React, { useEffect, useRef } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import HowzerTextField from "./TextField";
import Text from "../Text";

const filter = createFilterOptions();

export default function HowzerAutoComplete({
  options,
  label,
  onChange,
  placeholder,
  focusOnRender = false,
}) {
  const [value, setValue] = React.useState(null);

  const inputRef = useRef();

  useEffect(() => {
    if (focusOnRender) inputRef?.current?.focus();
  }, []);

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some(
          (option) => inputValue === option.name
        );
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.name;
      }}
      renderOption={(props, option) => <Text {...props}>{option.name}</Text>}
      freeSolo
      renderInput={(params) => (
        <HowzerTextField {...params} placeholder={placeholder} label={label} inputRef={inputRef} />
      )}
    />
  );
}
