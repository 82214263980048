import React, { useEffect } from "react";
import { get } from "../../utils/restClient";
import {
  SET_USER_INFO,
  FETCHED_USER_INFO_FAIL,
} from "../../reducers/currentUserReducer";
import { useCurrentUserStore } from "../../store/Store";
// Hook into reducer, rerun UE if state logged in changes, and refetch user/info if token exists.
export default function SetUserInfo() {
  const [state, dispatch] = useCurrentUserStore();

  useEffect(() => {
    async function fetchUserInfo() {
      const res = await get("api/v1/user/info");
      if (res.status == 200) {
        dispatch({ type: SET_USER_INFO, user: res?.data?.user });
      } else {
        dispatch({ type: FETCHED_USER_INFO_FAIL });
      }
    }
    const token = window.sessionStorage.getItem("accessToken");

    if (token) {
      fetchUserInfo();
    } else {
      dispatch({ type: FETCHED_USER_INFO_FAIL });
    }
  }, [state.loggedIn]);
  return <div />;
}
