import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./assets/fonts/SF-Pro-Text-Regular.otf";
import "./assets/fonts/SF-Pro-Text-Bold.otf";
import "./assets/fonts/SF-Pro-Text-Heavy.otf";
import "./assets/fonts/SF-Pro-Text-Semibold.otf";
import "./assets/fonts/SF-Pro-Text-Medium.otf";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
