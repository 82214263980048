import React from "react";
import capitalize from "lodash/capitalize";
import lowerCase from "lodash/lowerCase";
import GoogleMap from "google-map-react";
import { storageKeys, defaultMapProps } from "utils/constants";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import BrushIcon from "@mui/icons-material/Brush";
import DoneIcon from "@mui/icons-material/Done";

import MiniButton from "components/shared/Button/MiniButton";

export default function MapDraw({ handleSubmit, handleClose }) {
  const [loading, setLoading] = React.useState(false);
  const [draw, setDraw] = React.useState(false);
  const [markers, setMarkers] = React.useState([]);
  const googleMapRef = React.useRef(null);

  const handleDraw = () => {
    setDraw(!draw);
  };

  React.useEffect(() => {
    if (googleMapRef?.current && !loading) {
      setDrawingManager();
    }
  }, [draw, loading]);

  const getLocName = async (my_location) => {
    const google = googleMapRef.current;
    const geocoder = new google.maps.Geocoder();
    let loc_name;
    let loc_type;
    let loc_id;

    await geocoder.geocode({ latLng: my_location }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        const locations = results.filter((r) =>
          r.types.includes("postal_code")
        );

        if (locations[0]) {
          let adrs_comp = locations[0].address_components;
          loc_id = locations[0].place_id;

          for (let i = 0; i < adrs_comp.length; i++) {
            if (adrs_comp[i].types.includes("postal_code")) {
              loc_name = adrs_comp[i].long_name;
              loc_type = "PostalCode";
            }
          }
        }
      }
    });

    return {
      loc_name,
      loc_type,
      loc_id,
    };
  };

  const handleClear = () => {
    if (googleMapRef?.current && !loading) {
      setMarkers((preState = []) => {
        preState.forEach((marker) => {
          marker.setMap(null);
        });

        return [];
      });
    }
  };

  const handleSetMarkers = (e) => {
    if (e.type === "marker") {
      const google = googleMapRef.current;
      const map = google.map;
      const marker = e.overlay;
      const position = {
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      };
      marker.setMap(null);

      setMarkers((prevState = []) => {
        const marker = new google.maps.Marker({
          position,
          map,
        });

        return [...prevState, marker];
      });
    }
  };

  const setDrawingManager = async () => {
    const google = googleMapRef.current;
    const map = google.map;

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: draw,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.MARKER],
      },
      circleOptions: {
        fillColor: "#ffff00",
        fillOpacity: 0,
        strokeWeight: 2,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });

    google.maps.event.addListener(
      drawingManager,
      "overlaycomplete",
      handleSetMarkers
    );

    drawingManager.setMap(map);
  };

  const handleDone = async () => {
    let promises = [];
    let locations = [];

    markers.forEach((marker) => {
      const lat = marker.position.lat();
      const lng = marker.position.lng();

      const promise = () =>
        getLocName({ lat, lng }).then((result) => {
          const location = {
            id: null,
            key: lowerCase(result.loc_name + ""),
            latitude: lat + "",
            location_id: result.loc_id,
            location_type: result.loc_type,
            longitude: lng + "",
            name: capitalize(result.loc_name + ""),
          };

          locations.push(location);
        });

      promises.push(promise());
    });

    await Promise.all(promises);

    handleSubmit(locations);
    handleClose();
  };

  return (
    <>
      <Box sx={{ height: "100vh" }}>
        <GoogleMap
          bootstrapURLKeys={{
            key: storageKeys.bootstrapMapUrl,
            libraries: ["drawing"].join(","),
          }}
          center={defaultMapProps.center}
          zoom={defaultMapProps.zoom}
          options={{
            fullscreenControl: false,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={(google) => {
            googleMapRef.current = google;
            setLoading(false);
          }}
        />
      </Box>

      <Container
        sx={{
          display: "flex",
          gap: "10px",
          padding: "20px",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
        maxWidth="sm"
      >
        {draw ? (
          <>
            {!!markers?.length && (
              <Box sx={{ display: "flex", gap: "10px" }}>
                <MiniButton
                  name="Done"
                  icon={<DoneIcon />}
                  onClick={handleDone}
                />

                <MiniButton
                  name="Clear"
                  icon={<BackspaceOutlinedIcon fontSize="small" />}
                  onClick={handleClear}
                />
              </Box>
            )}
          </>
        ) : (
          <MiniButton name="Draw" icon={<BrushIcon />} onClick={handleDraw} />
        )}

        <Button
          variant="contained"
          fullWidth
          onClick={handleClose}
          sx={{
            padding: "15px",
            boxShadow: "none",
            borderRadius: "6px",
            fontSize: (theme) => theme.typography.h6,

            "&:hover": {
              boxShadow: "none",
              borderRadius: "6px",
            },
          }}
        >
          Back to Filters
        </Button>
      </Container>
    </>
  );
}
