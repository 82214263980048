import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useSearchesStore } from 'store/Store';
import { SET_FILTER_STATE, fetchLocationOptions } from "reducers/searchesReducer";

const Search = ({ onChange }) => {
    const [searchesState, searchesDispatch] = useSearchesStore();
    const { fetchedLocationOptions, locationOptions, filterState } = searchesState;

    React.useEffect(() => {
        if (locationOptions?.length == 0 && !fetchedLocationOptions) {
          fetchLocationOptions(searchesDispatch);
        }
    }, []);

    if (!fetchedLocationOptions || locationOptions?.length == 0) {
        return null;
    }

    return (
        <>
            <Autocomplete
                options={locationOptions}
                getOptionLabel={(option) => option?.name || ''}
                autoHighlight
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder='Enter Location'
                        InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color='inherit' />
                                </InputAdornment>
                            ),
                            sx: {
                                height: "inherit",
                            },
                        }}
                        variant='standard'
                        sx={{
                            height: "100%",
                            padding: "4px 8px",
                            flex: "1 auto",
                            backgroundColor: 'rgba(0, 0, 0, .035)',
                            borderRadius: '3px'
                        }}
                    />
                )}
                onChange={(_event, newValue) => {
                    searchesDispatch({
                        type: SET_FILTER_STATE,
                        filterState: {
                            ...filterState,
                            selectedLocations: [newValue]
                        },
                    });

                    onChange(newValue)
                }}
                forcePopupIcon={false}
                sx={{
                    flex: '1 auto'
                }}
            />
        </>
    );
}

export default Search;
