import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { alpha } from "@mui/material/styles";

import { useGet } from "utils/restClient";
import VerifyAuthenticated from "components/shared/VerifyAuthenticated";
import ListingCard from "components/Listings/ListingCardNew";

import heroImageSrc from "assets/images/Search/hero-image.png";

export default function SavedSearchesScreen() {
  const { data, isLoading } = useGet("api/v1/saved_searches");
  const savedSearchItems = data.data;

  return (
    <>
      <VerifyAuthenticated />

      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          background: (theme) =>
            `linear-gradient(0deg, rgba(0, 0, 0, 0), ${alpha(
              theme.palette.primary.main,
              0.4
            )}, ${alpha(
              theme.palette.primary.main,
              0.65
            )}), url(${heroImageSrc})`,
          height: "300px",
          borderRadius: "30px",
          backgroundSize: { xs: "cover", md: "100% 100%" },
          color: "primary.contrastText",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography
          variant="h4"
          color="inherit"
          sx={{
            letterSpacing: "0.923077px",
            fontSize: (theme) => theme.typography.pxToRem(20),
          }}
        >
          <strong>My Saved Searches</strong>
        </Typography>

      </Box>

      <br />
      <br />

      {isLoading && (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      )}

      {savedSearchItems?.length && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
              mb: "34px",
            }}
          >

            <Typography variant="h6">
              <strong>{savedSearchItems?.length} Saved Search</strong>
            </Typography>

            <Button
              color="primary"
              variant="contained"
              style={{ minWidth: "280px" }}
            >
              New Search
            </Button>

          </Box>

          <Grid container>

            {savedSearchItems?.map((item, index) => {
              const normalizedItem = {
                icon_image: item.icon_image,
                address_1: item.name,
                list_price: item.min_price,
                bedrooms_total: item.min_bedrooms,
                bathrooms_total: item.min_bathrooms,
                living_area: item.min_sq_footage,
              };

              return (
                <Grid item key={`saved-search-${index}-${item.id}`}>

                  <ListingCard
                    item={normalizedItem}
                    href={`/saved_searches/${item.id}`}
                  />

                </Grid>
              );
            })}

          </Grid>

        </>
      )}
    </>
  );
}
