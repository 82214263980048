import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { colors, fonts } from "../../../utils/constants";

const styles = (theme) => ({
  howzerButton: {
    background: (props) => `${props.backgroundColor} !important`,
    width: "100%",
    height: "50px",
    fontFamily: (props) => `${props.fontFamily} !important`,
    color: (props) => `${props.textColor} !important`,
    letterSpacing: "0",
    textAlign: "center",
    lineHeight: "20px",
  },
  root: {
    borderRadius: (props) => `${props.borderRadius}px !important`,
    padding: "10px !important",
  },
  text: {
    fontSize: (props) => `${props.fontSize}px !important`,
  },
});

const useStyles = makeStyles(styles);

export default function HowzerButton({
  text,
  Icon,
  onClick,
  className,
  backgroundColor = colors.mainGreen,
  textColor = colors.white,
  borderRadius,
  fontFamily = fonts.bold,
  fontSize = 12,
}) {
  const classes = useStyles({
    textColor,
    backgroundColor,
    borderRadius,
    fontFamily,
    fontSize,
  });

  return (
    <Button
      className={`${classes.howzerButton} ${className}`}
      onClick={onClick}
      classes={classes}
    >
      {Icon && <Icon />}
      {text && text}
    </Button>
  );
}
