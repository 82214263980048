import React, { useState } from "react";
import { makeStyles } from "@mui/styles";

const styles = () => ({
  button: {
    zIndex: 1,
    width: "100%",
    "&:active": {
      filter: "brightness(0.90)",
    },
    cursor: "pointer",
  },
});

const useStyles = makeStyles(styles);

export default function TouchableOpacity({
  className,
  children,
  onPress,
  ...rest
}) {
  const classes = useStyles();

  return (
    <div
      className={`${classes.button} ${className}`}
      style={{
        transition: "opacity 300ms ease",
      }}
      onClick={onPress}
      {...rest}
    >
      <div className={className}>{children}</div>
    </div>
  );
}
